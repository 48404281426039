import React, {useCallback, useMemo, useState} from "react";
import {Box, Button, IconButton, Popover, Stack} from "@mui/material";
import {MdQuestionMark} from "react-icons/md";
import {FreelancePopup} from "../../components/Popup/FreelancePopup";
import {DisplayBar} from "../../components/UI/DisplayBar";
import {AppLayout} from "../../layouts/AppLayout";
import Utils from "../../utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import Functions from "../../utils/Functions";
import {useTranslation} from "react-i18next";
import PermissionService from "../../services/permissionService";
import {ProposalAssignmentBoard} from "./ProposalAssignmentBoard";
import {FreelanceBoard} from "./FreelanceBoard";
import _ from "lodash";
import {saveDisplayConfig} from "../../services/userService";
import {updateDisplayConfig} from "../../utils/actions/authActions";

export const TalentBoard = ({sourcing = false}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const pageKey = 'freelance';
    const displayConfig = Object.keys(connectedUser.displayConfig).length
        ? connectedUser.displayConfig : {freelance: {}};
    if (!displayConfig.freelance?.pinnedColumns) {
        displayConfig.freelance.pinnedColumns = {left: ['action', 'User.fullName']}
    }

    const freelanceDisplayConfig = useMemo(() => {
        const config = {...displayConfig.freelance};
        if (sourcing) {
            config.advancedFilter = displayConfig?.sourcing?.advancedFilter ?? {};
            config.filterModel = displayConfig?.sourcing?.filterModel ?? {};
        }
        return config;
    }, [displayConfig, sourcing]);

    const [rowCount, setRowCount] = useState(!sourcing ? 0 : null);
    const [displayType, setDisplayType] = useState(freelanceDisplayConfig?.displayType || "List");
    const [triggerApi, setTriggerApi] = useState(!Object.keys(freelanceDisplayConfig?.advancedFilter || {}).length);
    const [freelanceAddingDialog, setFreelanceAddingDialog] = useState(false);
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);
    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const handleDisplayMenuClick = (newDisplay) => {
        setDisplayType(newDisplay);
        handleDisplayConfigChange("displayType", newDisplay);
    };

    const getDisplayItems = useCallback(() => {
        if (sourcing) return [];
        const baseItems = [{
            key: 'List',
            name: isEnterpriseSession ? 'All freelancers' : 'List',
            pageTitle: 'Freelancers'
        }];
        if (isEnterpriseSession) {
            return [
                ...baseItems,
                {
                    key: 'MyFavoriteFreelancers',
                    name: 'My favorite freelancers',
                    pageTitle: 'My favorite freelancers',
                    favorite: 'me'
                },
                {key: 'AssignmentProposals', name: 'Assignment proposals', pageTitle: 'Propositions de mission'},
            ];
        }
        return baseItems;
    }, [isEnterpriseSession, sourcing]);

    const handleDisplayConfigChange = useCallback((displayKey, displayValue) => {
        let updatedPageKey = pageKey;

        if (displayKey === 'columnVisibilityModel') {
            displayValue = _.pickBy(displayValue, value => value === false);
        } else if (sourcing && ['advancedFilter', 'filterModel'].includes(displayKey)) {
            updatedPageKey = 'sourcing';
        }

        const updatedDisplayConfig = {
            ...displayConfig,
            [updatedPageKey]: {
                ...displayConfig[updatedPageKey],
                [displayKey]: displayValue,
            },
        };

        saveDisplayConfig(updatedDisplayConfig, token).then((result) => {
            if (!result?.error) {
                dispatch(updateDisplayConfig(updatedDisplayConfig));
            }
        });
    }, [displayConfig, sourcing, token]);

    const activeDisplay = useMemo(() => {
        const items = getDisplayItems();
        return items[Utils.findIndexByKey(items, displayType)];
    }, [displayType]);

    const pageTitle = sourcing ? "Finding new freelancers" : activeDisplay?.pageTitle;

    const handleFreelanceDialogShow = () => {
        if (PermissionService.hasPermission(connectedUser, "addFreelance")) {
            setFreelanceAddingDialog(true);
        }
    };

    const handleFreelanceDialogClose = (reload = false) => {
        setFreelanceAddingDialog(false);
        if (reload) {
            setTriggerApi(true);
        }
    };

    const handleHelpClick = (event) => {
        setHelpAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const openHelpMenu = Boolean(helpAnchorEl);
    const helpPopoverId = openHelpMenu ? 'help-popover' : undefined;

    return (
        <AppLayout className="content-full-height">
            <Stack>
                <div className="pageheader">
                    <h1 className="pagetitle">
                        {t(pageTitle)}
                        <p className={`header-data-row-count ${sourcing && rowCount === null ? "d-none" : ""}`}>
                            {rowCount}
                        </p>
                        {sourcing && (
                            <>
                                <IconButton
                                    aria-describedby={helpPopoverId}
                                    onClick={handleHelpClick}
                                    size="small"
                                    sx={{border: '1px solid rgba(0, 0, 0, 0.23)'}}
                                ><MdQuestionMark/></IconButton>
                                <Popover
                                    id={helpPopoverId}
                                    open={openHelpMenu}
                                    anchorEl={helpAnchorEl}
                                    onClose={handleHelpClose}
                                    anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                    transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                    sx={{ml: 1}}
                                >
                                    <Box sx={{p: 1.5, width: "300px"}}>
                                        {t("Explore the freelancers registered on Smarteem, consult their profiles and add them to your pool of freelancers.")}
                                    </Box>
                                </Popover>
                            </>
                        )}
                    </h1>
                    <div className="pageaction">
                        {!sourcing && isEnterpriseSession && displayType !== "AssignmentProposals" && (
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={handleFreelanceDialogShow}
                                >
                                    {t("Add a freelancer")}
                                </Button>
                                <FreelancePopup
                                    show={freelanceAddingDialog}
                                    onClose={handleFreelanceDialogClose}
                                    canModify={true}
                                />
                            </>
                        )}
                    </div>
                </div>
                {!sourcing && (
                    <DisplayBar
                        displayItems={getDisplayItems()}
                        displayType={displayType}
                        onDisplayMenuClick={handleDisplayMenuClick}
                    />
                )}
                {sourcing && <Box sx={{mt: 1.5}}></Box>}
            </Stack>
            <Box className="table-layout">
                {displayType === "AssignmentProposals"
                    ? (
                        <ProposalAssignmentBoard
                            onListChange={setRowCount}
                        />
                    )
                    : (
                        <FreelanceBoard
                            sourcing={sourcing}
                            freelanceDisplayConfig={freelanceDisplayConfig}
                            activeDisplay={activeDisplay}
                            setDisplayType={setDisplayType}
                            triggerApi={triggerApi}
                            setTriggerApi={setTriggerApi}
                            onListChange={setRowCount}
                            onDisplayConfigChange={handleDisplayConfigChange}
                        />
                    )
                }
            </Box>
        </AppLayout>
    );
};
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import "../form.css";
import {ContactRequestActions} from "../../UI/ContactRequestActions";
import {fetchCompanyToFreelanceRelations} from "../../../services/companyToFreelanceRelationService";
import {Table} from "../../Tables/Table";
import PermissionService from "../../../services/permissionService";
import {Chip} from "@mui/material";
import Functions from "../../../utils/Functions";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const ContactRequests = () => {
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [data, setData] = useState([]);
    const [triggerApi, setTriggerApi] = useState(true);
    const {t} = useTranslation();
    const [, setError] = React.useState("");
    const fetchCompanyToFreelanceRelationsRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });

    let columns = [];
    let pageTitle;
    if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
        pageTitle = "Access to freelancer profiles";

        columns = [
            {
                field: 'FreelanceProfile.User.fullName',
                type: 'string',
                headerName: t("Freelancer"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.FreelanceProfile.User.fullName
                }
            },
            {
                field: 'FreelanceProfile.User.email',
                type: 'string',
                headerName: t("Email"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const email = row.FreelanceProfile.User.email;
                    return <div className="MuiDataGrid-cellContent" title={email} role="presentation">
                        {email}
                    </div>
                }
            },
            {
                field: 'FreelanceProfile.User.onboardingComplete',
                type: 'singleSelect',
                headerName: t("Account status"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'center',
                valueOptions: [
                    {value: true, label: t("Activated account")},
                    {value: false, label: t("Inactive account")},
                ],
                renderCell: ({row}) => {
                    const accountStatus = row.FreelanceProfile.User.onboardingComplete || false;
                    const accountStatusLabel = accountStatus ? t("Activated account") : t("Inactive account");
                    const accountStatusClass = accountStatus ? "account-status-active" : "account-status-inactive";
                    return <Chip label={accountStatusLabel} className={accountStatusClass} variant="outlined"/>
                }
            }
        ];

        if (PermissionService.hasPermission(connectedUser, "contactRequest")) {
            columns.push({
                field: 'action',
                headerName: '',
                minWidth: 100,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return <ContactRequestActions
                        contactRequest={row}
                        token={token}
                        setTriggerApi={setTriggerApi}
                    />
                }
            });
        }
    }

    useEffect(() => {
        fetchCompanyToFreelanceRelationsRef.current(filterConfigs);
    }, [filterConfigs, triggerApi]);

    fetchCompanyToFreelanceRelationsRef.current = async (query = {}) => {
        try {
            if (triggerApi) {
                const fetchedData = await fetchCompanyToFreelanceRelations(query, token);
                setRowCount(fetchedData.count);
                setData(fetchedData.data);

                setTriggerApi(false);
            }
        } catch (error) {
            setError(error);
        }
    }

    const handleDataGridChange = (filterConfigs) => {
        setTriggerApi(true);
        setFilterConfigs(filterConfigs);
    };

    return (
        <>
            <div className="standardform standardform-w55">
                <div className="card transparent">
                    <div className="pageheader">
                        <h3 className="text-title text-theme">
                            {t(pageTitle)}
                        </h3>
                    </div>
                    <Table
                        fullHeight={true}
                        columns={columns}
                        data={data}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        onChange={handleDataGridChange}
                    />
                </div>
            </div>
        </>
    );
};

import React, {forwardRef, useEffect, useRef, useState} from "react";
import Functions from "../../../../../utils/Functions";
import {Box, Button, Chip, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Popup from "../../../../Popup/Popup";
import ResponsiveStepper from "../../../../UI/ResponsiveStepper";
import {MessageComponent} from "../../../../UI/MessageComponent";
import {FieldHolder} from "../../../FieldHolder";
import {Table} from "../../../../Tables/Table";
import Utils from "../../../../../utils/Utils";
import {
    createQuote, getQuotesByCompany, getQuotesByFreelance, getQuotesByMission
} from "../../../../../services/quoteService";
import {createFile} from "../../../../../services/fileService";
import missionRxjs from "../../../../../services/missionRxjs";
import {QuoteDetailSection} from "./QuoteDetailSection";
import {useNavigate} from "react-router-dom";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const QuoteListSection = forwardRef((
    {
        formData,
        setFormData,
        objectAttachedType,
        isSection = true,
        sessionType,
        canModify
    },
    ref
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    const missionId = formData?.id || "";

    const [message, setMessage] = useState({});
    const [showQuoteDialog, setShowQuoteDialog] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState({});
    const [stepQuote, setStepQuote] = useState(Functions.TEXT_DESCRIPTION);
    const [quoteFile, setQuoteFile] = useState(null);

    const defaultFormQuote = {
        status: Functions.TEXT_OPEN, devise: "EUR",
    };
    const [formQuote, setFormQuote] = useState(defaultFormQuote);
    const deleteQuoteFileRef = useRef();

    const [quotes, setQuotes] = useState([]);
    const tableRef = useRef();
    const fetchQuotesRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '', range: JSON.stringify([0, pageSize - 1]), filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    const steps = [{key: Functions.TEXT_DESCRIPTION, name: 'Description'}, {
        key: Functions.TEXT_DETAILS,
        name: 'Details'
    },];

    const columns = [
        {
            field: 'Quote.name',
            type: 'string',
            headerName: t("Name"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return <Box sx={{textWrap: "wrap"}}>
                    {row.name}
                </Box>;
            }
        },
        {
            field: 'Quote.createdAt',
            type: 'date',
            headerName: t("Added on"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
            renderCell: ({row}) => {
                return Utils.formatDate(row.createdAt);
            }
        },
        {
            field: 'amount',
            type: 'number',
            headerName: t("Amount"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return `${row?.amount} ${Utils.getCurrencySymbol(row?.devise)}`;
            }
        },
        {
            field: 'status',
            type: 'singleSelect',
            headerName: t("Status"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueOptions: Object.entries(Functions.STATUS_QUOTE).map(([value, label]) => ({
                value: value, label: t(label)
            })),
            renderCell: ({row}) => {
                const status = t(Functions.STATUS_QUOTE[row.status]);
                return <Chip
                    label={status}
                    title={status}
                    size="small"
                    className={Functions.STATUS_QUOTE_BG[row.status]}
                />
            }
        }
    ];

    if (!isSection) {
        columns.splice(1, 0, {
            field: 'Mission.name',
            type: 'string',
            headerName: t("Assignment"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.Mission.name;
            }
        });
        if (isEnterpriseSession) {
            columns.splice(2, 0, {
                field: 'QuoteCreatorUser.fullName',
                type: 'string',
                headerName: t("Added by"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.QuoteCreatorUser?.fullName;
                }
            });
        } else {
            columns.splice(2, 0, {
                field: 'Company.name',
                type: 'string',
                headerName: t("Company"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.Company?.name;
                }
            });
        }
    }

    useEffect(() => {
        const quoteFileObservable = missionRxjs.getQuoteFileObservable();
        const subscriptionQuoteFile = quoteFileObservable.subscribe((data) => {
            if (data) {
                setQuoteFile(data);
            }
        });

        return () => {
            subscriptionQuoteFile.unsubscribe();
        };
    }, []);

    useEffect(() => {
        fetchQuotesRef.current(filterConfigs);
    }, [formData?.id, filterConfigs]);

    fetchQuotesRef.current = async (query = {}) => {
        try {
            if (formData?.contractualisation_type !== Functions.TEXT_CONTRAT_ONLY) {
                let fetchedQuotes;
                if (isSection) {
                    if (formData?.id) {
                        fetchedQuotes = await getQuotesByMission(missionId, query, token);
                    }
                } else {
                    if (isFreelanceSession) {
                        fetchedQuotes = await getQuotesByFreelance(query, token);
                    } else {
                        fetchedQuotes = await getQuotesByCompany(query, token);
                    }
                }
                if (fetchedQuotes && !fetchedQuotes?.error) {
                    setRowCount(fetchedQuotes.count);
                    setQuotes(fetchedQuotes.data);
                }
            }
        } catch (error) {
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormQuote({
            ...formQuote, [name]: value
        });
    };

    const handleBack = () => {
        switch (stepQuote) {
            case Functions.TEXT_DETAILS:
                setStepQuote(Functions.TEXT_DESCRIPTION);
                break;
            default:
                break;
        }
    };

    const handleNext = async () => {
        const currentStep = stepQuote;
        let datas;
        let verifyData;

        const msgError = "Error saving data";

        verifyData = Utils.verifyDataQuote(t, formQuote, currentStep);
        if (verifyData.type === "error") {
            setMessage(verifyData);
            return;
        }

        switch (currentStep) {
            case Functions.TEXT_DESCRIPTION:
                setStepQuote(Functions.TEXT_DETAILS);
                setMessage({});
                break;
            case Functions.TEXT_DETAILS:
                if (quoteFile.type !== "application/pdf") {
                    setMessage({
                        type: "error", message: t("Format allowed") + " , PDF",
                    });
                } else {
                    //Save datas for Freelance
                    try {
                        datas = formQuote;
                        datas.missionId = formData.id;
                        datas.companyId = formData.CompanyId;
                        datas.freelanceProfileId = formData.staffedFreelanceId;
                        datas.quote_type = formData.quote_type;

                        //## Create the file
                        if (quoteFile) {
                            const fileData = {
                                typeFile: Functions.TEXT_DOCUMENT, file: quoteFile.theFile,
                            };
                            datas["file"] = await createFile(fileData, "", token);
                        }

                        const createdQuote = await createQuote(datas, token);

                        if (createdQuote.error) {
                            setMessage({type: "error", message: msgError});
                        } else {
                            handleQuoteDialogClose();

                            setStepQuote(Functions.TEXT_DESCRIPTION);
                            setMessage({});
                            missionRxjs.setQuoteFile(null);

                            handleInitList();

                            const newSectionStatus = Utils.updateStatusSection(formData.status_section, Functions.SECTION_QUOTE);
                            setFormData({...formData, status_section: newSectionStatus});
                        }
                    } catch (error) {
                        setMessage({type: "error", message: msgError});
                    }
                }
                break;
            default:
                break;
        }
    };

    const handleQuoteDialogClose = () => {
        if (deleteQuoteFileRef.current) {
            deleteQuoteFileRef.current.click();
        }
        setShowQuoteDialog(false);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleQuoteCellClick = (row) => {
        if (isSection) {
            setSelectedQuote(row);
        } else {
            navigate(`/quotes/${row.id}`);
        }
    };

    const handleInitList = () => {
        setSelectedQuote({});
        setFormQuote(defaultFormQuote);
        tableRef.current && tableRef.current.initTable();
        setFilterConfigs(defaultFilterConfigs);
    };

    if (ref) {
        ref.current = handleInitList;
    }

    return <>
        {formData?.contractualisation_type === Functions.TEXT_CONTRAT_ONLY && <div
            className="card standardform-card card-padding-wide"
            style={{flex: "none", alignItems: "start", width: "64%"}}
        >
            <Typography variant="label">{t("Assignment without quote")}</Typography>
        </div>}
        {formData?.contractualisation_type !== Functions.TEXT_CONTRAT_ONLY &&
            <div className={`h-100 ${isSection ? "card standardform-card card-padding-wide" : ""}`}>
                {Object.keys(selectedQuote).length === 0 && (
                    <Box className="common-board-container">
                        <Stack direction="row" justifyContent="space-between" spacing={1}>
                            {!isSection && (<div className="pageheader">
                                <h1 className="pagetitle">
                                    {isFreelanceSession ? t("My quotes") : t("Quotes")}
                                    <p className="header-data-row-count">{rowCount}</p>
                                </h1>
                            </div>)}
                            {isSection && (<Typography variant="labelInfo">
                                {t("Quote list")}
                            </Typography>)}
                            {isSection && canModify && <>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setShowQuoteDialog(true)}
                                >
                                    {t("Add a quote")}
                                </Button>

                                <Popup
                                    onDialogClose={handleQuoteDialogClose}
                                    open={showQuoteDialog}
                                >
                                    <Box sx={{width: {md: "40vw"}}}>
                                        <Grid container direction="column" columns={12} spacing={2}>
                                            <Grid item xs={12}>
                                                <ResponsiveStepper
                                                    steps={steps}
                                                    stepKey={stepQuote}
                                                    onStepClick={(index) => (setStepQuote(steps[index]?.key))}
                                                />

                                                <MessageComponent
                                                    type={message?.type}
                                                    message={message?.message}
                                                    width="100%"
                                                />
                                            </Grid>

                                            {stepQuote === Functions.TEXT_DESCRIPTION && (<>
                                                <Grid item xs={12}>
                                                    <FieldHolder
                                                        fielddesc={t("Document name")}
                                                        type="text"
                                                        name="name"
                                                        id="quote_name"
                                                        placeholder={t("Document name")}
                                                        value={formQuote?.name}
                                                        onChange={handleChange}
                                                        canModify={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FieldHolder
                                                        ref={deleteQuoteFileRef}
                                                        fielddesc={t("File")}
                                                        type="file"
                                                        name="file"
                                                        id="quote_file"
                                                        value={formQuote?.file}
                                                        sibling="missionQuote"
                                                        formData={formQuote}
                                                        setFormData={setFormQuote}
                                                        objectAttachedType={objectAttachedType}
                                                        canModify={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Stack direction="column" spacing={2}>
                                                        <FieldHolder
                                                            fielddesc={t("Mission")}
                                                            type="text"
                                                            placeholder={t("Mission")}
                                                            value={formData?.name}
                                                            objectAttachedType={objectAttachedType}
                                                            canModify={false}
                                                        />
                                                        <FieldHolder
                                                            fielddesc={t("Enterprise")}
                                                            type="text"
                                                            placeholder={t("Enterprise")}
                                                            value={formData.Company?.name}
                                                            objectAttachedType={objectAttachedType}
                                                            canModify={false}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </>)}

                                            {stepQuote === Functions.TEXT_DETAILS && (<>
                                                <Grid item container xs={12} spacing={2}>
                                                    <Grid item xs={6}>
                                                        <FieldHolder
                                                            fielddesc={t("Amount")}
                                                            type="text"
                                                            name="amount"
                                                            id="quote_amount"
                                                            placeholder={t("Amount")}
                                                            formData={formQuote}
                                                            setFormData={setFormQuote}
                                                            value={formQuote?.amount}
                                                            onChange={handleChange}
                                                            mandatoryFlag={true}
                                                            canModify={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FieldHolder
                                                            fielddesc={t("Currency")}
                                                            type="dropdown"
                                                            set="Exchange"
                                                            name="devise"
                                                            id="quote_devise"
                                                            value={formQuote?.devise}
                                                            onChange={handleChange}
                                                            formData={formQuote}
                                                            setFormData={setFormQuote}
                                                            mandatoryFlag={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FieldHolder
                                                        fielddesc={t("Message")}
                                                        type="multiline"
                                                        name="commentEnterprise"
                                                        id="commentEnterprise"
                                                        placeholder={t("Message")}
                                                        value={formQuote?.commentEnterprise || ""}
                                                        onChange={handleChange}
                                                        canModify={true}
                                                    />
                                                </Grid>
                                            </>)}

                                            <Grid item xs={12}
                                                  sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                                                <Button
                                                    variant="contained"
                                                    color="light"
                                                    onClick={handleBack}
                                                >
                                                    {t("Previous")}
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleNext}
                                                >
                                                    {stepQuote === Functions.TEXT_DETAILS ? t("Send quote") : t("Next")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Popup>
                            </>}
                        </Stack>
                        <Box className="table-layout">
                            <Table
                                fullHeight={isSection}
                                ref={tableRef}
                                columns={columns}
                                data={quotes}
                                rowCount={rowCount}
                                pageSize={pageSize}
                                filterConfigs={filterConfigs}
                                onChange={handleDataGridChange}
                                onCellClick={handleQuoteCellClick}
                            />
                        </Box>
                    </Box>)}
                {Object.keys(selectedQuote).length > 0 && (<QuoteDetailSection
                    formData={formData}
                    selectedQuote={selectedQuote}
                    setSelectedQuote={setSelectedQuote}
                    sessionType={sessionType}
                    isSection={isSection}
                />)}
            </div>}
    </>
});
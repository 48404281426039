import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Button} from "@mui/material";
import {useSelector} from "react-redux";
import Popup from "../../../Popup/Popup";
import {deleteReferral, fetchFreelanceReferrals} from "../../../../services/referralService";
import {ReferralList} from "./ReferralSection/ReferralList";
import ReferralForm from "./ReferralSection/ReferralForm";
import {useParams} from "react-router-dom";
import {useFormStore} from "../../../../store/formStore";
import Functions from "../../../../utils/Functions";
import ReferralContactAccessForm from "./ReferralSection/ReferralContactAccessForm";

export const ReferralSection = ({formData, canModify, ...props}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const params = useParams();
    const id = formData?.id ?? params.id; // FreelanceProfileId
    const [data, setData] = useState([]);
    const defaultValue = {
        FreelanceProfileId: id,
        firstName: '',
        lastName: '',
        email: null,
        phoneNumber: '',
        linkedinProfile: '',
        companyName: '',
        context: '',
        contactPermission: ''
    };
    const [loading, setLoading] = useState(true);
    const [showReferralContactDialog, setShowReferralContactDialog] = useState(false);
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const fetchFreelanceReferralsRef = useRef();
    const {referenceAccessData} = useFormStore();

    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    useEffect(() => {
        fetchFreelanceReferralsRef.current({});
    }, []);

    fetchFreelanceReferralsRef.current = (query = {}) => {
        try {
            fetchFreelanceReferrals(id, query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    setData(fetchedData.data);
                }
                setLoading(false);
            })
        } catch (error) {
        }
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleEdit = (id) => {
        const index = data.findIndex((referral) => referral.id === id);
        if (index > -1) {
            setEditingData(data[index] ?? defaultValue);
            setEditingMode(true);
        }
    };

    const handleDelete = async (id) => {
        await deleteReferral(id, token);
        fetchFreelanceReferralsRef.current();
    };

    const handleDialogClose = () => {
        setEditingMode(false);
    };

    const handleSaveFinish = (success) => {
        if (success) {
            fetchFreelanceReferralsRef.current({});
            handleDialogClose();
        }
    };

    const handleReferralContactAccessFinish = (success) => {
        if (success) {
            setShowReferralContactDialog(false);
        }
    };

    return <>
        <div className='card transparent card-padding-wide'>
            {canModify
                ? (
                    <Box style={{width: '100%', marginBottom: "15px", textAlign: "right"}}>
                        <Button color="secondary" variant="contained"
                                onClick={handleCreate}>
                            {t("Add a referral")}
                        </Button>
                        <Popup
                            title={t("Add a referral")}
                            open={isEditingMode}
                            onDialogClose={handleDialogClose}
                        >
                            <ReferralForm
                                referral={editingData}
                                onSaveFinish={handleSaveFinish}
                                token={token}
                            />
                        </Popup>
                    </Box>
                )
                : (
                    isEnterpriseSession && data.length > 0 &&
                    referenceAccessData?.id && !referenceAccessData.referenceAccessRequestCode && (
                        <Box style={{width: '100%', marginBottom: "15px", textAlign: "right"}}>
                            <Button color="secondary" variant="contained"
                                    onClick={() => setShowReferralContactDialog(true)}>
                                {t("Request access to references")}
                            </Button>
                            {showReferralContactDialog && (
                                <Popup
                                    title={t("Request access to references")}
                                    open={showReferralContactDialog}
                                    onDialogClose={() => setShowReferralContactDialog(false)}
                                >
                                    <ReferralContactAccessForm
                                        onSaveFinish={handleReferralContactAccessFinish}
                                        token={token}
                                    />
                                </Popup>
                            )}
                        </Box>
                    )
                )
            }

            <ReferralList
                loading={loading}
                referrals={data}
                canModify={canModify}
                onEdit={handleEdit}
                onDelete={handleDelete}
                fullWidth={props.fullWidth}
            />
        </div>
    </>
};
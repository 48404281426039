import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Grid, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {deleteInteraction, fetchFreelanceInteractions} from "../../../../services/companyFreelanceInteractionService";
import {Display} from "../../../Inputs/Display";
import Popup from "../../../Popup/Popup";
import {CompanyFreelanceInteractionForm} from "./CompanyFreelanceInteractionForm";
import {MdGroups, MdMail, MdMessage, MdMoreHoriz, MdPhone} from "react-icons/md";
import Functions from "../../../../utils/Functions";
import Utils from "../../../../utils/Utils";
import {BiSolidBriefcase} from "react-icons/bi";

export const CompanyFreelanceInteraction = ({freelanceProfile, type, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const isNoteSection = (type === "note");
    const [data, setData] = useState([]);
    const defaultValue = {
        FreelanceProfileId: freelanceProfile?.id,
        type: isNoteSection ? Functions.INTERACTION_TYPE.NOTE : Functions.INTERACTION_TYPE.CALL,
        title: null,
        description: null,
        date: null,
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);

    useEffect(() => {
        loadFreelanceInteractions({});
    }, [freelanceProfile?.id]);

    const loadFreelanceInteractions = useCallback((query = {}) => {
        try {
            if (freelanceProfile?.id) {
                const interactionTypes = [
                    Functions.INTERACTION_TYPE.CALL, Functions.INTERACTION_TYPE.EMAIL,
                    Functions.INTERACTION_TYPE.MESSAGE, Functions.INTERACTION_TYPE.MEETING,
                    Functions.INTERACTION_TYPE.SMARTEEM_EVENT
                ];
                const noteTypes = [Functions.INTERACTION_TYPE.NOTE];

                const filter = {
                    type: {cond: 'and', operator: 'in', value: isNoteSection ? noteTypes : interactionTypes}
                };
                query.filter = JSON.stringify(filter);
                fetchFreelanceInteractions(freelanceProfile.id, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setData(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [freelanceProfile?.id, token, isNoteSection]);

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleEdit = (id) => {
        const index = data.findIndex((achievement) => achievement.id === id);
        if (index > -1) {
            setEditingData(data[index] ?? defaultValue);
            setEditingMode(true);
        }
    }

    const handleDelete = async (id) => {
        await deleteInteraction(id, token);
        loadFreelanceInteractions({});
    }

    const handleDialogClose = () => {
        setEditingMode(false);
    }

    const handleSaveFinish = (success) => {
        if (success) {
            loadFreelanceInteractions({});
            handleDialogClose();
        }
    }

    return <Box sx={{width: '100%'}}>
        <Stack direction="column" alignItems="flex-start" spacing={2}>
            <Stack direction="row" justifyContent="end" sx={{width: '100%'}}>
                {canModify && (
                    <>
                        <Button variant="outlined" size="small" color="info" onClick={handleCreate}>
                            {isNoteSection ? t("New note") : t("New interaction")}
                        </Button>

                        <Popup
                            title={isNoteSection ? t("Note") : t("Interaction")}
                            open={isEditingMode}
                            onDialogClose={handleDialogClose}
                        >
                            <CompanyFreelanceInteractionForm
                                interaction={editingData}
                                interactionType={type}
                                onSaveFinish={handleSaveFinish}
                                onDialogClose={handleDialogClose}
                            />
                        </Popup>
                    </>
                )}
            </Stack>
            <Stack direction="column" alignItems="start" spacing={2} sx={{width: '100%'}}>
                {data?.map((interaction, index) => (
                    <CompanyFreelanceInteractionRow
                        key={index}
                        interaction={interaction}
                        canModify={canModify}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                ))}
            </Stack>
        </Stack>
    </Box>
};

export const CompanyFreelanceInteractionRow = ({interaction, canModify, onEdit, onDelete}) => {
    const {t} = useTranslation();

    const [showMenu, setShowMenu] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const creatorUser = interaction?.User?.fullName || "";
    const isSmarteemEvent = interaction.type === Functions.INTERACTION_TYPE.SMARTEEM_EVENT;

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleEdit = () => {
        handleClose();
        onEdit && onEdit(interaction.id);
    };

    const handleDelete = () => {
        handleClose();
        onDelete && onDelete(interaction.id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <Grid
        container
        className="interaction-item"
        gap={1.5}
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
    >
        {interaction.type === Functions.INTERACTION_TYPE.NOTE
            ? (
                <Grid item xs={12}>
                    <Display
                        type="wysiwyg"
                        value={interaction.description}
                    />
                    {creatorUser && (
                        <Typography className="interaction-user">{creatorUser}</Typography>
                    )}
                </Grid>
            )
            : (
                <>
                    <Grid item xs="auto">
                        {getInteractionTypeIcon(interaction.type, 25).icon}
                    </Grid>
                    <Grid item xs container direction="column" gap={0.5}>
                        <Typography sx={!isSmarteemEvent ? {fontWeight: "bold"} : {}}>{interaction.title}</Typography>
                        {interaction.date && (
                            <Typography>{Utils.formatDate(interaction.date)}</Typography>
                        )}
                        {interaction.description && <Typography>{interaction.description}</Typography>}
                        {(isSmarteemEvent || creatorUser) && (
                            <Typography className="interaction-user">
                                {creatorUser ? creatorUser : t("Smarteem notification")}
                            </Typography>
                        )}
                    </Grid>
                </>
            )
        }
        {!isSmarteemEvent && canModify && showMenu && (
            <div style={{position: "absolute", top: "5px", right: "5px"}}>
                <Grid container justifyContent="flex-end">
                    <IconButton
                        aria-controls="item-actions-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="small"
                    >
                        <MdMoreHoriz size={20}/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    >
                        <MenuItem onClick={handleEdit}>{t("Edit")}</MenuItem>
                        <MenuItem onClick={handleDelete}>
                            <Typography color="secondary">{t("Delete")}</Typography>
                        </MenuItem>
                    </Menu>
                </Grid>
            </div>
        )}
    </Grid>
};

export const getInteractionTypeIcon = (type, size) => {
    const iconMap = {
        [Functions.INTERACTION_TYPE.CALL]: {icon: <MdPhone size={size}/>, name: "Call"},
        [Functions.INTERACTION_TYPE.EMAIL]: {icon: <MdMail size={size}/>, name: "Email"},
        [Functions.INTERACTION_TYPE.MESSAGE]: {icon: <MdMessage size={size}/>, name: "Message"},
        [Functions.INTERACTION_TYPE.MEETING]: {icon: <MdGroups size={size}/>, name: "Meeting"},
        [Functions.INTERACTION_TYPE.SMARTEEM_EVENT]: {icon: <BiSolidBriefcase size={size}/>, name: "Event"},
    };

    return iconMap[type] || {};
};

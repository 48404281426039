import React, {useRef, useState} from "react";
import {CustomCard} from "../../../../UI/CustomCard";
import {OtherAssignmentForm} from "./OtherAssignmentForm";

export const OtherAssignmentDetailSection = ({assignment, canModify}) => {
    const [editMode, setEditMode] = useState(false);
    const otherAssignmentFormRef = useRef(null);

    const handleSave = async () => {
        otherAssignmentFormRef?.current();
    };

    const handleSaveFinish = (success) => {
        if (success) {
            setEditMode(false);
        }
    };

    return (
        <CustomCard
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={canModify}
            onSave={handleSave}
        >
            <OtherAssignmentForm
                ref={otherAssignmentFormRef}
                assignment={assignment}
                canModify={editMode}
                onSaveFinish={handleSaveFinish}
            />
        </CustomCard>
    );
};
import {AppLayout} from "../../layouts/AppLayout";
import {useSelector} from "react-redux";
import {InvoiceListSection} from "../../components/Forms/Mission/Sections/InvoiceSection/InvoiceListSection";

export const InvoicesBoard = () => {
    const sessionType = useSelector((state) => state.auth.sessionType);
    return (
        <AppLayout className="content-full-height">
            <InvoiceListSection sessionType={sessionType} isSection={false}/>
        </AppLayout>
    );
};

export default InvoicesBoard;

import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

import {fetchFreelanceProfile} from "../../../services/freelanceProfileService";

import "../form.css";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";
import Functions from "../../../utils/Functions";
import {ProfileSection} from "../FreelanceProfile/Sections/ProfileSection/ProfileSection";
import {AvailabilitySection} from "../FreelanceProfile/Sections/AvailabilitySection";
import {ReferralSection} from "../FreelanceProfile/Sections/ReferralSection";
import {
    InformationAndDocumentSection
} from "../FreelanceProfile/Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import Utils from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {useFormStore} from "../../../store/formStore";

export const FreelanceProfileSteps = forwardRef((
    {
        activeStepKey,
        className,
        setLoading,
        setMessage,
        onSubmit
    },
    ref
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const freelanceId = useSelector((state) => state.auth.freelanceId);

    const {
        freelanceData,
        setFreelanceData,
        freelanceEditPermissions,
        setFreelanceEditPermissions
    } = useFreelanceProfileStore();

    const {editingCard, setEditingCard} = useFormStore();

    const [triggerApi, setTriggerApi] = useState(true);
    const formRef = useRef(null);
    const objectAttachedType = "freelanceProfile";

    useEffect(() => {
        return () => {
            setEditingCard({});
        };
    }, []);

    useEffect(() => {
        try {
            fetchFreelanceProfile(freelanceId, token, true).then(fetchedFreelanceProfile => {
                if (!fetchedFreelanceProfile?.error) {
                    setFreelanceData(fetchedFreelanceProfile);
                    setFreelanceEditPermissions([Functions.FREELANCE_PROFILE_PERMISSION.FULL]);
                }
            });
            setTriggerApi(false);
        } catch (error) {
            console.error(error);
        }
    }, [freelanceId, triggerApi, token]);

    const checkEditingForm = () => {
        if (Object.keys(editingCard).length === 0) {
            return true;
        }
        return Object.values(editingCard).every(value => value === false);
    };

    const handleNext = async () => {
        const verifyData = Utils.verifyDataFreelance(t, freelanceData, activeStepKey);
        if (verifyData.type === "error") {
            setMessage(verifyData);
            return;
        }

        if (!checkEditingForm()) {
            setMessage({type: "error", message: "Please save the current changes before continuing"});
            return;
        }

        onSubmit && onSubmit({success: true});
    };

    if (ref) {
        ref.current = handleNext;
    }

    const standardFormClass = className || "standardform-w70";

    return (
        <>
            <form
                ref={formRef}
                className={`standardform ${standardFormClass}`}
                noValidate
            >
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <ProfileSection
                        formData={freelanceData}
                        objectAttachedType={objectAttachedType}
                        editPermissions={freelanceEditPermissions}
                        canModify={true}
                    />
                )}
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                    <AvailabilitySection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={freelanceEditPermissions}
                    />
                )}
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                    <ReferralSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        canModify={true}
                    />
                )}
                {activeStepKey === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                    <InformationAndDocumentSection
                        formData={freelanceData}
                        objectAttachedType={objectAttachedType}
                        setFormData={setFreelanceData}
                        editPermissions={freelanceEditPermissions}
                        canModify={true}
                    />
                )}
            </form>
        </>
    );
});

import {Grid} from "@mui/material";
import {FieldHolder} from "../FieldHolder";
import {useTranslation} from "react-i18next";
import {forwardRef, useRef, useState} from "react";
import Utils from "../../../utils/Utils";

const ContactStep = forwardRef(({data, setData, onChange, onSubmit}, ref) => {
    const {t} = useTranslation();
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = ['companyName', 'email', 'firstName', 'lastName'];

    const handleSubmit = (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return
        }

        onSubmit && onSubmit({success: true});
    };
    ref.current = handleSubmit;

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            style={{display: "flex", flexDirection: "column", gap: "15px"}}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Company name")}
                        type="text"
                        name="companyName"
                        id="companyName"
                        value={data.companyName}
                        onChange={onChange}
                        mandatoryFlag={true}
                        error={formErrors.companyName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("First name")}
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={data.firstName}
                        onChange={onChange}
                        mandatoryFlag={true}
                        error={formErrors.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("Last name")}
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={data.lastName}
                        onChange={onChange}
                        mandatoryFlag={true}
                        error={formErrors.lastName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Email")}
                        type="text"
                        name="email"
                        id="email"
                        value={data.email}
                        onChange={onChange}
                        mandatoryFlag={true}
                        error={formErrors.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Language")}
                        type="dropdown"
                        set="Language"
                        name="language"
                        id="language"
                        datas={[]}
                        formData={data}
                        setFormData={setData}
                        onChange={onChange}
                        canModify={true}
                        error={formErrors.language}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Message for the company")}
                        type="multiline"
                        name="companyMessage"
                        id="companyMessage"
                        value={data.companyMessage}
                        onChange={onChange}
                        canModify={true}
                        error={formErrors.companyMessage}
                    />
                </Grid>
            </Grid>
        </form>
    );
})

export default ContactStep
import React, {memo, useState} from 'react';
import {IconButton, Box} from '@mui/material';
import {styled} from '@mui/system';
import {MdCheck, MdContentCopy} from "react-icons/md";
import {useTranslation} from "react-i18next";

const HoverBox = styled('div')(({theme}) => ({
    position: 'relative',
    width: '100%',
    display: 'inline-block',
    '&:hover .copy-button': {
        display: 'inline-flex',
    },
}))

const CopyButton = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    right: '-8px',
    transform: 'translateY(-50%)',
    display: 'none'
}));

const HoverCopyButton = memo(({children, textToCopy}) => {
    const {t} = useTranslation();
    const [copied, setCopied] = useState(false);

    const handleCopy = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    return (
        <HoverBox>
            {children}
            <CopyButton
                className="copy-button"
                title={copied ? "" : t("Copy")}
                size="small"
                onClick={handleCopy}
                aria-label={copied ? t("Copied!") : t("Copy")}
            >
                {copied ? <MdCheck/> : <MdContentCopy/>}
            </CopyButton>
        </HoverBox>
    );
}, (prevProps, nextProps) => {
    return prevProps.textToCopy === nextProps.textToCopy;
});

export default HoverCopyButton;

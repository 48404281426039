import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import Functions from "../../../../../utils/Functions";
import {MdCheck, MdDehaze, MdDelete} from "react-icons/md";
import {FieldHolder} from "../../../FieldHolder";
import Utils from "../../../../../utils/Utils";
import {CustomCard} from "../../../../UI/CustomCard";
import {DragDropContext, Draggable} from "react-beautiful-dnd";
import {StrictModeDroppable} from "../../../../UI/StrictModeDroppable";

export const OfferProcessStep = ({offerData, setOfferData, onSave, canModify}) => {
    const {t} = useTranslation();

    const editMode = offerData.steps.includes(Functions.OFFER_STEP.PROCESS);
    const [pipelineEditMode, setPipelineEditMode] = useState(canModify && !editMode);
    const [pipelines, setPipelines] = useState([]);

    useEffect(() => {
        if (offerData?.RecruitmentPipelines !== null) {
            const updatedPipelines = offerData?.RecruitmentPipelines || [];

            // Ajouter une colonne "New" si elle n'existe pas déjà
            const hasNewColumn = updatedPipelines.some(pipeline => pipeline.title === "New");
            if (!hasNewColumn) {
                updatedPipelines.splice(0, 0,
                    {
                        title: 'New',
                        isReadOnly: true,
                        displayOrder: 1
                    },
                    {
                        title: 'To meet',
                        isReadOnly: false,
                        displayOrder: 2
                    },
                    {
                        title: 'Interview scheduled',
                        isReadOnly: false,
                        displayOrder: 3
                    },
                    {
                        title: 'Pipeline.Rejected',
                        isReadOnly: false,
                        displayOrder: 4
                    },
                    {
                        title: 'Hired',
                        isReadOnly: false,
                        displayOrder: 5
                    }
                );
            }

            if (pipelineEditMode) {
                // Ajouter une colonne par défaut si aucune n'est présente
                const hasDefault = updatedPipelines.some(pipeline => pipeline.default === true);
                if (!hasDefault) {
                    updatedPipelines.push({
                        title: '',
                        isReadOnly: false,
                        displayOrder: updatedPipelines.length + 1,
                        default: true,
                        editMode: true
                    });
                }
            }

            setPipelines([...updatedPipelines]);
        }
    }, [offerData, pipelineEditMode]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(pipelines);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedPipelines = items.map((item, index) => ({...item, displayOrder: index + 1}));
        setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
    };

    const handlePipelineDelete = (pipeline) => {
        const updatedPipelines = [...pipelines];
        updatedPipelines.splice(pipeline.index, 1);
        setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
    };

    const handlePipelineSave = (pipeline) => {
        const updatedPipelines = [...pipelines];
        updatedPipelines[pipeline.index] = {
            ...pipeline,
            default: false,
            editMode: false,
        };
        setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
    };

    const handleSave = async () => {
        const result = onSave && await onSave();
        if (!result?.error) {
            setPipelineEditMode(false);
        }
    };

    return (
        <>
            <CustomCard
                className="justify-content-center"
                editMode={pipelineEditMode}
                setEditMode={setPipelineEditMode}
                canModify={canModify && editMode}
                onSave={handleSave}
            >
                <Typography variant="label">{t("Pipeline column")}</Typography>

                {pipelines.length > 0 && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <StrictModeDroppable droppableId="pipelines">
                            {(provided) => (
                                <Stack
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    direction="column"
                                    className="w-100"
                                    gap={2}
                                >
                                    {pipelines.map((pipeline, index) => {
                                        pipeline.index = index;
                                        if (pipelineEditMode || !pipeline.default) {
                                            return (
                                                <Draggable
                                                    key={`tmp${index}`}
                                                    draggableId={`tmp${index}`}
                                                    index={index}
                                                    isDragDisabled={Boolean(pipeline.default)}
                                                >
                                                    {(provided) => (
                                                        <RecruitmentPipelineRow
                                                            provided={provided}
                                                            pipeline={pipeline}
                                                            editMode={pipelineEditMode}
                                                            onSave={handlePipelineSave}
                                                            onDelete={handlePipelineDelete}
                                                        />
                                                    )}
                                                </Draggable>
                                            );
                                        }
                                        return null;
                                    })}
                                    {provided.placeholder}
                                </Stack>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                )}
            </CustomCard>

            {!editMode && (
                <Stack alignItems="center" className="w-100">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSave}
                    >
                        {t("Save")}
                    </Button>
                </Stack>
            )}
        </>
    );
};

const RecruitmentPipelineRow = ({provided, pipeline, editMode, onSave, onDelete}) => {
    const {t} = useTranslation();

    const [pipelineData, setPipelineData] = useState(pipeline);
    const [formErrors, setFormErrors] = useState({});

    const formRef = useRef(null);
    const hasError = Object.keys(formErrors).length > 0;

    useEffect(() => {
        setPipelineData(pipeline);
        if (pipeline?.default && pipeline.editMode && formRef.current) {
            const titleField = formRef.current.elements.title;
            titleField && titleField.focus();
        }
    }, [pipeline]);

    const handleItemClick = () => {
        if (!editMode || pipeline.isReadOnly) return;
        setPipelineData((prevData) => ({...prevData, editMode: true}));
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setPipelineData((prevData) => ({...prevData, [name]: value}));
    };

    const handleSave = (e) => {
        e.preventDefault();

        let {valid, errors} = Utils.validateRequiredFields(pipelineData, ['title']);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (valid && onSave) {
            onSave(pipelineData);
        }
    };

    return (
        <form ref={formRef} noValidate>
            <Box
                {...provided.draggableProps}
                ref={provided.innerRef}
                className={`recruitment-pipeline-item ${pipelineData.editMode ? "edit" : ""} ${hasError ? "error" : ""}`}
            >
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Grid container alignItems="center" spacing={1}>
                            {editMode && (
                                <Grid item xs="auto" {...provided.dragHandleProps}>
                                    < MdDehaze
                                        color="primary"
                                        size={20}
                                        style={{cursor: "pointer", color: "rgba(0, 0, 0, 0.5)"}}
                                    />
                                </Grid>
                            )}
                            <Grid item xs>
                                {pipelineData?.editMode
                                    ? (
                                        <FieldHolder
                                            type="text"
                                            name="title"
                                            id={`pipelineTitle${pipelineData?.index}`}
                                            placeholder={t("New column")}
                                            defaultValue={t(pipelineData?.title)}
                                            onBlur={handleChange}
                                            canModify={true}
                                            mandatoryFlag={true}
                                            error={formErrors.title}
                                        />
                                    )
                                    : (
                                        <Typography
                                            onDoubleClick={handleItemClick}
                                            sx={{px: '14px', py: '5px'}}
                                        >
                                            {t(pipelineData?.title)}
                                            {!editMode && pipelineData?.isRequired && (
                                                <span className="mandatory-text"> *</span>)}
                                        </Typography>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {editMode && !pipeline.isReadOnly && (
                        <>
                            <Grid item xs="auto">
                                {pipelineData?.editMode
                                    ? (
                                        <IconButton
                                            title={t("Save")}
                                            onClick={handleSave}
                                            color="secondary"
                                            size="small"
                                            sx={{border: '1px solid var(--secondary-main-color)', p: '2px'}}
                                        >
                                            <MdCheck size={20}/>
                                        </IconButton>
                                    )
                                    : (
                                        <IconButton
                                            title={t("Delete")}
                                            onClick={() => onDelete && onDelete(pipelineData)}
                                            color="secondary"
                                            size="small"
                                        >
                                            <MdDelete size={20}/>
                                        </IconButton>
                                    )
                                }
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </form>
    );
};
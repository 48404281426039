import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/enterprisecontact/";

export const fetchRequests = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching request freelance :", error);
        throw error;
    }
};

export const fetchPublicFreelanceProfile = async (freelanceId) => {
    try {
        return requestApi(`${BASE_URL}freelance/${freelanceId}`, 'GET');
    } catch (error) {
        console.error("Error fetching freelance", error);
        throw error;
    }
};

export const fetchRequest = async (id) => {
    try {
        return requestApi(`${BASE_URL}${id}`, 'GET');
    } catch (error) {
        console.error("Error fetching request freelance", error);
        throw error;
    }
};

export const createRequest = async (requestData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', requestData, token);
    } catch (error) {
        console.error("Error creating request :", error);
        throw error;
    }
};
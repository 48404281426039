import {Box, Button, Grid} from "@mui/material";
import Popup from "./Popup";
import {useTranslation} from "react-i18next";

const ConfirmPopup = ({open, title, onConfirm, onDialogClose}) => {
    const {t} = useTranslation();

    return <Popup
        open={open}
        title={title}
        onDialogClose={onDialogClose}
        style={{margin: 'auto'}}
    >
        <Box sx={{width: {md: "30vw"}}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Button
                        variant="contained"
                        color="light"
                        size="large"
                        onClick={onDialogClose}
                        fullWidth
                    >
                        {t("Cancel")}
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={onConfirm}
                        fullWidth
                    >
                        {t("Confirm")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    </Popup>
};

export default ConfirmPopup;
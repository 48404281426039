import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {FaUserCheck} from "react-icons/fa6";
import {LuShieldCheck} from "react-icons/lu";

import "../form.css";
import "../../../pages/page.css";

import {MessageComponent} from "../../UI/MessageComponent";
import {HelperCard} from "../../UI/HelperCard";

import {
    fetchEnterpriseProfile,
    fetchEnterpriseProfiles,
    updateEnterpriseProfile
} from "../../../services/enterpriseProfileService";
import {EnterprisePopup} from "../../Popup/EnterprisePopup";
import {Table} from "../../Tables/Table";
import Popup from "../../Popup/Popup";
import {Box, Button, Grid, Stack} from "@mui/material";
import {FieldHolder} from "../FieldHolder";
import Functions from "../../../utils/Functions";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const OrganizationSection = ({activeComponent, ...props}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const companyRole = useSelector((state) => state.auth.companyRole);

    const sessionType = useSelector((state) => state.auth.sessionType);
    const [data, setData] = useState([]);
    const [triggerApi, setTriggerApi] = useState(true);

    const [selectedEnterpriseProfileId, setSelectedEnterpriseProfileId] = useState(null);
    const [showEnterprisePopup, setShowEnterprisePopup] = useState(false);
    const [showRolePopup, setShowRolePopup] = useState(false);
    const fetchEnterpriseProfilesRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });
    const dataRole = {companyRole: "", activatedAccount: ""};
    const [formData, setFormData] = useState(dataRole);

    const isAdmin = [
        Functions.USER_ROLE.SUPER_ADMINISTRATOR,
        Functions.USER_ROLE.ADMINISTRATOR
    ].includes(companyRole);

    useEffect(() => {
        if (selectedEnterpriseProfileId) {
            (async () => {
                try {
                    const fetchedData = await fetchEnterpriseProfile(selectedEnterpriseProfileId, token);
                    setFormData({
                        ...formData,
                        companyRole: fetchedData.companyRole,
                        userId: fetchedData.userId,
                        activatedAccount: fetchedData.User.activatedAccount,
                        id: fetchedData.id
                    });
                } catch (error) {
                    console.error(error);
                }
            })();
        }

    }, [selectedEnterpriseProfileId]);

    const actionComponentFunction = ({row}) => {
        if (row.userId !== row?.Company?.superAdministratorUserId) {
            return (
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleClick(row.id)}
                >
                    {t("Edit")}
                </Button>
            );
        } else {
            return <></>;
        }
    };

    const columns = [
        {
            field: 'User.fullName',
            type: 'string',
            headerName: t("Name"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.User.fullName
            }
        },
        {
            field: 'User.email',
            type: 'string',
            headerName: t("Email"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const email = row.User.email
                return <div className="MuiDataGrid-cellContent" title={email} role="presentation">
                    {email}
                </div>
            }
        },
        {
            field: 'companyRole',
            headerName: t("Role"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                const role = t(row.companyRole);
                return <div className="MuiDataGrid-cellContent text-center" title={role} role="presentation">
                    {role}
                </div>
            }
        },
        {
            field: 'activatedAccount',
            headerName: t("Active account"),
            minWidth: 100,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.User.activatedAccount ? <div className={"text-theme fw-bold"}>{t("Yes")}</div> :
                    <div className={"fw-bold"}>{t("No")}</div>;
            }
        },
    ];

    if (isAdmin) {
        columns.push({
            field: 'action',
            headerName: 'Actions',
            minWidth: 120,
            flex: 1.2,
            headerAlign: 'center',
            align: 'center',
            renderCell: actionComponentFunction
        });
    }


    useEffect(() => {
        fetchEnterpriseProfilesRef.current(filterConfigs);
    }, [filterConfigs, triggerApi]);

    fetchEnterpriseProfilesRef.current = async (query = {}) => {
        try {
            if (triggerApi) {
                const fetchedData = await fetchEnterpriseProfiles(query, token);
                setRowCount(fetchedData.count);
                setData(fetchedData.data);

                setTriggerApi(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDataGridChange = (filterConfigs) => {
        setTriggerApi(true);
        setFilterConfigs(filterConfigs);
    };

    const toggleRolePopupVisibility = () => {
        setShowRolePopup(!showRolePopup);
    };

    const onEnterprisePopupOpen = () => {
        setShowEnterprisePopup(true);
        setTriggerApi(true);
    };

    const onEnterprisePopupClose = () => {
        setShowEnterprisePopup(false);
        setTriggerApi(true);
    };

    const handleClick = (enterpriseProfileId) => {
        setSelectedEnterpriseProfileId(enterpriseProfileId);
        toggleRolePopupVisibility();
    };

    let button;
    if (
        sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
        companyRole === Functions.USER_ROLE.SUPER_ADMINISTRATOR
    ) {
        button = (
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={onEnterprisePopupOpen}
            >
                {t("Add a user")}
            </Button>
        );
    } else {
        button = <></>;
    }

    const onDialogRolePopupClose = () => {
        setShowRolePopup(false);
    }

    const handleChangeRole = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Effectue la requête API POST avec les données du formulaire
        (async () => {
            try {
                await updateEnterpriseProfile(selectedEnterpriseProfileId, formData, token);

                setTriggerApi(true);
                onDialogRolePopupClose();
            } catch (error) {
                console.error(error);
            }
        })();
    };

    const rolePopupContent = (
        <Box sx={{width: {md: "25vw"}}}>
            <Grid container columns={12} spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Role")}
                        set="UserRole"
                        type="dropdown"
                        name="companyRole"
                        id="company_role"
                        placeholder={"Role"}
                        value={formData?.companyRole}
                        formData={formData}
                        setFormData={setFormData}
                        onChange={(e) => handleChangeRole(e)}
                        canModify={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t('Active account')}
                        type="checkbox"
                        name="activatedAccount"
                        id="activated_account"
                        placeholder={"Active account"}
                        value={formData?.activatedAccount}
                        onChange={(e) => handleChangeRole(e)}
                        canModify={true}
                    />
                </Grid>
            </Grid>
        </Box>
    );

    const dialogActionRolePopup = <>
        <Stack
            direction="row"
            justifyContent="center"
            gap={1}
        >
            <Button
                variant="contained"
                onClick={handleSubmit}
            >
                {t("Confirm")}
            </Button>
            <Button
                variant="outlined"
                onClick={onDialogRolePopupClose}
            >
                {t("Cancel")}
            </Button>
        </Stack>
    </>


    return (
        <>
            <div className="standardform standardform-w55">
                <div className="card transparent">
                    <div className="pageheader">
                        <h3 className="text-title text-theme">
                            {t("My company's users")}
                        </h3>
                        <div className="pageaction">
                            {button}
                        </div>
                    </div>

                    <Table
                        fullHeight={true}
                        columns={columns}
                        data={data}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        onChange={handleDataGridChange}
                    />

                    <EnterprisePopup
                        open={showEnterprisePopup}
                        onClose={onEnterprisePopupClose}
                    />

                    <Popup
                        open={showRolePopup}
                        onDialogClose={onDialogRolePopupClose}
                        children={rolePopupContent}
                        dialogActions={dialogActionRolePopup}
                        title={t("Role")}
                        maxWidth={"lg"}
                        flexDirectionAction={"column"}
                    />
                </div>
            </div>
            <div className="helpercard-container">
                <HelperCard
                    icon={<FaUserCheck/>}
                    headerText={"After adding a user, what are the next steps?"}
                    descriptionText={"The user will receive a welcome email on the platform.\n\nThey can log in by clicking on \"First connection\" to set up a password."}
                />
                <HelperCard
                    icon={<LuShieldCheck/>}
                    headerText={"Which role to assign to a user?"}
                    descriptionText={"The \"Reader\" role only allows you to view the profiles of freelancers.\n\nThe \"Standard\" role is used to add freelancers to the pool and manage assignments.\n\nThe \"Payer\" role is also used to validate the payment of invoices.\n\nThe \"Administrator\" role is also used to add new users to the solution.\n\nThe \"Super Administrator\" role gives full rights to the platform and cannot be withdrawn.\n\nThe \"Super Administrator\" role gives full rights on the platform."}
                />
            </div>
        </>
    );
};

import React, {memo, useState} from "react";
import {Box, Chip, Popover} from "@mui/material";
import "./customtagcell.css";

export const CustomTagCell = memo(({value}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const tagsChips = value.map((tag, index) => (
        <Chip className="tag-item" key={index} label={tag} size="small"/>
    ));

    return (
        <>
            {
                value.length > 0 && (
                    <div
                        className="custom-tags-container"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        {tagsChips}
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            sx={{
                                pointerEvents: 'none',
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            disableRestoreFocus
                        >
                            <Box
                                className="custom-tags-container"
                                sx={{
                                    minWidth: `250px`,
                                    maxWidth: `250px`,
                                    maxHeight: "600px",
                                    overflowY: "auto"
                                }}
                            >{tagsChips}</Box>
                        </Popover>
                    </div>
                )
            }
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
});
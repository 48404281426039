import Functions from "./Functions";
import moment from "moment-timezone";
import "moment/locale/fr";
import React from "react";
import {DisplayErrorMessage} from "../components/UI/MessageComponent";
import parameters from "./parameters.json";

class Utils {
    emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    constructor() {
        this.msgNull = "ne peut pas être vide";
    }

    verifyDataOrganisationUser(datas) {
        let verif;
        const message = {type: "success", message: null};

        verif = this.checkClenIfNull(datas, "firstName");
        if (verif === "not_ok") {
            return {
                type: "error",
                message: "Le champ nom " + this.msgNull,
            };
        }

        verif = this.checkClenIfNull(datas, "email");
        if (verif === "not_ok") {
            return {
                type: "error",
                message: "Le champ email " + this.msgNull,
            };
        } else {
            const isValidEmail = this.emailRegex.test(datas.email);
            if (!isValidEmail) {
                return {type: "error", message: "Email incorrect."};
            }
        }

        return message;
    }

    verifyDataQuote(t, data, stepCreation, file = null) {
        let requiredFields;

        if (stepCreation === Functions.TEXT_DESCRIPTION) {
            requiredFields = ['name', 'file'];
        } else {
            requiredFields = ['amount', 'devise'];
        }

        return this.verifyData(t, data, 'quote', requiredFields);
    }

    verifyDataContract(datas, currentStep) {
        let verif;
        let message = {type: "success", message: null};

        if (currentStep === Functions.TEXT_CREATION) {
            verif = this.checkClenIfNull(datas, "contractModifType");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ modification du contrat " + this.msgNull,
                };
            }

            verif = this.checkClenIfNull(datas, "contract_type");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ type du contract " + this.msgNull,
                };
            }

        }

        if (currentStep === Functions.TEXT_DETAILS) {
            verif = this.checkClenIfNull(datas, "name");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ nom du contrat " + this.msgNull,
                };
            }

            if (datas.contractModifType === Functions.TEXT_AMENDMENT) {
                verif = this.checkClenIfNull(datas, "amendmentMotif");
                if (verif === "not_ok") {
                    return {
                        type: "error",
                        message: "Le champ motif de l'avenant " + this.msgNull,
                    };
                }

                verif = this.checkClenIfNull(datas, "amendmentEffectiveDate");
                if (verif === "not_ok") {
                    return {
                        type: "error",
                        message:
                            "Le champ date de prise de l'effet de l'avenant " +
                            this.msgNull,
                    };
                }
            }
        }

        return message;
    }

    verifyDataFactureFreelance(datas, stepCreation, file = null) {
        let verif;
        let message = {type: "success", message: null};

        if (stepCreation === Functions.TEXT_DESCRIPTION) {
            verif = this.checkClenIfNull(datas, "name");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ nom du document " + this.msgNull,
                };
            }

            if (file === null) {
                return {
                    type: "error",
                    message: "Le champ fichier " + this.msgNull,
                };
            }
        }

        if (stepCreation === Functions.TEXT_DETAILS) {
            verif = this.checkClenIfNull(datas, "amount");

            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ montant " + this.msgNull,
                };
            }

            verif = this.checkClenIfNull(datas, "dueDate");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ échéance " + this.msgNull,
                };
            }

            verif = this.checkClenIfNull(datas, "devise");
            if (verif === "not_ok") {
                return {
                    type: "error",
                    message: "Le champ devise " + this.msgNull,
                };
            }
        }

        return message;
    }

    verifyDataAssessment(t, data, askEvaluation) {
        let requiredFields;

        if (askEvaluation) {
            requiredFields = ['assessorFirstName', 'assessorUserEmail'];
        } else {
            requiredFields = ['date', 'nps'];
        }

        return this.verifyData(t, data, 'assessment', requiredFields);
    }

    verifyDataFeedback(t, data, action) {
        let requiredFields;

        if (action === "ADD") {
            requiredFields = ['commentToTheFreelance'];
        } else {
            requiredFields = ['nps', 'comment'];
        }

        return this.verifyData(t, data, 'feedback', requiredFields);
    }

    verifyDataFreelance(t, data, stepCreation = "All") {
        let requiredFields = [];

        if (stepCreation === Functions.TEXT_SEARCH) {
            requiredFields = ['email'];
        } else if (stepCreation === Functions.TEXT_PERSONAL_INFO_STEP) {
            requiredFields = ['firstName', 'email'];
        } else if (stepCreation === Functions.TEXT_SELECTION) {
            requiredFields = ['id'];
        } else if (stepCreation === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT) {
            requiredFields = [
                'User.countryOfResidence', 'personalAddress', 'identity_document',
                'legal_status', 'enterprise_name', 'siren_number', 'enterpriseAddress', 'headquarterCountry',
                'kbis', 'rib'
            ];
        }

        return this.verifyData(t, data, 'freelance', requiredFields);
    }

    verifyData(t, data, section, requiredFields) {
        let message = {type: "success", message: null};

        const {valid, errors} = this.validateRequiredFields(data, requiredFields);
        if (!valid) {
            const firstErrorField = Object.keys(errors)[0];
            message = {
                type: "error",
                message: <DisplayErrorMessage
                    t={t}
                    values={{field: `"${t(this.mapFieldToLabel(section, firstErrorField))}"`}}
                />
            };
        }

        return message;
    }

    updateStatusSection(sectionStatus, section) {
        if (sectionStatus && typeof sectionStatus === "object") {
            if (!sectionStatus.includes(section)) {
                sectionStatus = [...sectionStatus, section];
            }
        } else {
            sectionStatus = [section];
        }

        return sectionStatus;
    }

    checkClenIfNull(objet, cle) {
        // Vérifie d'abord si la clé existe dans l'objet
        if (cle in objet) {
            // Ensuite, vérifie si la valeur associée à la clé n'est pas null
            if (objet[cle] !== null && objet[cle] !== "") {
                return "ok"; // La clé existe et n'est pas null
            }
        }
        return "not_ok"; // La clé n'existe pas ou est null
    }

    generateRandomPassword = (length) => {
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
        let password = "";

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters.charAt(randomIndex);
        }

        return password;
    };

    convertDate(dateString) {
        const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        };

        const date = new Date(dateString);
        return date.toLocaleString("fr-FR", options);
    }

    /**
     * Modifier la valeur des champs d'un objet avec plusieurs niveaux d'imbrication
     * de manière récursive et dynamique
     * @param obj
     * @param path
     * @param value
     * @returns {*}
     */
    updateNestedField(obj, path, value) {
        const parts = path.split(".");
        const last = parts.pop();

        parts.reduce((acc, key) => {
            acc[key] = acc[key] || {};
            return acc[key];
        }, obj)[last] = value;

        return {...obj};
    }

    /**
     * Récupérer la valeur des champs d'un objet avec plusieurs niveaux d'imbrication
     * @param obj
     * @param path
     * @param defaultValue
     * @returns {*}
     */
    getNestedValue(obj, path, defaultValue = {}) {
        const parts = path.split(".");
        let result = parts.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : {}, obj);
        if (result !== null && typeof result === 'object' && Object.keys(result).length === 0) {
            result = defaultValue;
        }
        return result;
    }

    formatDate(value, outputFormat = "DD/MM/YYYY", fromFormat = "YYYY-MM-DD") {
        if (value) {
            const date = moment(value, fromFormat);
            value = date.format(outputFormat);
        }
        return value;
    }

    formatDateTz(date, timezone = "Etc/GMT-3", outputFormat = "YYYY-MM-DD") {
        return moment.tz(date.toISOString(), timezone).format(outputFormat);
    };

    formatTime(time, showSecond = false) {
        const date = new Date(time);
        const option = {hour: '2-digit', minute: '2-digit'};
        if (showSecond) {
            option.second = '2-digit';
        }
        return date.toLocaleTimeString([], option);
    };

    timeAgo(date, lang = "fr", withoutSuffix = true) {
        moment.updateLocale('fr', {
            relativeTime: {
                s: '1 s',
                ss: '%d s',
                m: '1 min',
                mm: '%d min',
                h: '1 h',
                hh: '%d h',
                d: '1 j',
                dd: '%d j',
                M: '1 mois',
                MM: '%d mois',
                y: '1 an',
                yy: '%d ans'
            }
        });

        moment.updateLocale('en', {
            relativeTime: {
                s: '1 s',
                ss: '%d s',
                m: '1 min',
                mm: '%d min',
                h: '1 h',
                hh: '%d h',
                d: '1 d',
                dd: '%d d',
                M: '1 mo',
                MM: '%d mo',
                y: '1 y',
                yy: '%d y'
            }
        });

        moment.locale(lang);

        const pastDate = moment(date);
        return pastDate.fromNow(withoutSuffix);
    };

    validateRequiredFields(data, requiredFields) {
        let valid = true;
        const errors = {};

        for (const field of requiredFields) {
            let value = this.getNestedValue(data, field);
            value = typeof value === "string"
                ? value.trim() : data[field];

            if (
                value != null && value !== "" &&
                (!(typeof value === "object" && Object.keys(value).length === 0)) &&
                (!(field.includes("email") && !this.emailRegex.test(value)))
            ) {
                delete errors[field];
            } else {
                errors[field] = true;
                valid &&= false;
            }
        }

        return {valid, errors};
    }

    handleFieldValidation(formRef, errors) {
        if (Object.keys(errors).length > 0) {
            const firstErrorField = Object.keys(errors)[0];
            const errorFieldElement = formRef.current.elements[firstErrorField];

            if (errorFieldElement) {
                // Faites défiler la page jusqu'au champ en erreur
                errorFieldElement.scrollIntoView({behavior: 'smooth', block: 'center'});

                // Mettre l'accent sur le champ en erreur
                errorFieldElement.focus();
            }
        }
    }

    getRequiredFields(key) {
        let requiredFields = [];

        switch (key) {
            case 'referral':
                requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'context', 'companyName'];
                break;
            case 'achievement':
                requiredFields = ['enterprise', 'location', 'startDate'];
                break;
            case 'formation':
                requiredFields = ['degree'];
                break;
            case 'certification':
                requiredFields = ['name'];
                break;
            default:
                break;
        }

        return requiredFields;
    };

    showAmount(value, currency = "EUR", minimumFractionDigits = 2) {
        return value
            ? `${this.numberFormat(value, minimumFractionDigits)} ${this.getCurrencySymbol(currency ?? "")}`
            : "-";
    };

    numberFormat(value, minimumFractionDigits = 2) {
        const formattedNumber = parseFloat(value).toLocaleString('fr-FR', {
            minimumFractionDigits: minimumFractionDigits,
        });

        return formattedNumber.replace('.', ',');
    };

    getCurrencySymbol(currency) {
        let symbol = "";
        switch (currency) {
            case 'EUR':
                symbol = "€";
                break;
            case 'USD':
                symbol = "$";
                break;
            default:
                symbol = currency;
                break;
        }
        return symbol;
    };

    getParameterValues(parameterKey) {
        return parameters[parameterKey];
    };

    getParameterValue(key, parameterKey) {
        const jsonOptions = parameters[parameterKey];
        if (jsonOptions && jsonOptions[key]) {
            return jsonOptions[key].nativetext;
        }
        return key;
    };

    findIndexByKey(items, key) {
        return items.findIndex(item => item.key === key);
    };

    mapFieldToLabel(section, fieldName) {
        const fieldLabelMap = {
            freelance: {
                id: "Freelancer",
                email: "Email",
                firstName: "First name",
                countryOfResidence: "Country of residence",
                "User.countryOfResidence": "Country of residence",
                personalAddress: "Personal address",
                identity_document: "Identity document",
                legal_status: "Legal status",
                enterprise_name: "Corporate name",
                siren_number: "SIREN number",
                enterpriseAddress: "Company address",
                headquarterCountry: "Headquarter country 2",
                kbis: "KBIS or INSEE registration document",
                rib: "RIB",
            },
            mission: {
                name: "Assignment name",
                operational_manager: "Assignment Manager",
                hr_contact: "Contracting manager",
                invoicing_contact: "Invoice Manager",
                deadline: "Assignment type",
                start_date: "Starting date",
                estimated_end_date: "End date",
                address: "Assignment location",
                description: "Activity deliverable",
                staffedFreelanceId: "Staffed freelancer",
                contractualisation_type: "Type of contractualization",
                paymentMethod: "Payment terms",
                payment_amount: "Amount",
                currency: "Currency",
                payment_frequency: "Payment frequency",
                noQuote: "You must receive a quotation to complete the payment terms",
            },
            quote: {
                name: "Document name",
                file: "File",
                amount: "Amount",
                devise: "Currency",
            },
            contract: {},
            assessment: {
                date: "Date",
                nps: "Recommendation level",
                assessorFirstName: "Customer first name",
                assessorUserEmail: "Email",
            },
            feedback: {
                commentToTheFreelance: "Message for the freelancer",
                nps: "Satisfaction level",
                comment: "Comment"
            }
        };

        if (fieldLabelMap[section] && fieldLabelMap[section][fieldName]) {
            return fieldLabelMap[section][fieldName]; // Retourne le libellé correspondant
        } else {
            return fieldName; // Retourne le nom du champ s'il n'est pas trouvé
        }
    };

    isImage(fileName) {
        // Liste des extensions d'images courantes
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];

        // Récupérer l'extension du fichier
        const extension = fileName.toLowerCase().substring(fileName.lastIndexOf('.'));

        // Vérifier si l'extension correspond à une image
        return imageExtensions.includes(extension);
    };

    formatUrl(url) {
        // Vérifier si l'URL commence par http:// ou https://
        const isAbsoluteUrl = this.isAbsoluteUrl(url);

        // Si ce n'est pas une URL absolue, ajouter https:// au début
        return isAbsoluteUrl ? url : `https://${url}`;
    };

    isAbsoluteUrl(url) {
        url = url ? url.toString() : "";
        return url.startsWith('http://') || url.startsWith('https://');
    };

    getRecommendationStatus(score) {
        if (score >= 0 && score < 7) {
            // Couleur orange
            return "recommandation-level-orange";
        } else if (score >= 7 && score < 9) {
            // Couleur jaune
            return "recommandation-level-yellow";
        } else if (score >= 9 && score <= 10) {
            // Couleur verte
            return "recommandation-level-green";
        } else {
            // Score invalide, retourne une couleur par défaut
            return "recommandation-level-default";
        }
    };
}

export default new Utils();

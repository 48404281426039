import {Box, IconButton, Stack} from "@mui/material";
import {MdAdd, MdEdit, MdRemove, MdSave} from "react-icons/md";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Popup from "../Popup/Popup";
import {useFreelanceProfileStore} from "../../store/freelanceProfileStore";
import Functions from "../../utils/Functions";
import {useFormStore} from "../../store/formStore";

export const CustomCard = (
    {
        cardName = "",
        className = "",
        editMode,
        setEditMode,
        otherContent,
        detailDialogContent,
        editDialogContent,
        onSave,
        canModify,
        children,
        ...props
    }
) => {
    const {t} = useTranslation();

    const {freelanceEditPermissions} = useFreelanceProfileStore();
    const {editingCard, setEditingCard} = useFormStore();

    const [showDetailDialog, setShowDetailDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);

    if (typeof canModify === "undefined") {
        canModify = freelanceEditPermissions.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL);
    }

    const handleEdit = () => {
        if (editDialogContent) {
            setShowEditDialog(true);
        } else {
            setEditMode && setEditMode(true);
            if (cardName) {
                setEditingCard({...editingCard, [cardName]: true});
            }
        }
    };

    const handleSave = () => {
        setEditingCard({...editingCard, [cardName]: false});
        onSave && onSave();
    };

    let boxStyle = props.style || {};
    boxStyle.position = "relative";

    let childrenStyle = {
        display: "flex",
        gap: "21px",
        flexDirection: "column",
        alignItems: "start",
        width: "100%",
        height: "100%",
        mt: otherContent ? 2.3 : 0
    };
    if (canModify && editMode) {
        childrenStyle.mt = 2;
    }

    return <>
        <Box className={`card standardform-card h-100 ${className}`} sx={boxStyle}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                sx={{
                    width: "100%",
                    position: "absolute",
                    top: "5px",
                    right: "8px",
                    zIndex: 2
                }}
            >
                {!editMode && otherContent && (
                    <>{otherContent}</>
                )}
                {canModify && (
                    <>
                        {!editMode
                            ? (
                                <>
                                    <IconButton
                                        title={t("Modify")}
                                        onClick={handleEdit}
                                        size="small"
                                    >
                                        <MdEdit size={20}/>
                                    </IconButton>
                                    <Popup
                                        open={showEditDialog}
                                        onDialogClose={() => setShowEditDialog(false)}
                                        dialogCloseIcon={<MdRemove/>}
                                    >
                                        {editDialogContent}
                                    </Popup>
                                </>
                            )
                            : (
                                <IconButton
                                    title={t("Save")}
                                    onClick={handleSave}
                                    size="small"
                                >
                                    <MdSave size={20}/>
                                </IconButton>
                            )
                        }
                    </>
                )}
                {!editMode && detailDialogContent && (
                    <>
                        <IconButton
                            title={t("Details")}
                            onClick={() => setShowDetailDialog(true)}
                            size="small"
                        >
                            <MdAdd size={20}/>
                        </IconButton>
                        <Popup
                            open={showDetailDialog}
                            onDialogClose={() => setShowDetailDialog(false)}
                            dialogCloseIcon={<MdRemove/>}
                        >
                            {detailDialogContent}
                        </Popup>
                    </>
                )}
            </Stack>
            <Box sx={childrenStyle}>{children}</Box>
        </Box>
    </>
};
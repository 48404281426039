import {create} from 'zustand';

export const useFormStore = create((set) => ({
    showMandatoryMark: true,
    referenceAccessData: {
        referenceAccessRequestCode: false,
        referenceAccessAuthorization: false,
    },
    editingCard: {},
    setShowMandatoryMark: (data) => set({showMandatoryMark: data}),
    setReferenceAccessData: (data) => set({referenceAccessData: data}),
    setEditingCard: (data) => set({editingCard: data}),
}));
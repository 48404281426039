import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Grid, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {MdAdd, MdBusiness, MdMoreHoriz} from "react-icons/md";
import Popup from "../../../../../Popup/Popup";
import AchievementForm from "./AchievementForm";
import Utils from "../../../../../../utils/Utils";
import {useSelector} from "react-redux";
import {deleteAchievement, fetchFreelanceAchievements} from "../../../../../../services/achievementService";
import skillService from "../../../../../../services/skillService";
import {Display} from "../../../../../Inputs/Display";

const Achievement = ({freelanceProfile, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [skills, setSkills] = useState([]);
    const [data, setData] = useState([]);
    const defaultValue = {
        FreelanceProfileId: freelanceProfile?.id,
        title: '',
        role: '',
        description: '',
        enterprise: '',
        location: '',
        startDate: null,
        endDate: null,
        ongoingStatus: false,
        appropriatePersonForValidation: '',
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const fetchFreelanceAchievementsRef = useRef();

    useEffect(() => {
        (async () => {
            const defaultSkills = await skillService.getAllDefaultSkill(token);
            const skillsData = skillService.groupSkillsByType(defaultSkills);
            setSkills(skillsData);
        })();
    }, [token]);

    useEffect(() => {
        fetchFreelanceAchievementsRef.current({});
    }, [freelanceProfile?.id]);

    fetchFreelanceAchievementsRef.current = async (query = {}) => {
        try {
            if (freelanceProfile?.id) {
                const fetchedData = await fetchFreelanceAchievements(freelanceProfile?.id, query, token);
                setData(fetchedData.data);
            }
        } catch (error) {

        }
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleEdit = (id) => {
        const index = data.findIndex((achievement) => achievement.id === id);
        if (index > -1) {
            const _data = data[index] ?? defaultValue;
            setEditingData({
                ..._data,
                ongoingStatus: !_data.endDate
            });
            setEditingMode(true);
        }
    }

    const handleDelete = async (id) => {
        await deleteAchievement(id, token);
        fetchFreelanceAchievementsRef.current();
    }

    const handleDialogClose = () => {
        setEditingMode(false);
    }

    const handleSaveFinish = (success) => {
        if (success) {
            fetchFreelanceAchievementsRef.current({});
            handleDialogClose();
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <Stack direction="column" alignItems="flex-start" spacing={2}>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Typography variant="label"><span>{t("Experiences")}</span></Typography>
                    {canModify && (
                        <>
                            <Button variant="outlined" startIcon={<MdAdd/>} size="small"
                                    onClick={handleCreate}>
                                {t("Add")}
                            </Button>

                            <Popup
                                title={t("Experiences")}
                                open={isEditingMode}
                                onDialogClose={handleDialogClose}
                            >
                                <AchievementForm
                                    achievement={editingData}
                                    skills={skills}
                                    onSaveFinish={handleSaveFinish}
                                />
                            </Popup>
                        </>
                    )}
                </Stack>
                <Stack direction="column" alignItems="start" spacing={2} sx={{width: '100%'}}>
                    {data.length === 0 && (
                        <Alert icon={false} severity="info" sx={{width: '100%'}}>
                            {t("No experience added")}
                        </Alert>
                    )}
                    {data?.map((achievement, index) => (
                        <AchievementRow
                            key={index}
                            achievement={achievement}
                            canModify={canModify}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};

const AchievementRow = ({achievement, canModify, onEdit, onDelete}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    };

    const handleEdit = () => {
        handleClose()
        onEdit && onEdit(achievement.id)
    };

    const handleDelete = () => {
        handleClose()
        onDelete && onDelete(achievement.id)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    return (
        <Grid container className="achievement-item" gap={1.5}>
            <Grid item xs="auto">
                <MdBusiness className="Mui-icon" size={30} style={{marginRight: "3px"}}/>
            </Grid>
            <Grid item xs container direction="column" gap={1}>
                <Stack direction="column">
                    <Typography sx={{fontWeight: "bold"}}>{achievement.enterprise}</Typography>
                    <Typography>{achievement.role}</Typography>
                </Stack>
                <Stack direction="column">
                    <Stack direction="row" spacing={0.5}>
                        <Typography>
                            {Utils.formatDate(achievement.startDate, 'DD MMMM YYYY')}
                            {achievement.endDate
                                ? ` - ${Utils.formatDate(achievement.endDate, 'DD MMMM YYYY')}`
                                : ` - ${t("Today")}`
                            }
                        </Typography>
                    </Stack>
                    <Typography>{achievement.location}</Typography>
                </Stack>
                {achievement.description && (
                    <Display
                        type="wysiwyg"
                        value={achievement.description}
                    />
                )}
                {achievement?.Skills.length > 0 && (
                    <Display
                        type="dropdown"
                        ismulti={true}
                        value={achievement.Skills}
                        size="small"
                    />
                )}
                {canModify &&
                    <div style={{position: "absolute", top: "5px", right: "5px"}}>
                        <Grid container justifyContent="flex-end">
                            <IconButton
                                aria-controls="item-actions-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                size="small"
                            >
                                <MdMoreHoriz size={20}/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleEdit}>{t("Edit")}</MenuItem>
                                <MenuItem onClick={handleDelete}>
                                    <Typography color="secondary">{t("Delete")}</Typography>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </div>
                }
            </Grid>
        </Grid>
    );
};

export {
    Achievement,
    AchievementRow
};
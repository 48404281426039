import React, {useRef, useState} from "react";
import {FieldHolder} from "../../../../FieldHolder";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import Utils from "../../../../../../utils/Utils";
import {createAchievement, updateAchievement} from "../../../../../../services/achievementService";
import {useSelector} from "react-redux";
import Functions from "../../../../../../utils/Functions";

const AchievementForm = ({achievement, skills, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(achievement);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = Utils.getRequiredFields('achievement');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value,
            ...(name === "ongoingStatus" && value ? { endDate: null } : {})
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!data.ongoingStatus) {
            requiredFields.push('endDate');
        }

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let response;
            if (data.id) {
                response = await updateAchievement(data.id, data, token);
            } else {
                response = await createAchievement(data, token);
            }
            onSaveFinish && onSaveFinish(!response.error);

            setLoading(false);
        })()
    };

    return <Box sx={{width: {md: "35vw"}}}>
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            style={{display: "flex", flexDirection: "column"}}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Enterprise")}
                        type="text"
                        name="enterprise"
                        id="enterprise"
                        value={data.enterprise}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.enterprise}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Occupied role")}
                        type="text"
                        name="role"
                        id="role"
                        value={data.role}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.role}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={t("Starting date")}
                                type="date"
                                name="startDate"
                                id="startDate"
                                value={data.startDate}
                                onChange={handleChange}
                                canModify={true}
                                mandatoryFlag={true}
                                error={formErrors.startDate}
                            />
                        </Grid>
                        {!(data.ongoingStatus) && (
                            <Grid item xs={6}>
                                <FieldHolder
                                    fielddesc={t("End date")}
                                    type="date"
                                    name="endDate"
                                    id="endDate"
                                    value={data.endDate}
                                    onChange={handleChange}
                                    mandatoryFlag={true}
                                    canModify={true}
                                    error={formErrors.endDate && !data.ongoingStatus}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("Ongoing assignment/job")}
                        type="checkbox"
                        name="ongoingStatus"
                        id="ongoingStatus"
                        value={data.ongoingStatus}
                        onChange={handleChange}
                        canModify={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Location")}
                        type="text"
                        name="location"
                        id="location"
                        value={data.location}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.location}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Assignment and deliverables")}
                        type="wysiwyg"
                        name="description"
                        id="description"
                        value={data.description}
                        onChange={handleChange}
                        canModify={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Mobilized skills")}
                        type="dropdown"
                        name="Skills"
                        id="Skills"
                        datas={skills[Functions.SKILL_TYPE.TECHNICAL]}
                        value={data?.Skills}
                        formData={data}
                        setFormData={setData}
                        ismulti={true}
                        canModify={true}
                        allowGroup={true}
                    />
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >{t("Save")}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>
}

export default AchievementForm
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {fetchDefaultJobKeywords, fetchFreelanceProfiles} from "../../services/freelanceProfileService";

import {UserCard} from "../../components/UI/UserCard";

import "../page.css";
import {Table} from "../../components/Tables/Table";

import skillService from "../../services/skillService";
import {Box, Button, Chip, IconButton, Link} from "@mui/material";
import {MdOutlineFavorite, MdOutlineFavoriteBorder} from "react-icons/md";
import Utils from "../../utils/Utils";
import Functions from "../../utils/Functions";
import {FreelanceAvailabilityDisplay} from "../../components/Forms/FreelanceProfile/FreelanceAvailabilityDisplay";
import {CustomTagCell} from "../../components/Tables/CustomTagCell";
import _ from "lodash";
import {addFreelanceToFavorite, removeFreelanceToFavorite} from "../../services/enterpriseProfileService";
import {useFreelanceProfileStore} from "../../store/freelanceProfileStore";
import TextTruncate from "react-text-truncate";
import TagService from "../../services/tagService";
import HoverCopyButton from "../../components/Button/HoverCopyButton";
import {EditableTag} from "../../components/Tables/EditableTag";
import CopyLinkButton from "../../components/Button/CopyLinkButton";
import {SelectedFreelanceFooter} from "./SelectedFreelanceFooter";

let pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const FreelanceBoard = (
    {
        sourcing = false,
        freelanceDisplayConfig = {},
        activeDisplay,
        setDisplayType,
        triggerApi,
        setTriggerApi,
        onListChange,
        onDisplayConfigChange
    }
) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const isAdminUser = useSelector((state) => state.auth.fullAccess);
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    pageSize = freelanceDisplayConfig.pageSize ?? pageSize;

    const {setFreelanceData, setFreelanceEditPermissions} = useFreelanceProfileStore();

    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: '',
        activatedFilter: false
    };
    const [loading, setLoading] = useState(!sourcing);
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
    const [skillsData, setSkillsData] = useState([]);
    const [tagsData, setTagsData] = useState([]);
    const [jobKeywords, setJobKeywords] = useState([]);
    const [senioritiesData, setSenioritiesData] = useState([]);
    const [freelanceRowSelectionModel, setFreelanceRowSelectionModel] = useState([]);

    const tableRef = useRef();
    const hasMounted = useRef(false);
    const abortControllerRef = useRef(null);

    useEffect(() => {
        Promise.all([
            skillService.getAllDefaultSkill(token),
            TagService.getFreelanceProfileTags({ type: Functions.TAGS_TYPE.FREELANCE_TAG }, token),
            fetchDefaultJobKeywords(token)
        ]).then(([skills, tags, keywords]) => {
            skills = skills.map((skill) => {
                return {...skill, group: t(skillService.getSkillTypeLabel(skill.type))};
            });
            setSkillsData(skills);
            setTagsData(tags?.data || []);
            setJobKeywords(keywords);
        });

        const jsonOptions = Utils.getParameterValues("professionalExperienceYears");
        setSenioritiesData(
            Object.keys(jsonOptions).map((key) => ({
                id: key, name: t(jsonOptions[key].nativetext)
            }))
        );
    }, [token, t]);

    useEffect(() => {
        if (hasMounted.current) {
            if (!sourcing) {
                tableRef.current.initTable({initFilter: false});
                setTriggerApi(true);
            }
        } else {
            hasMounted.current = true;
        }
    }, [activeDisplay?.key]);

    useEffect(() => {
        if (triggerApi) {
            loadFreelanceProfiles(filterConfigs, activeDisplay);
        }
    }, [filterConfigs, triggerApi]);

    const tagMap = useMemo(() => {
        return tagsData.reduce((acc, tag) => {
            acc[tag.id] = tag.name;
            return acc;
        }, {});
    }, [tagsData]);

    const columns = useMemo(() => {
        const _columns = [
            {
                field: 'User.fullName',
                type: 'string',
                hideable: false,
                headerName: t("Name"),
                width: 290,
                headerAlign: 'center',
                align: 'left',
                cellClassName: isEnterpriseSession ? 'px-10' : '',
                renderCell: ({row}) => {
                    const user = row?.User;
                    return (
                        <HoverCopyButton textToCopy={user?.fullName}>
                            <UserCard user={user} displaySkeleton={true}/>
                        </HoverCopyButton>
                    );
                }
            },
            {
                field: 'jobTitle',
                type: 'string',
                headerName: t("Job title"),
                width: 290,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return <Box sx={{textWrap: "wrap"}}>{row.jobTitle}</Box>
                }
            },
            {
                field: 'averageDailyRate',
                type: 'number',
                headerName: t("ADR"),
                width: 180,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return `${row.averageDailyRate ?? ''}`
                        + ` ${t(Utils.getParameterValue(row?.averageDailyRateCurrency, "Currency"))}`
                }
            },
            {
                field: 'CompanyFreelanceNotes.negotiatedRate',
                type: 'string',
                headerName: t("Negotiated rate"),
                width: 180,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return row?.CompanyFreelanceNotes.length
                        ? row.CompanyFreelanceNotes[0].negotiatedRate : "";
                }
            },
            {
                field: 'User.phoneNumber',
                type: 'string',
                headerName: t("Phone number"),
                width: 180,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const phoneNumber = row?.User.phoneNumber
                    return phoneNumber ? (
                        <HoverCopyButton textToCopy={phoneNumber}>
                            <Box sx={{textWrap: "wrap"}}>{phoneNumber}</Box>
                        </HoverCopyButton>
                    ) : '';
                }
            },
            {
                field: 'User.email',
                type: 'string',
                headerName: t("Email"),
                width: 300,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const email = row?.User.email;
                    return email ? (
                        <HoverCopyButton textToCopy={email}>
                            <div className="MuiDataGrid-cellContent" title={email} role="presentation">{email}</div>
                        </HoverCopyButton>
                    ) : '';
                }
            },
            {
                field: 'website',
                type: 'string',
                headerName: t("Website"),
                width: 250,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const formattedUrl = Utils.formatUrl(row.website);
                    return <Link
                        className="MuiDataGrid-cellContent"
                        href={formattedUrl}
                        underline="none"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(formattedUrl, '_blank', 'noopener,noreferrer');
                        }}
                    >
                        <TextTruncate
                            line={2}
                            truncateText="..."
                            text={row.website}
                        />
                    </Link>
                }
            },
            {
                field: 'linkedinProfile',
                type: 'string',
                headerName: t("LinkedIn profile"),
                width: 250,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const formattedUrl = Utils.formatUrl(row.linkedinProfile);
                    return <Link
                        className="MuiDataGrid-cellContent"
                        href={formattedUrl}
                        underline="none"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(formattedUrl, '_blank', 'noopener,noreferrer');
                        }}
                    >
                        <TextTruncate
                            line={2}
                            truncateText="..."
                            text={row.linkedinProfile}
                        />
                    </Link>
                }
            },
            {
                field: 'location',
                headerName: t("Localization"),
                width: 250,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return (
                        <Box sx={{textWrap: "wrap"}}>
                            {row?.Location ? `${row?.Location.name}, ${row?.Location?.Country.name}` : ""}
                        </Box>
                    );
                }
            },
            {
                field: 'professionalExperienceYears',
                type: 'string',
                headerName: t("Experience"),
                width: 200,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return (
                        <Box sx={{textWrap: "wrap"}}>
                            {t(row.professionalExperienceYears)}
                        </Box>
                    );
                }
            },
            {
                field: 'working_terms_preference',
                type: 'string',
                headerName: t("Preferences"),
                width: 200,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return (
                        <Box sx={{textWrap: "wrap"}}>
                            {t(row.working_terms_preference)}
                        </Box>
                    );
                }
            },
            {
                field: 'JobKeywords.id',
                headerName: t("Job keywords"),
                width: 300,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const tags = row?.FreelanceProfileJobKeywords.map(jk => jk.JobKeyword.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileSkills.Skill.technical',
                headerName: t("Skills"),
                width: 300,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const customOrder = {technical: 1, transversal: 2};
                    let skills = row?.FreelanceProfileSkills.map((item) => item.Skill);
                    skills = skills.filter(skill => skill.type === 'technical' || skill.type === 'transversal');
                    skills = _.orderBy(skills, skill => customOrder[skill.type]);
                    const tags = skills.map(tag => tag.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileSkills.Skill.language',
                headerName: t("Languages"),
                width: 300,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    let skills = row?.FreelanceProfileSkills
                        .filter(item => item.Skill.type === Functions.SKILL_TYPE.LANGUAGE)
                        .map(item => item.Skill);
                    const tags = skills.map(tag => tag.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileSkills.Skill.business_line',
                headerName: t("Business lines"),
                width: 300,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    let skills = row?.FreelanceProfileSkills
                        .filter(item => item.Skill.type === Functions.SKILL_TYPE.BUSINESS)
                        .map(item => item.Skill);
                    const tags = skills.map(tag => tag.name);
                    return <CustomTagCell value={tags}/>;
                }
            },
            {
                field: 'FreelanceProfileTags.freelance_tag',
                headerName: t("Tags"),
                width: 300,
                headerAlign: 'center',
                align: 'center',
                editable: true,
                valueGetter: (value, row) => {
                    if (typeof value !== "undefined") return value;
                    if (!row.FreelanceProfileTags?.freelance_tag) return [];
                    const tags = row.FreelanceProfileTags.freelance_tag.map(item => ({
                        ...item,
                        name: tagMap[item.id] || ''
                    }));
                    return _.orderBy(tags, 'name');
                },
                renderCell: (params) => {
                    const tags = params?.value.map(item => item?.name);
                    return <CustomTagCell value={tags}/>;
                },
                renderEditCell: (params) => {
                    return <EditableTag data={tagsData} onEditOption={handleTagEditOption} {...params}/>;
                }
            },
            {
                field: 'FreelanceProfileAvailabilities.date',
                type: 'dateRange',
                headerName: t("Availability"),
                width: 280,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return <FreelanceAvailabilityDisplay availabilityData={row}/>;
                }
            },
            {
                field: 'User.onboardingComplete',
                type: 'singleSelect',
                headerName: t("Account status"),
                width: 200,
                headerAlign: 'center',
                align: 'center',
                valueOptions: [
                    {value: true, label: t("Activated account")},
                    {value: false, label: t("Inactive account")},
                ],
                renderCell: ({row}) => {
                    const accountStatus = row?.User.onboardingComplete || false;
                    const accountStatusLabel = accountStatus ? t("Activated account") : t("Inactive account");
                    const accountStatusClass = accountStatus ? "account-status-active" : "account-status-inactive";
                    return <Chip label={accountStatusLabel} className={accountStatusClass} variant="outlined"/>
                }
            },
            {
                field: 'url',
                noSearchFilter: true,
                headerName: t("Url"),
                width: 220,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return (
                        <CopyLinkButton
                            link={`${window.location.origin}/profile/${row.id}`}
                            label="Copy link"
                            variant="outlined"
                            size="small"
                        />
                    );
                }
            }
        ];
        if (!sourcing && isEnterpriseSession) {
            _columns.splice(0, 0,
                {
                    field: 'action',
                    headerName: '',
                    width: 50,
                    cellClassName: 'p-0',
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => (
                        <FavoriteButton
                            row={row}
                            isFavorite={row?.EnterpriseFreelanceFavorites?.length > 0}
                            onToggleFavorite={handleToggleFavorite}
                            loading={loading}
                        />
                    )
                }
            );
            _columns.splice(18, 0,
                {
                    field: 'missionCount',
                    type: 'number',
                    noSearchFilter: true,
                    headerName: t("Number of assignments completed"),
                    width: 220,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        return parseInt(row.missionCount) > 0 ? row.missionCount : "-";
                    }
                },
                {
                    field: 'interactionCount',
                    type: 'number',
                    noSearchFilter: true,
                    headerName: t("Number of interactions"),
                    width: 220,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        return parseInt(row.interactionCount) > 0 ? row.interactionCount : "-";
                    }
                },
                {
                    field: 'noteCount',
                    type: 'number',
                    noSearchFilter: true,
                    headerName: t("Number of notes"),
                    width: 220,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        return parseInt(row.noteCount) > 0 ? row.noteCount : "-";
                    }
                },
                {
                    field: 'FreelancePerformances.nps',
                    type: 'number',
                    headerName: t("Recommendation"),
                    width: 200,
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: ({row}) => {
                        const nps = row?.FreelancePerformances[0]?.nps ?? null;
                        return nps !== null && typeof nps !== "undefined"
                            ? <Chip
                                label={+parseFloat(nps).toFixed(1)}
                                className={Utils.getRecommendationStatus(parseFloat(nps))}
                            />
                            : "-";
                    }
                }
            );

            if (isAdminUser) {
                _columns.splice(22, 0,
                    {
                        field: 'Cooptation.User.fullName',
                        headerName: t("Referred"),
                        width: 220,
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: ({row}) => {
                            const cooperatorUser = row?.Cooptation?.User;
                            return cooperatorUser
                                ? <Box sx={{textWrap: "wrap"}}>{t("Yes") + " - " + cooperatorUser?.fullName}</Box>
                                : t("No");
                        }
                    },
                    {
                        field: 'referredFreelanceCount',
                        type: 'number',
                        noSearchFilter: true,
                        headerName: t("Referred freelancers"),
                        width: 220,
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: ({row}) => {
                            return parseInt(row.referredFreelanceCount) > 0 ? row.referredFreelanceCount : "-";
                        }
                    },
                );
            }
        }
        return _columns;
    }, [sourcing, isEnterpriseSession, tagMap, loading]);

    const advancedSearchColumns = [
        {
            field: 'averageDailyRate',
            type: 'number',
            headerName: t("ADR")
        },
        {
            field: 'FreelanceProfileSkills.Skill.id',
            type: 'multipleSelect',
            headerName: t("Skills and business lines"),
            data: skillsData
        },
        {
            field: 'FreelanceProfileTags.Tag.id',
            type: 'multipleSelect',
            headerName: t("Tags"),
            data: tagsData
        },
        {
            field: 'FreelanceProfileAvailabilities.date',
            type: 'dateRange',
            headerName: t("Availability")
        },
        {
            field: 'FreelanceProfileJobKeywords.JobKeyword.id',
            type: 'multipleSelect',
            headerName: t("Job keywords"),
            data: jobKeywords
        },
        {
            field: 'professionalExperienceYears',
            type: 'singleSelect',
            headerName: t("Seniority"),
            data: senioritiesData
        },
        {
            field: 'Location',
            type: 'location',
            headerName: t("Localization")
        }
    ];

    const loadFreelanceProfiles = useCallback((query = {}, activeDisplay = {}) => {
        if (sourcing && !query.activatedFilter) return;

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        setLoading(true);
        try {
            query.sourcing = sourcing;
            if (activeDisplay?.favorite) {
                query.favorite = "me";
            } else {
                delete query.favorite;
            }

            abortControllerRef.current = new AbortController();
            const abortSignal = abortControllerRef.current.signal;

            fetchFreelanceProfiles(query, token, abortSignal).then(freelanceData => {
                if (!freelanceData?.error) {
                    const _data = freelanceData.data.map(freelanceItem => {
                        if (freelanceItem?.FreelanceProfileTags) {
                            freelanceItem.FreelanceProfileTags = freelanceItem.FreelanceProfileTags.map(item => item.Tag);
                            freelanceItem.FreelanceProfileTags = TagService.groupTagsByType(freelanceItem.FreelanceProfileTags);
                        }
                        return freelanceItem;
                    });
                    setRowCount(freelanceData.count);
                    setData(_data);
                    onListChange(freelanceData.count);
                }
            }).finally(() => {
                setLoading(false);
                setTriggerApi(false);
            });
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const handleToggleFavorite = async (row, isFavorite) => {
        const index = data.findIndex(freelance => freelance.id === row.id);
        if (index === -1) return;

        const updatedData = _.cloneDeep(data);
        const action = isFavorite ? removeFreelanceToFavorite : addFreelanceToFavorite;

        if (isFavorite && activeDisplay.key === 'MyFavoriteFreelancers') {
            updatedData.splice(index, 1);
            setData(updatedData);
            setRowCount(updatedData.length);
        }

        await action(row.id, token);
    };

    const handleDataGridChange = (filterConfigs) => {
        setTriggerApi(true);
        setFilterConfigs(filterConfigs);
    };

    const handleCellClick = ({id}) => {
        setFreelanceData({});
        setFreelanceEditPermissions([]);
        if (sourcing) {
            navigate(`/sourcing/freelanceprofile/${id}`);
        } else {
            navigate(`/talents/freelanceprofile/${id}`);
        }
    };

    const handleTagEditOption = (option, newLabel) => {
        const newTagsData = tagsData.map(item => item.id === option.value ? {...item, name: newLabel} : item);
        setTagsData(newTagsData);
    };

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        setFreelanceRowSelectionModel(newRowSelectionModel);
    };

    const handleCancelSelecting = () => {
        setFreelanceRowSelectionModel([]);
    };

    return (
        <>
            {isEnterpriseSession && (
                <>
                    <Table
                        ref={tableRef}
                        loading={loading}
                        disableColumnSelector={sourcing}
                        disableColumnPinning={sourcing}
                        disableColumnReorder={sourcing}
                        displayConfig={freelanceDisplayConfig}
                        columns={columns}
                        advancedSearchColumns={advancedSearchColumns}
                        data={data}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        onChange={handleDataGridChange}
                        onCellDoubleClick={handleCellClick}
                        onDisplayConfigChange={onDisplayConfigChange}
                        checkboxSelection={isEnterpriseSession}
                        rowSelectionModel={freelanceRowSelectionModel}
                        onRowSelectionModelChange={handleRowSelectionModelChange}
                    >
                        {!sourcing && (
                            <Button
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={() => navigate(`/talents/comparator`)}
                            >
                                {t("Freelancer/Assignment Matching")}
                            </Button>
                        )}
                    </Table>
                    <SelectedFreelanceFooter
                        selectedFreelances={freelanceRowSelectionModel}
                        onProposalAssignmentFinish={() => setDisplayType("AssignmentProposals")}
                        onCancelSelecting={handleCancelSelecting}
                    />
                </>
            )}
        </>
    );
};

const FavoriteButton = ({row, isFavorite, loading, onToggleFavorite}) => {
    const [favorite, toggleFavorite] = useState(isFavorite);

    const handleButtonClick = async (e) => {
        e.stopPropagation();
        toggleFavorite((v) => !v);
        await onToggleFavorite(row, isFavorite);
    };

    return (
        <IconButton
            color={favorite ? "secondary" : "default"}
            onClick={!loading ? handleButtonClick : null}
            aria-label="favorite"
            size="small"
        >
            {favorite ? <MdOutlineFavorite size={22}/> : <MdOutlineFavoriteBorder size={22}/>}
        </IconButton>
    );
};

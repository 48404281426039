import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Box, Button, Divider, Grid, Stack, Typography} from "@mui/material";
import './publicoffer.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchOffer, saveApplication} from "../../../services/recruitment";
import ResponsiveComponent from "../../../components/UI/ResponsiveComponent";
import {LanguageSelector} from "../../../components/UI/LanguageSelector";
import logosmall from "../../../assets/smarteem_logo_small.png";
import Functions from "../../../utils/Functions";
import ResponsiveStepper from "../../../components/UI/ResponsiveStepper";
import {updateUser} from "../../../services/userService";
import {UserForm} from "../../../components/Forms/Configuration/UserForm";
import {FreelanceProfileSteps} from "../../../components/Forms/Onboarding/FreelanceProfileSteps";
import {FaBuilding, FaFileSignature, FaLocationDot} from "react-icons/fa6";
import Utils from "../../../utils/Utils";
import {GoMoveToEnd, GoMoveToStart} from "react-icons/go";
import {logout} from "../../../utils/actions/authActions";
import {MessageComponent} from "../../../components/UI/MessageComponent";
import {fetchFreelanceAvailabilities} from "../../../services/freelanceAvailabilityService";
import {CustomCard} from "../../../components/UI/CustomCard";
import {FieldHolder} from "../../../components/Forms/FieldHolder";
import {fetchFreelanceReferrals} from "../../../services/referralService";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";

export const PublicOfferOnboarding = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const freelanceId = useSelector((state) => state.auth.freelanceId);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();

    const {freelanceData} = useFreelanceProfileStore();

    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [message, setMessage] = useState({});
    const [offer, setOffer] = useState({});

    const checkStepRef = useRef();
    const onboardingScrollContainerRef = useRef(null);
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    const criteria = offer?.RecruitmentCriteria || [];
    const questions = offer?.RecruitmentQuestions || [];
    const requiredCriteria = criteria.filter(c => c.isRequired);

    const steps = [
        {key: 'AccountConfigSection', name: 'Account configuration'},
        ...requiredCriteria.map(c => ({key: c.infoType, name: c.name, isRequired: c.isRequired})),
        ...(questions.length ? [{key: "OfferQuestionSection", name: 'Questions'}] : [])
    ];

    const activeStepKey = steps[activeStep]?.key;

    useEffect(() => {
        if (!isFreelanceSession) {
            navigate(`/offer/${id}`);
        }

        fetchOffer(id, token).then((fetchedOffer) => {
            if (!fetchedOffer?.error) {
                if (fetchedOffer.RecruitmentOfferSkills) {
                    fetchedOffer.RecruitmentOfferSkills = fetchedOffer.RecruitmentOfferSkills.map(os => os.Skill);
                }
                setOffer(fetchedOffer);
            }
        });
    }, []);

    useEffect(() => {
        setMessage({});
    }, [activeStepKey]);

    const getOfferDetailItem = (label, icon, iconLabel = "") => {
        return (
            <Grid container alignItems="center" spacing={1.5}>
                <Grid item title={t(iconLabel)}>
                    <Button variant="outlined" color="info" className="MuiButton-square"
                            size="small" sx={{pointerEvents: "none"}}>
                        {icon}
                    </Button>
                </Grid>
                <Grid item xs={6} md={9}>
                    <Typography variant="label" color="primary">{label}</Typography>
                </Grid>
            </Grid>
        );
    };

    const validateSection = async (section) => {
        const activeStepData = steps[section] || [];
        if (activeStepData?.isRequired) {
            if (activeStepData.key === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY) {
                const availabilitiesData = await fetchFreelanceAvailabilities(freelanceId, {}, token);
                if (!availabilitiesData?.count) {
                    setMessage({
                        type: "error",
                        message: "Please enter at least one availability to continue"
                    });
                    return;
                }
            } else if (activeStepData.key === Functions.FREELANCE_PROFILE_SECTION.REFERRAL) {
                const referralsData = await fetchFreelanceReferrals(freelanceId, {}, token);
                if (!referralsData?.count) {
                    setMessage({
                        type: "error",
                        message: "Please add at least one external reference to continue"
                    });
                    return;
                }
            }
        }

        return true;
    };

    const isLastStep = () => {
        return activeStep === steps.length - 1;
    };

    const handleBack = () => {
        if (activeStep === 0) {
            dispatch(logout());
            navigate(`/offer/${id}`);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);

            if (onboardingScrollContainerRef.current) {
                onboardingScrollContainerRef.current.scrollTop = 0;
            }
        }
    };

    const handleNext = () => {
        if (checkStepRef.current) {
            checkStepRef.current();
        }
    };

    const handleSubmit = async (response) => {
        if (response.success) {
            if (isLastStep()) {
                let result = await saveApplication({
                    RecruitmentOfferId: offer?.id,
                    JobApplicationAnswers: response?.data?.JobApplicationAnswers || []
                }, token);
                if (!result?.error) {
                    result = await updateUser({
                        userData: {
                            onboardingComplete: true,
                            accountCreationNotification: !freelanceData?.User?.onboardingComplete
                        },
                        token: token
                    });
                    if (!result?.error) {
                        navigate("/applications/applicationsboard");
                    }
                } else {
                    setMessage({type: "error", message: "Error saving data"});
                }
            } else {
                const validatedSection = await validateSection(activeStep);
                if (validatedSection) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);

                    if (onboardingScrollContainerRef.current) {
                        onboardingScrollContainerRef.current.scrollTop = 0;
                    }
                }
            }
        }
    };

    return (
        <>
            {isFreelanceSession && Boolean(offer?.id) && (
                <div className="onboarding-row-container layout">
                    <ResponsiveComponent mobile>
                        <Box sx={{width: "100%", padding: "10px"}}>
                            <img
                                src={logosmall}
                                alt="Smarteem Logo"
                                className="app-logo"
                                onClick={() => navigate("/")}
                            />
                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem/>}
                                justifyContent="center"
                                columnGap={2}
                                sx={{marginTop: "15px"}}
                            >
                                <LanguageSelector/>
                            </Stack>
                        </Box>
                    </ResponsiveComponent>

                    <ResponsiveComponent tablet desktop>
                        <Box className="onboarding-column-container onboarding-column-left offer-detail">
                            <Stack direction="column" gap={5} sx={{mt: {md: "8vh"}}}>
                                <h1 className="pagetitle">{offer.title}</h1>
                                <Stack direction="column" gap={2}>
                                    {getOfferDetailItem(offer.Company?.name, <FaBuilding size={20}/>, "Company")}
                                    {offer.location && getOfferDetailItem(
                                        offer.location,
                                        <FaLocationDot size={20}/>,
                                        "Location"
                                    )}
                                    {offer.contractType && getOfferDetailItem(
                                        t(Utils.getParameterValue(offer.contractType, "OfferContractType")),
                                        <FaFileSignature size={20}/>,
                                        "Contract"
                                    )}
                                    {offer.startDate && getOfferDetailItem(
                                        Utils.formatDate(offer.startDate),
                                        <GoMoveToStart size={20}/>,
                                        "Start"
                                    )}
                                    {offer.endDate && getOfferDetailItem(
                                        Utils.formatDate(offer.endDate),
                                        <GoMoveToEnd size={20}/>,
                                        "End"
                                    )}
                                </Stack>
                            </Stack>
                            <div className="onboarding-column-container" style={{overflow: "hidden"}}>
                                <LanguageSelector/>
                            </div>
                        </Box>
                    </ResponsiveComponent>

                    <div className="onboarding-column-container onboarding-column-right"
                         ref={onboardingScrollContainerRef}>
                        <Box className="w-100">
                            <ResponsiveStepper
                                steps={steps}
                                index={activeStep}
                                onStepClick={setActiveStep}
                            />
                        </Box>

                        <>
                            <Box className="form-layout" sx={{mt: "2vh"}}>
                                {activeStepKey === "AccountConfigSection" && (
                                    <UserForm
                                        ref={checkStepRef}
                                        setLoading={setLoading}
                                        onSubmit={handleSubmit}
                                        className="standardform-w70"
                                    />
                                )}
                                {activeStepKey === "OfferQuestionSection" && (
                                    <OfferQuestionSection
                                        ref={checkStepRef}
                                        offer={offer}
                                        setMessage={setMessage}
                                        onSubmit={handleSubmit}
                                    />
                                )}
                                {!["AccountConfigSection", "OfferQuestionSection"].includes(activeStepKey) && (
                                    <FreelanceProfileSteps
                                        ref={checkStepRef}
                                        activeStepKey={activeStepKey}
                                        className="w-100"
                                        setLoading={setLoading}
                                        setMessage={setMessage}
                                        onSubmit={handleSubmit}
                                    />
                                )}
                            </Box>

                            <MessageComponent type={message.type} message={message.message} width="90%"/>

                            <Stack direction="row" className="w-100">
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            md: activeStepKey === "AccountConfigSection" ? "70%" : "100%"
                                        },
                                        pt: {xs: "10px", md: "25px"}
                                    }}
                                >
                                    {isLastStep()
                                        ? (
                                            <Button
                                                disabled={loading}
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleNext}>
                                                {t("Send my application")}
                                            </Button>
                                        )
                                        : (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="light"
                                                    onClick={handleBack}
                                                    sx={{mr: 1}}
                                                >
                                                    {t("Previous")}
                                                </Button>
                                                <Box sx={{flex: '1 1 auto'}}/>
                                                <Button
                                                    disabled={loading}
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleNext}>
                                                    {activeStep === steps.length - 1 ? t("Validate") : t("Next")}
                                                </Button>
                                            </>
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </>
                    </div>
                </div>
            )}
        </>
    );
};

const OfferQuestionSection = forwardRef(({offer, setMessage, onSubmit}, ref) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const questions = offer?.RecruitmentQuestions || [];
    const requiredFields = questions.filter(q => q.isRequired).map(q => q.id);
    const defaultResponseData = questions.reduce((acc, q) => ({...acc, [q.id]: ""}), {});
    const [formErrors, setFormErrors] = useState({});

    const responseDataRef = useRef(defaultResponseData);
    const formRef = useRef(null);

    const handleChange = (e) => {
        const {name, value} = e.target;
        responseDataRef.current[name] = value;
    };

    const handleNext = async () => {
        const {valid, errors} = Utils.validateRequiredFields(responseDataRef.current, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        const filteredResponses = Object.fromEntries(
            Object.entries(responseDataRef.current).filter(([, value]) => value.trim() !== "")
        );

        onSubmit && onSubmit({
            success: true,
            data: {
                JobApplicationAnswers: filteredResponses
            }
        });
    };

    if (ref) {
        ref.current = handleNext;
    }

    return (
        <CustomCard editMode={true} canModify={false}>
            <Typography variant="label">{t("Questions")}</Typography>
            <form
                ref={formRef}
                className="w-100"
                style={{display: "flex", flexDirection: "column", gap: "21px"}}
                noValidate
            >
                {questions.map((question, index) => {
                    const number = index + 1;
                    return (
                        <Stack gap={1}>
                            <Stack direction="row" gap={0.5}>
                                <Typography>{`${t("Question")} ${number} :`}</Typography>
                                <Typography variant="label" color="primary">
                                    {question.title}
                                    {question.isRequired && (<span className="mandatory-text"> *</span>)}
                                </Typography>
                            </Stack>
                            <FieldHolder
                                type="wysiwyg"
                                name={question.id}
                                id={`response${index}`}
                                value={responseDataRef.current[question.id]}
                                onChange={handleChange}
                                canModify={true}
                                mandatoryFlag={question.isRequired}
                                error={formErrors[question.id]}
                            />
                        </Stack>
                    );
                })}
            </form>
        </CustomCard>
    );
});
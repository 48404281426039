import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {AppLayout} from "../../../layouts/AppLayout";

import "../../page.css";
import {Table} from "../../../components/Tables/Table";
import {DisplayBar} from "../../../components/UI/DisplayBar";
import Utils from "../../../utils/Utils";
import Functions from "../../../utils/Functions";
import {Box, Stack} from "@mui/material";
import {fetchFreelanceApplications} from "../../../services/recruitment";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const FreelanceApplicationBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const freelanceId = useSelector((state) => state.auth.freelanceId);

    const tableRef = useRef();
    const [displayType, setDisplayType] = useState("List");
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const displayItems = [
        {key: 'List', name: 'All applications'}
    ];
    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    const columns = useMemo(() => {
        return [
            {
                field: 'RecruitmentOffer.title',
                type: 'string',
                headerName: t("Offer title"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.RecruitmentOffer.title;
                }
            },
            {
                field: 'RecruitmentOffer.Company.name',
                type: 'string',
                headerName: t("Company"),
                minWidth: 200,
                flex: 2.0,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    return row.RecruitmentOffer.Company.name;
                }
            },
            {
                field: 'status',
                headerName: t("Status"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    return t("Application sent");
                }
            },
            {
                field: 'createdAt',
                type: 'date',
                headerName: t("Application date"),
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
                renderCell: ({row}) => {
                    return Utils.formatDate(row.createdAt);
                }
            },
        ];
    }, []);

    useEffect(() => {
        loadApplications(filterConfigs);
    }, [filterConfigs]);

    const loadApplications = useCallback((query = {}) => {
        try {
            if (isFreelanceSession) {
                fetchFreelanceApplications(freelanceId, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [freelanceId, isFreelanceSession, token]);

    const handleDisplayMenuClick = (newDisplay) => {
        tableRef.current.initTable();
        setDisplayType(newDisplay);
        setFilterConfigs(defaultFilterConfigs);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout className="content-full-height">
            <Stack>
                <div className="pageheader">
                    <h1 className="pagetitle">
                        {t("My applications")}
                        <p className="header-data-row-count">{rowCount}</p>
                    </h1>
                </div>
                <DisplayBar
                    displayItems={displayItems}
                    displayType={displayType}
                    onDisplayMenuClick={handleDisplayMenuClick}
                />
            </Stack>
            <Box className="table-layout">
                <Table
                    ref={tableRef}
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                />
            </Box>
        </AppLayout>
    );
};


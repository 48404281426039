import React, {useRef, useState} from "react";
import {FieldHolder} from "../../../../FieldHolder";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import Utils from "../../../../../../utils/Utils";
import {useSelector} from "react-redux";
import {createCertification, updateCertification} from "../../../../../../services/certificationService";
import Functions from "../../../../../../utils/Functions";
import {createFile} from "../../../../../../services/fileService";

const CertificationForm = ({certification, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [certificationData, setCertificationData] = useState(certification);
    const [certificationFile, setCertificationFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const objectAttachedType = "freelanceProfile";
    const requiredFields = Utils.getRequiredFields('certification');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCertificationData({...certificationData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(certificationData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let data = {...certificationData};

            if (certificationFile) {
                const fileData = {
                    typeFile: Functions.TEXT_DOCUMENT,
                    file: certificationFile.theFile,
                };
                const result = await createFile(fileData, "", token);
                data.file = !result.error ? result : null;
            }

            let response;
            if (data.id) {
                response = await updateCertification(data.id, data, token);
            } else {
                response = await createCertification(data, token);
            }
            onSaveFinish && onSaveFinish(!response.error);

            setLoading(false);
        })()
    };

    return <Box sx={{width: {md: "35vw"}}}>
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
              style={{display: "flex", flexDirection: "column"}}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Certification name")}
                        type="text"
                        name="name"
                        id="name"
                        value={certificationData.name}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Certification school or organization")}
                        type="text"
                        name="companyName"
                        id="companyName"
                        value={certificationData.companyName}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.companyName}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={t("Certification year")}
                                type="text"
                                name="certificationYear"
                                id="certificationYear"
                                value={certificationData.certificationYear}
                                onChange={handleChange}
                                canModify={true}
                                error={formErrors.certificationYear}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FieldHolder
                                fielddesc={t("Still ongoing")}
                                type="checkbox"
                                name="ongoingStatus"
                                id="ongoingStatus"
                                value={certificationData.ongoingStatus}
                                onChange={handleChange}
                                canModify={true}
                                error={formErrors.ongoingStatus}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FieldHolder
                        type="file"
                        name="file"
                        id="file"
                        value={certificationData.file}
                        objectAttachedType={objectAttachedType}
                        formData={certificationData}
                        setFormData={setCertificationData}
                        setFileInfo={setCertificationFile}
                        canModify={true}
                    />
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                    <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        color="secondary"
                    >{t("Save")}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>
}

export default CertificationForm
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

import './index.css';

import {SignupForm} from '../components/Forms/Login_Signup/SignupForm';
import {LoginForm} from '../components/Forms/Login_Signup/LoginForm';
import {PasswordResetForm} from '../components/Forms/Login_Signup/PasswordResetForm';

import logosmall from '../assets/smarteem_logo_small.png';


export const Index = () => {
    const {t} = useTranslation();

    const [loginVisibility, setLoginVisibility] = useState(true);
    const [resetPasswordVisibility, setResetPasswordVisibility] = useState(false);

    const toggleLoginVisibility = () => setLoginVisibility(prev => !prev);

    const toggleResetPasswordVisibility = () => setResetPasswordVisibility(prev => !prev);

    return (
        <>
            <div className="contentholder">
                <div className="contentholder">
                    <img
                        src={logosmall}
                        alt='Smarteem Logo'
                        height={100}
                        className='logosmall'
                    />
                    <h1>{t("Join Smarteem community")}</h1>
                </div>
                {loginVisibility ? null :
                    <SignupForm toggleLoginVisibility={toggleLoginVisibility}/>
                }
                {(!loginVisibility || resetPasswordVisibility) ? null :
                    <LoginForm
                        toggleLoginVisibility={toggleLoginVisibility}
                        toggleResetPasswordVisibility={toggleResetPasswordVisibility}
                    />
                }
                {!resetPasswordVisibility ? null :
                    <PasswordResetForm toggleResetPasswordVisibility={toggleResetPasswordVisibility}/>
                }

            </div>
        </>
    );
};

import React, {useEffect, useRef, useState} from "react";
import {CustomCard} from "../../../../UI/CustomCard";
import {FieldHolder} from "../../../FieldHolder";
import {Button, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Functions from "../../../../../utils/Functions";
import {useSelector} from "react-redux";
import skillService from "../../../../../services/skillService";

export const OfferDescriptionStep = ({offerData, setOfferData, titleRef, onChange, onSave, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const editMode = offerData.steps.includes(Functions.OFFER_STEP.DESCRIPTION);
    const [descriptionEditMode, setDescriptionEditMode] = useState(canModify && !editMode);
    const [requiredProfileEditMode, setRequiredProfileEditMode] = useState(canModify && !editMode);
    const [skillsData, setSkillsData] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    const descriptionRef = useRef(null);
    const requiredProfileRef = useRef(null);

    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    useEffect(() => {
        if (isEnterpriseSession) {
            skillService.getAllDefaultSkill(token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    const _skillsData = fetchedData.map((skill) => {
                        return {...skill, group: t(skillService.getSkillTypeLabel(skill.type))};
                    });
                    setSkillsData(_skillsData);
                }
            })

        }
    }, [token, t, isEnterpriseSession]);

    const handleTitleChange = (e) => {
        if (titleRef.current) {
            titleRef.current.textContent = e.target.value;
        }
    };

    const handleSave = async () => {
        if (descriptionRef.current) {
            const editor = descriptionRef.current.getEditor();
            editor.blur();
        }
        if (requiredProfileRef.current) {
            const editor = requiredProfileRef.current.getEditor();
            editor.blur();
        }
        const result = onSave && await onSave();
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            setDescriptionEditMode(false);
            setRequiredProfileEditMode(false);
        }
    };

    return (
        <>
            <CustomCard
                className="justify-content-center"
                editMode={descriptionEditMode}
                setEditMode={setDescriptionEditMode}
                canModify={canModify && editMode}
                onSave={handleSave}
            >
                <Grid container spacing={{xs: 2, md: 3}}>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Offer title")}
                            type="text"
                            name="title"
                            id="title"
                            defaultValue={offerData?.title}
                            onChange={handleTitleChange}
                            onBlur={onChange}
                            canModify={descriptionEditMode}
                            mandatoryFlag={true}
                            error={formErrors.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            ref={descriptionRef}
                            fielddesc={t("Offer description")}
                            type="wysiwyg"
                            name="description"
                            id="description"
                            value={offerData?.description}
                            onBlur={onChange}
                            canModify={descriptionEditMode}
                            mandatoryFlag={true}
                            error={formErrors.description}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={{xs: 2, md: 3}}>
                    {(descriptionEditMode || offerData?.location) && (
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("Location")}
                                type="text"
                                name="location"
                                id="location"
                                defaultValue={offerData?.location}
                                onBlur={onChange}
                                canModify={descriptionEditMode}
                            />
                        </Grid>
                    )}
                    {(descriptionEditMode || offerData?.contractType) && (
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("Contract")}
                                type="dropdown"
                                name="contractType"
                                id="contractType"
                                set="OfferContractType"
                                value={offerData?.contractType}
                                formData={offerData}
                                setFormData={setOfferData}
                                canModify={descriptionEditMode}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={{xs: 2, md: 3}}>
                    {(descriptionEditMode || offerData?.startDate) && (
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("Starting date")}
                                type="date"
                                name="startDate"
                                id="startDate"
                                value={offerData?.startDate}
                                onChange={onChange}
                                canModify={descriptionEditMode}
                            />
                        </Grid>
                    )}
                    {(descriptionEditMode || offerData?.endDate) && (
                        <Grid item xs={12} md={6}>
                            <FieldHolder
                                fielddesc={t("End date")}
                                type="date"
                                name="endDate"
                                id="endDate"
                                value={offerData?.endDate}
                                onChange={onChange}
                                canModify={descriptionEditMode}
                            />
                        </Grid>
                    )}
                </Grid>
            </CustomCard>

            <CustomCard
                className="justify-content-center"
                editMode={requiredProfileEditMode}
                setEditMode={setRequiredProfileEditMode}
                canModify={canModify && editMode}
                onSave={handleSave}
            >
                <Grid container spacing={{xs: 2, md: 3}}>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="label">{t("Profile required")}</Typography>
                            <FieldHolder
                                ref={requiredProfileRef}
                                type="wysiwyg"
                                name="requiredProfile"
                                id="requiredProfile"
                                value={offerData?.requiredProfile}
                                onBlur={onChange}
                                canModify={requiredProfileEditMode}
                                mandatoryFlag={true}
                                error={formErrors.requiredProfile}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Skills required")}
                            type="dropdown"
                            name="RecruitmentOfferSkills"
                            id="RecruitmentOfferSkills"
                            datas={skillsData}
                            value={offerData?.RecruitmentOfferSkills}
                            formData={offerData}
                            setFormData={setOfferData}
                            ismulti={true}
                            canModify={requiredProfileEditMode}
                            allowGroup={true}
                        />
                    </Grid>
                </Grid>
            </CustomCard>

            {!editMode && (
                <Stack alignItems="center" className="w-100">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSave}
                    >
                        {t("Save")}
                    </Button>
                </Stack>
            )}
        </>
    )
};
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import Utils from "../../../../../utils/Utils";
import {FieldHolder} from "../../../FieldHolder";
import {getInvoiceSendEmails, sendNotification} from "../../../../../services/invoiceService";

const InvoiceSendFileForm = ({invoiceIds, onSaveFinish, onClose}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const defaultData = {email: '', message: ''};
    const [data, setData] = useState(defaultData);
    const [invoiceSendEmails, setInvoiceSendEmails] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    useEffect(() => {
        getInvoiceSendEmails({}, token).then((invoiceSendEmailsData) => {
            if (invoiceSendEmailsData?.data) {
                const _invoiceSendEmails = invoiceSendEmailsData?.data.map(item => ({
                    id: item.email,
                    name: item.email
                }));
                setInvoiceSendEmails(_invoiceSendEmails);
            }
        });
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        const {valid, errors} = Utils.validateRequiredFields(data, ['email']);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            try {
                setLoading(true);

                const response = await sendNotification({...data, invoiceIds: invoiceIds}, token);
                setLoading(false);

                setData(defaultData);
                onClose && onClose();

                onSaveFinish && onSaveFinish(!response.error, data);
            } catch (error) {
                console.error(error);
            }

        })()
    };

    return (
        <Box sx={{width: {md: "20vw"}}}>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                noValidate
                style={{display: "flex", flexDirection: "column"}}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Email")}
                            type="dropdown"
                            name="email"
                            id="email"
                            isMulti={false}
                            value={data.email}
                            datas={invoiceSendEmails}
                            formData={data}
                            setFormData={setData}
                            canModify={true}
                            mandatoryFlag={true}
                            error={formErrors.email}
                            allowAdd={true}
                            disableClearable={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Message")}
                            type="multiline"
                            name="message"
                            id="message"
                            value={data.message}
                            onChange={handleChange}
                            canModify={true}
                        />
                    </Grid>
                    <Grid item container justifyContent="space-between"
                          xs={12}>
                        <Button
                            type="button"
                            variant="contained"
                            color="light"
                            onClick={onClose}
                        >
                            {t("Cancel")}
                        </Button>

                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >
                            {t("Send")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default InvoiceSendFileForm
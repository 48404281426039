import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Table} from "../../../components/Tables/Table";
import {fetchCandidates} from "../../../services/recruitment";
import {useNavigate} from "react-router-dom";
import Functions from "../../../utils/Functions";
import {UserCard} from "../../../components/UI/UserCard";
import Utils from "../../../utils/Utils";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const CandidateTable = ({offerId, onListChange}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const {setFreelanceData, setFreelanceEditPermissions} = useFreelanceProfileStore();

    const tableRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
    // const [jobApplicationStatuses, setJobApplicationStatuses] = useState([]);

    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const columns = [
        {
            field: 'FreelanceProfile.User.fullName',
            type: 'string',
            headerName: t("Name"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const user = row.FreelanceProfile?.User;
                return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
            }
        },
        {
            field: 'RecruitmentOffer.title',
            type: 'string',
            headerName: t("Offer"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.RecruitmentOffer.title;
            }
        },
        {
            field: 'createdAt',
            type: 'date',
            headerName: t("Application date"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
            renderCell: ({row}) => {
                return Utils.formatDate(row.createdAt);
            }
        },
        {
            field: 'RecruitmentPipeline.title',
            headerName: t("Status"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return t(row?.RecruitmentPipeline?.title);
            }
        },
        {
            field: 'addedToPool',
            headerName: t("Added to pool of qualified freelancers"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return parseInt(row.addedToPool) > 0 ? t("Yes") : t("No");
            }
        },
        {
            field: 'interactionCount',
            type: 'number',
            noSearchFilter: true,
            headerName: t("Number of interactions"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return parseInt(row.interactionCount) > 0 ? row.interactionCount : "-";
            }
        },
        {
            field: 'noteCount',
            type: 'number',
            noSearchFilter: true,
            headerName: t("Number of notes"),
            minWidth: 150,
            flex: 1.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return parseInt(row.noteCount) > 0 ? row.noteCount : "-";
            }
        },
    ];

    useEffect(() => {
        loadCandidates(filterConfigs);
    }, [filterConfigs]);

    const loadCandidates = useCallback((query = {}) => {
        try {
            if (isEnterpriseSession) {
                fetchCandidates(offerId || "", query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                        onListChange && onListChange(fetchedData.count);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, offerId, isEnterpriseSession]);

    const handleCellClick = ({id}) => {
        setFreelanceData({});
        setFreelanceEditPermissions([]);
        navigate(`/recruitment/candidates/detail/${id}`);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <Table
            ref={tableRef}
            columns={columns}
            data={data}
            rowCount={rowCount}
            pageSize={pageSize}
            filterConfigs={filterConfigs}
            onChange={handleDataGridChange}
            onCellDoubleClick={handleCellClick}
        />
    );
};
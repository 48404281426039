import React, {useCallback} from 'react';
import {Step, StepButton, Stepper} from "@mui/material";
import {useTranslation} from "react-i18next";

const ResponsiveStepper = (
    {
        steps,
        nonLinear = false,
        completedSteps = [],
        index,
        stepKey,
        onStepClick
    }
) => {
    const {t} = useTranslation();

    const getStepIndex = (key) => {
        return steps.findIndex(item => item.key === key);
    };

    let stepIndex = stepKey ? getStepIndex(stepKey) : index;

    const handleStepClick = useCallback(
        (index) => {
            if (nonLinear || (!nonLinear && index < stepIndex)) {
                onStepClick(index);
            }
        },
        [nonLinear, stepIndex, onStepClick]
    );

    return <>
        <Stepper
            alternativeLabel
            activeStep={stepIndex}
            nonLinear={nonLinear}
            sx={{margin: "15px 0", width: "100%"}}
        >
            {steps.map((step, idx) => {
                const isCompleted = nonLinear
                    ? step.key !== stepKey && completedSteps.includes(step.key)
                    : idx < stepIndex;

                return (
                    <Step key={step.key} completed={isCompleted}>
                        <StepButton color="inherit" onClick={() => handleStepClick(idx)}>
                            {t(step.name)}
                        </StepButton>
                    </Step>
                );
            })}
        </Stepper>
    </>
};

export default ResponsiveStepper;
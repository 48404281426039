import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {Box, Button} from "@mui/material";

import {useParams} from "react-router-dom";
import Functions from "../../../../../utils/Functions";
import {deleteMission, fetchFreelanceMissions} from "../../../../../services/missionService";
import {Table, TableActionMenu} from "../../../../Tables/Table";
import Popup from "../../../../Popup/Popup";
import {CustomTagCell} from "../../../../Tables/CustomTagCell";
import TagService from "../../../../../services/tagService";
import {OtherAssignmentDetailSection} from "./OtherAssignmentDetailSection";
import {OtherAssignmentForm} from "./OtherAssignmentForm";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const OtherAssignmentSection = forwardRef(({formData, canModify}, ref) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const params = useParams();
    const freelanceId = formData?.id ?? params.id; // FreelanceProfileId

    const defaultValue = {
        name: '',
        customOperationalManager: '',
        customDate: '',
        address: '',
        description: '',
        type: 'other'
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const [selectedAssignment, setSelectedAssignment] = useState({});

    const tableRef = useRef();
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const columns = [
        {
            field: 'Mission.name',
            headerName: t("Name"),
            type: 'string',
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => row.name
        },
        {
            field: 'customOperationalManager',
            type: 'string',
            headerName: t("Assignment Manager"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left'
        },
        {
            field: 'customDate',
            type: 'string',
            headerName: t("Assignment dates"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'MissionTags.mission_final_customer',
            headerName: t("Final customer"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                const finalCustomerTags = row?.MissionTags?.mission_final_customer ?? [];
                let tags = finalCustomerTags.map(item => item?.name);
                return <CustomTagCell value={tags}/>;
            }
        },
        {
            field: 'action',
            headerName: '',
            minWidth: 80,
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <TableActionMenu
                        row={row}
                        onDelete={handleDelete}
                    />
                );
            }
        }
    ];

    const loadAssignments = useCallback((query = {}) => {
        try {
            if (isEnterpriseSession) {
                query.type = defaultValue.type;
                fetchFreelanceMissions(freelanceId, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        const _data = fetchedData.data.map(assignmentItem => {
                            if (assignmentItem.MissionTags) {
                                assignmentItem.MissionTags = assignmentItem.MissionTags.map(mt => mt.Tag);
                                assignmentItem.MissionTags = TagService.groupTagsByType(assignmentItem.MissionTags);
                            }
                            return assignmentItem;
                        });
                        setRowCount(fetchedData.count);
                        setData(_data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isEnterpriseSession, freelanceId]);

    useEffect(() => {
        loadAssignments(filterConfigs);
    }, [filterConfigs]);

    const handleDelete = async (id) => {
        await deleteMission(id, token);
        loadAssignments(filterConfigs);
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleDialogClose = () => {
        setEditingMode(false);
    };

    const handleSaveFinish = (success) => {
        if (success) {
            loadAssignments(filterConfigs);
            handleDialogClose();
        }
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleCellClick = (row) => {
        setSelectedAssignment(row);
    };

    const handleInitList = () => {
        setSelectedAssignment({});
        tableRef.current && tableRef.current.initTable();
        setFilterConfigs(defaultFilterConfigs);
    };

    if (ref) {
        ref.current = handleInitList;
    }

    return (
        <>
            {isEnterpriseSession && (
                <>
                    {Object.keys(selectedAssignment).length === 0 && (
                        <div className='card transparent card-padding-wide'>
                            {canModify && (
                                <Box style={{width: '100%', marginBottom: "15px", textAlign: "right"}}>
                                    <Button color="secondary" variant="contained"
                                            onClick={handleCreate}>
                                        {t("Add an assignment from outside Smarteem")}
                                    </Button>
                                    <Popup
                                        title={t("Add an assignment from outside Smarteem")}
                                        open={isEditingMode}
                                        onDialogClose={handleDialogClose}
                                    >
                                        <Box sx={{width: {md: "30vw"}}}>
                                            <OtherAssignmentForm
                                                assignment={editingData}
                                                canModify={canModify}
                                                onSaveFinish={handleSaveFinish}
                                            />
                                        </Box>
                                    </Popup>
                                </Box>
                            )}
                            <Box className="table-layout">
                                <Table
                                    fullHeight={true}
                                    ref={tableRef}
                                    columns={columns}
                                    data={data}
                                    rowCount={rowCount}
                                    pageSize={pageSize}
                                    filterConfigs={filterConfigs}
                                    onChange={handleDataGridChange}
                                    onCellClick={handleCellClick}
                                />
                            </Box>
                        </div>
                    )}
                    {Object.keys(selectedAssignment).length > 0 && (
                        <OtherAssignmentDetailSection
                            assignment={selectedAssignment}
                            canModify={canModify}
                        />
                    )}
                </>
            )}
        </>
    );
});


import SectionMenu from "../../UI/SectionMenu";
import React, {useState} from "react";
import {Grid} from "@mui/material";
import {UserCard} from "../../UI/UserCard";
import {ProfileSection} from "../FreelanceProfile/Sections/ProfileSection/ProfileSection";
import {InformationAndDocumentSection} from "../FreelanceProfile/Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import {AvailabilitySection} from "../FreelanceProfile/Sections/AvailabilitySection";
import {ReferralSection} from "../FreelanceProfile/Sections/ReferralSection";
import {MissionsSection} from "../FreelanceProfile/Sections/MissionsSection";
import {useTranslation} from "react-i18next";
import skillService from "../../../services/skillService";
import Functions from "../../../utils/Functions";

const FreelanceComparatorList = ({freelances}) => {
    const {t} = useTranslation();
    const [activeComponent, setActiveComponent] = useState(Functions.FREELANCE_PROFILE_SECTION.PROFILE);

    const sectionMenuItems = [
        {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
        {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
        {key: Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT, name: 'Assignments'},
        {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
        {key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT, name: 'Informations and documents'},
    ];

    const missionColumns = [
        {
            field: 'name',
            headerName: t("Name"),
            headerClassName: 'MuiDataGrid-columnHeader--rounded',
            type: 'string',
            minWidth: 400,
            flex: 1,
            headerAlign: 'center',
            align: 'left',
        }
    ];

    const handleSectionClick = (componentName) => {
        setActiveComponent(componentName);
    };

    return <>
        <div className="form-layout">
            <div className="workflowmenu">
                <SectionMenu
                    color="info"
                    sectionMenuItems={sectionMenuItems}
                    activeSection={activeComponent}
                    onSectionMenuClick={handleSectionClick}
                />
            </div>
            <div style={{width: "100%", overflow: "auto"}}>
                <Grid
                    container
                    alignItems="start"
                    columnSpacing="20px"
                    id="freelanceProfileForm"
                    sx={{width: {xs: "280%", md: "auto"}}}
                >
                    {freelances.map((freelance) => {
                        const formData = {...freelance};
                        if (formData?.Skills) {
                            formData.Skills = skillService.groupSkillsByType(formData.Skills)
                        }
                        return (
                            <Grid
                                item
                                container
                                key={formData.id}
                                direction="column"
                                xs={Math.round(12 / freelances.length)}
                                sx={{gap: "20px"}}
                            >
                                <div className="card standardform-card"
                                     style={{alignItems: "start", padding: "10px", fontWeight: "bold"}}
                                >
                                    <UserCard user={formData.User} displaySkeleton={true}/>
                                </div>
                                {activeComponent === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                                    <ProfileSection
                                        formData={formData}
                                        editPermissions={[]}
                                        canModify={false}
                                        showProfilePicture={false}
                                        fullWidth={freelances.length >= 3}
                                    />
                                )}
                                {activeComponent === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                                    <AvailabilitySection
                                        formData={formData}
                                        canModify={false}
                                        fullWidth={true}
                                    />
                                )}
                                {activeComponent === Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT && (
                                    <MissionsSection
                                        formData={formData}
                                        canModify={false}
                                        hideTableFilter={true}
                                        columns={missionColumns}
                                    />
                                )}
                                {activeComponent === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                                    <ReferralSection
                                        formData={formData}
                                        canModify={false}
                                        fullWidth={freelances.length >= 3}
                                    />
                                )}
                                {activeComponent === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                                    <InformationAndDocumentSection
                                        formData={formData}
                                        canModify={false}
                                        fullWidth={freelances.length >= 3}
                                    />
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </div>
    </>
};

export default FreelanceComparatorList
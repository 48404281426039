import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchApplication} from "../../../services/recruitment";
import {FreelanceProfile} from "../../Freelance/FreelanceProfile";

export const CandidateDetail = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {id} = useParams();

    const [application, setApplication] = useState({});

    useEffect(() => {
        try {
            fetchApplication(id, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    setApplication(fetchedData);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [id, token]);

    return (
        <>
            {application?.id && (
                <FreelanceProfile
                    freelanceProfileId={application?.FreelanceProfile?.id}
                    application={application}
                />
            )}
        </>
    );
};
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import {FieldHolder} from "../../../components/Forms/FieldHolder";
import Utils from "../../../utils/Utils";
import {useToast} from "../../../context/ToastProvider";
import {saveAffiliation} from "../../../services/collaborationService";

export const AffiliationForm = ({affiliation, onSaveFinish}) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const token = useSelector((state) => state.auth.token);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(affiliation);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = ['companyName', 'firstName', 'lastName', 'email'];

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            const response = await saveAffiliation(data, token);
            if (response.message) {
                showToast(
                    t(response.message),
                    response.error ? 'error' : (response.success ? 'success' : 'warning'),
                    10000
                );
            }
             onSaveFinish && onSaveFinish(response.success);

            setLoading(false);
        })()
    };

    return (
        <Box sx={{width: {md: "40vw"}}}>
            <form ref={formRef} onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Company")}
                            type="text"
                            name="companyName"
                            id="companyName"
                            value={data.companyName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.companyName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Contact first name")}
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={data.firstName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Contact last name")}
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={data.lastName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Contact email")}
                            type="text"
                            name="email"
                            id="email"
                            value={data.email}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.email}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Contact phone number")}
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={data.phoneNumber}
                            onChange={handleChange}
                            error={formErrors.phoneNumber}
                        />
                    </Grid>
                    {/*<Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Language")}
                            type="dropdown"
                            set="Language"
                            name="language"
                            id="language"
                            datas={[]}
                            formData={data}
                            setFormData={setData}
                            onChange={handleChange}
                            error={formErrors.language}
                        />
                    </Grid>*/}
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Comment")}
                            type="multiline"
                            name="comment"
                            id="comment"
                            value={data.comment}
                            onChange={handleChange}
                            error={formErrors.comment}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >{t("Save")}</Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
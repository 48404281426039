import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import parameters from "../../utils/parameters.json";
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    TextField
} from "@mui/material";
import Utils from "../../utils/Utils";
import {MdMoreHoriz} from "react-icons/md";
import Popup from "../Popup/Popup";
import {FieldHolder} from "../Forms/FieldHolder";

export const InputDropdown = (
    {
        isDisabled,
        datas = [],
        set,
        value,
        ismulti = false,
        formData,
        setFormData,
        placeholder,
        displayPlaceholder,
        className = "",
        allowAdd,
        allowManageOption,
        allowGroup,
        onInputChange,
        onDropdownChange,
        onEditOption,
        onDeleteOption,
        ...props
    }
) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentOption, setCurrentOption] = useState(null);
    const [showEditOptionDialog, setShowEditOptionDialog] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                if (datas.length) {
                    const optionsList = datas.map(({User, ...item}) => ({
                        value: item.id,
                        label: `${item.name}`,
                        group: item.group ?? '',
                        User
                    }));
                    setOptions(optionsList);
                } else {
                    // Utilisation des options définies dans le JSON
                    const jsonOptions = parameters[set];
                    if (jsonOptions) {
                        setOptions(
                            Object.keys(jsonOptions).map((key) => ({
                                value: key,
                                label: t(jsonOptions[key].nativetext),
                            }))
                        );
                    } else {
                        setOptions([]);
                    }
                }
            } catch (error) {
                console.error("Error fetching options:", error);
            }
        })();
    }, [token, datas]);

    useEffect(() => {
        const getSelectedOptionData = () => {
            if (formData && typeof formData[props.name] !== "undefined") {
                return Utils.getNestedValue(formData, props.name);
            }
            return value;
        };

        const createOption = (opt, options) => {
            const id = opt.value ?? opt.id ?? opt;
            const option = options.find((o) => o.value === id);

            if (option) {
                return {
                    ...opt,
                    value: id,
                    label: option ? t(option.label) : opt.inputValue,
                    inputValue: false,
                };
            }
            return null;
        };

        const processOptionsArray = (selectedOptionData, options) => {
            return selectedOptionData.map((opt) => createOption(opt, options)).filter((item) => item !== null);
        };

        const processSingleOption = (selectedOptionData, options) => {
            const id = typeof selectedOptionData === "object" ? (selectedOptionData?.id || "")
                : (typeof selectedOptionData === "boolean" ? (selectedOptionData ? "1" : "0") : selectedOptionData);

            const option = options.find((o) => o.value === id);
            return id ? [{
                value: id,
                label: option ? t(option.label) : id,
            }] : [];
        };

        const selectedOptionData = getSelectedOptionData();
        const optionsList = Array.isArray(selectedOptionData)
            ? processOptionsArray(selectedOptionData, options)
            : processSingleOption(selectedOptionData, options);

        setSelectedOptions(optionsList);
    }, [value, options]);

    const handleMenuOpen = (event, option) => {
        event.stopPropagation(); // Prevent the autocomplete from closing
        event.preventDefault(); // Prevent default behavior
        setAnchorEl(event.currentTarget);
        setCurrentOption(option);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentOption(null);
    };

    const handleAddOption = (newValue) => {
        const optionExists = options.some((option) => option.value === newValue.value);
        if (!optionExists) {
            delete newValue.noAction;
            setOptions([...options, newValue]);
        }
    };

    const handleShowEditOptionDialog = () => {
        setShowEditOptionDialog(true);
    };

    const handleEditOptionDialogClose = () => {
        setShowEditOptionDialog(false);
        handleMenuClose();
    };

    const handleEditOption = (newLabel) => {
        const updateOptionLabel = (option) =>
            option.value === currentOption.value ? {...option, label: newLabel, name: newLabel} : option;

        const updatedOptions = options.map(updateOptionLabel);
        setOptions(updatedOptions);

        if (typeof formData !== "undefined") {
            const updatedSelectedOptions = selectedOptions.map(updateOptionLabel);
            const newFormData = Utils.updateNestedField(formData, props.name, updatedSelectedOptions);
            setFormData && setFormData(newFormData);
        }

        onEditOption && onEditOption(currentOption, newLabel);

        handleEditOptionDialogClose();
    };

    const handleDeleteOption = () => {
        const updatedSelectedOptions = selectedOptions.filter(option => option.value !== currentOption.value);
        if (typeof formData !== "undefined") {
            const newFormData = Utils.updateNestedField(formData, props.name, updatedSelectedOptions);
            setFormData && setFormData(newFormData);
        }
        setSelectedOptions(updatedSelectedOptions);

        const updatedOptions = options.filter(option => option.value !== currentOption.value);
        setOptions(updatedOptions);

        onDeleteOption && onDeleteOption(currentOption);

        handleMenuClose();
    };

    const handleChange = async (e, newValue) => {
        try {
            let selectedValue;
            let selected = newValue;

            if (typeof newValue === 'string') {
                selected = {label: newValue, value: newValue};
            }

            if (!ismulti) {
                selectedValue = selected ? selected.value : null;
                if (newValue?.inputValue) {
                    handleAddOption({
                        ...newValue,
                        label: newValue.inputValue ? newValue.inputValue : newValue.label
                    });
                }
            } else if (ismulti) {
                selectedValue = selected.map((option) => {
                    option = {
                        ...option,
                        id: option.value,
                        label: option.inputValue ? option.inputValue : option.label
                    };

                    if (option.inputValue) {
                        handleAddOption(option);
                    }
                    return option;
                });
            }

            if (typeof formData !== "undefined") {
                const newFormData = Utils.updateNestedField(formData, props.name, selectedValue);
                setFormData && setFormData(newFormData);
            }

            onDropdownChange && onDropdownChange(props.name, selectedValue);
        } catch (error) {
        }
    };

    // Add of a specific placeholder when the input is disabled
    let dropdownPlaceholder;
    if (!isDisabled) {
        dropdownPlaceholder = placeholder;
    } else {
        dropdownPlaceholder = displayPlaceholder;
    }

    // Vérifier si la valeur sélectionnée existe bien parmi la liste des options
    const selectedOption = !ismulti
        ? (selectedOptions && selectedOptions.length && options.find(option => option.value === selectedOptions[0].value) ? selectedOptions[0] : null)
        : selectedOptions;

    const autocompleteProps = {};

    if (allowAdd) {
        const filter = createFilterOptions();
        autocompleteProps.filterOptions = (options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            const isExisting = filtered.some(
                (option) => inputValue.toLocaleLowerCase() === option.label.toLocaleLowerCase()
            );
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    inputValue: inputValue,
                    value: inputValue,
                    label: `${t("Add")} "${inputValue}"`,
                    noAction: true
                });
            }

            return filtered;
        };
    }

    if (allowGroup) {
        autocompleteProps.groupBy = (option) => option.group;
    }

    if (props.renderOption) {
        autocompleteProps.renderOption = props.renderOption;
    } else if (allowManageOption) {
        autocompleteProps.renderOption = (props, option) => (
            <Box component="li"
                 key={option.id}
                 sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                     width: '100%'
                 }}
                 {...props}
            >
                <Box className="w-100">{option.label}</Box>
                {typeof option.noAction === "undefined" && (
                    <IconButton
                        onClick={(event) => handleMenuOpen(event, option)}
                        size="small"
                    >
                        <MdMoreHoriz size={18}/>
                    </IconButton>
                )}
            </Box>
        );
    }

    return (
        <>
            <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={(event, reason) => {
                    if (!anchorEl) {
                        setOpen(false);
                    }
                }}
                fullWidth
                disableClearable={props.disableClearable ?? true}
                disabled={isDisabled}
                id={props.id}
                name={props.name}
                multiple={ismulti}
                options={options}
                isOptionEqualToValue={
                    (opt, value) => opt.value === value.value || value.inputValue
                }
                onChange={handleChange}
                onInputChange={(event, newInputValue) => {
                    onInputChange && onInputChange(newInputValue);
                }}
                value={selectedOption}
                getOptionLabel={(opt) => opt.label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={dropdownPlaceholder}
                        label={props.label}
                        required={props.required}
                        error={props.error}
                    />
                )}
                noOptionsText={allowAdd ? t("Enter a value") : t("No options available")}
                {...autocompleteProps}
            />
            {allowManageOption && (
                <>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    >
                        <MenuItem onClick={handleShowEditOptionDialog}>{t("Edit option")}</MenuItem>
                        <MenuItem onClick={handleDeleteOption}>{t("Delete option")}</MenuItem>
                    </Menu>
                    {currentOption && (
                        <EditOptionPopup
                            title={t("Edit option")}
                            open={showEditOptionDialog}
                            option={currentOption}
                            onConfirm={handleEditOption}
                            onDialogClose={handleEditOptionDialogClose}
                        />
                    )}
                </>
            )}
        </>
    )
};

export const EditOptionPopup = ({open, title, option, onConfirm, onDialogClose}) => {
    const {t} = useTranslation();
    const [optionData, setOptionData] = useState({label: ''});
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    useEffect(() => {
        setOptionData(option);
    }, [option]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setOptionData({...optionData, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(optionData, ['label']);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        onConfirm && onConfirm(optionData.label);
    };

    return (
        <Popup
            open={open}
            title={title}
            onDialogClose={onDialogClose}
        >
            <Box sx={{width: {md: "30vw"}}}>
                <form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    noValidate
                    style={{display: "flex", flexDirection: "column"}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Label")}
                                type="text"
                                name="label"
                                id="label"
                                value={optionData.label}
                                onChange={handleChange}
                                canModify={true}
                                mandatoryFlag={true}
                                error={formErrors.label}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >{t("Save")}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Popup>
    );
};
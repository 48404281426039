import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {AppLayout} from "../../../layouts/AppLayout";
import Functions from "../../../utils/Functions";
import {fetchOffers} from "../../../services/recruitment";
import {Table} from "../../../components/Tables/Table";
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Utils from "../../../utils/Utils";
import {MdCircle} from "react-icons/md";
import CopyLinkButton from "../../../components/Button/CopyLinkButton";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const OfferBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const tableRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const columns = [
        {
            field: 'RecruitmentOffer.title',
            type: 'string',
            headerName: t("Offer title"),
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.title;
            }
        },
        {
            field: 'OfferManager.User.fullName',
            type: 'string',
            headerName: t("Offer Manager"),
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.OfferManager?.User?.fullName;
            }
        },
        {
            field: 'status',
            type: 'singleSelect',
            headerName: t("Status"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left',
            valueOptions: Object.entries(Functions.STATUS_OFFER).map(([value, label]) => ({
                value: value,
                label: t(label)
            })),
            renderCell: ({row}) => {
                let statusClass = row.status.toLowerCase();
                statusClass = statusClass.replace(/ /g, '-');
                return (
                    <Typography variant="iconText">
                        <MdCircle className={`status-${statusClass}`} size={14} style={{marginRight: "6px"}}/>
                        {t(Functions.STATUS_OFFER[row.status])}
                    </Typography>
                );
            }
        },
        {
            field: 'applicationCount',
            type: 'number',
            noSearchFilter: true,
            headerName: t("Total candidates"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <Typography>
                        {parseInt(row.applicationCount) > 0 ? row.applicationCount : "-"}
                    </Typography>
                );
            }
        },
        {
            field: 'newApplicationCount',
            type: 'number',
            noSearchFilter: true,
            headerName: t("New candidates"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <Typography color="secondary">
                        {parseInt(row.newApplicationCount) > 0 ? row.newApplicationCount : "-"}
                    </Typography>
                );
            }
        },
        {
            field: 'lastApplicationDate',
            type: 'date',
            headerName: t("Last application date"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.lastApplicationDate && new Date(row.lastApplicationDate),
            renderCell: ({row}) => {
                return row.lastApplicationDate ? Utils.formatDate(row.lastApplicationDate) : "-";
            }
        },
        {
            field: 'action',
            headerName: t("Link to the offer"),
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <CopyLinkButton
                        link={`${window.location.origin}/offer/${row.id}`}
                        label="Copy offer link"
                        variant="outlined"
                        size="small"
                    />
                );
            }
        }
    ];

    const loadOffers = useCallback((query = {}) => {
        try {
            if (isEnterpriseSession) {
                fetchOffers(query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isEnterpriseSession]);

    useEffect(() => {
        loadOffers(filterConfigs);
    }, [filterConfigs, loadOffers]);

    const handleCellClick = ({id}) => {
        navigate(`/recruitment/offers/detail/${id}`);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout className="content-full-height">
            <Box className="pageheader">
                <h1 className="pagetitle">
                    {t("Offers")}
                    <p className="header-data-row-count">{rowCount}</p>
                </h1>
                <div className="pageaction">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => navigate('/recruitment/offers/detail')}
                    >
                        {t("Create a new offer")}
                    </Button>
                </div>
            </Box>
            <Box className="table-layout">
                <Table
                    ref={tableRef}
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                    onCellClick={handleCellClick}
                />
            </Box>
        </AppLayout>
    );
};
import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import Utils from "../../../../utils/Utils";
import {createMission, updateMission} from "../../../../services/missionService";
import {FieldHolder} from "../../FieldHolder";
import Functions from "../../../../utils/Functions";
import TagService from "../../../../services/tagService";
import skillService from "../../../../services/skillService";

export const ProposalAssignmentForm = forwardRef(({data, setData, canModify, onSaveFinish}, ref) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [skillsData, setSkillsData] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);
    const descriptionRef = useRef(null);
    const isSuperAdministrator = connectedUser.companyRole === Functions.USER_ROLE.SUPER_ADMINISTRATOR;

    const requiredFields = ['name'];

    useEffect(() => {
        setFormErrors({});

        TagService.getMissionTags({}, token).then((fetchedTags) => {
            if (!fetchedTags?.error) {
                const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                setTags(fetchedTagsData);
            }
        });

        skillService.getAllDefaultSkill(token).then((defaultSkills) => {
            defaultSkills = defaultSkills.map((skill) => {
                return {...skill, group: t(skillService.getSkillTypeLabel(skill.type))};
            });
            setSkillsData(defaultSkills);
        });
    }, [token]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            if (descriptionRef.current) {
                const editor = descriptionRef.current.getEditor();
                editor.blur();
            }

            let response;
            if (data.id) {
                response = await updateMission(data.id, data, token);
            } else {
                response = await createMission(data, token);
            }
            if (!response?.error) {
                onSaveFinish && onSaveFinish({
                    success: true,
                    data: {
                        id: response.id
                    }
                });
            }

            setLoading(false);
        })();
    };

    const handleEditOption = async (option, newLabel) => {
        if (option.value !== option.label) {
            await TagService.modifyTag(option.value, {name: newLabel}, token);
        }
    };

    const handleDeleteOption = async (option) => {
        if (option.value !== option.label) {
            await TagService.deleteTag(option.value, token);
        }
    };

    if (ref) {
        ref.current = handleSubmit;
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} noValidate>
            <Box sx={{display: "flex", flexDirection: "column", gap: "21px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment name")}
                            type="text"
                            name="name"
                            id="name"
                            defaultValue={data?.name}
                            onBlur={handleChange}
                            canModify={canModify}
                            mandatoryFlag={true}
                            error={formErrors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment Manager")}
                            type="text"
                            name="customOperationalManager"
                            id="customOperationalManager"
                            defaultValue={data?.customOperationalManager}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <FieldHolder
                                    fielddesc={t("Assignment type")}
                                    type="dropdown"
                                    name="deadline"
                                    id="deadline"
                                    set="AssignmentType"
                                    value={data?.deadline}
                                    formData={data}
                                    setFormData={setData}
                                    canModify={canModify}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}
                            >
                                <FieldHolder
                                    fielddesc={t("Starting date")}
                                    type="date"
                                    name="start_date"
                                    id="start_date"
                                    value={data?.start_date}
                                    onChange={handleChange}
                                    canModify={canModify}
                                />
                            </Grid>
                            {(data.deadline === Functions.TEXT_CDD || data?.estimated_end_date) && (
                                <Grid item xs={12} md={4}>
                                    <FieldHolder
                                        fielddesc={t("End date")}
                                        type="date"
                                        name="estimated_end_date"
                                        id="estimated_end_date"
                                        value={data?.estimated_end_date}
                                        onChange={handleChange}
                                        canModify={canModify}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Staffing rate (%)")}
                            type="number"
                            name="estimatedStaffingRate"
                            id="estimatedStaffingRate"
                            value={data?.estimatedStaffingRate}
                            min={1}
                            max={100}
                            unit="%"
                            onChange={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Assignment location")}
                            type="text"
                            name="address"
                            id="address"
                            defaultValue={data?.address}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            ref={descriptionRef}
                            fielddesc={t("Activity deliverable")}
                            type="wysiwyg"
                            name="description"
                            id="description"
                            value={data?.description}
                            onBlur={handleChange}
                            canModify={canModify}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Final customer")}
                            type="dropdown"
                            name="MissionTags.mission_final_customer"
                            id="MissionTags.mission_final_customer"
                            value={data?.MissionTags?.mission_final_customer}
                            datas={tags?.mission_final_customer}
                            formData={data}
                            setFormData={setData}
                            canModify={canModify}
                            ismulti={true}
                            allowAdd={isSuperAdministrator}
                            allowManageOption={isSuperAdministrator}
                            onEditOption={handleEditOption}
                            onDeleteOption={handleDeleteOption}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Skills required")}
                            type="dropdown"
                            name="Skills"
                            id="Skills"
                            datas={skillsData}
                            value={data?.Skills}
                            formData={data}
                            setFormData={setData}
                            ismulti={true}
                            canModify={canModify}
                            allowGroup={true}
                        />
                    </Grid>
                </Grid>
                {canModify && !ref && (
                    <Box sx={{textAlign: "center"}}>
                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            color="secondary"
                        >{t("Save")}</Button>
                    </Box>
                )}
            </Box>
        </form>
    );
});
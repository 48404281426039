import React, {useEffect, useState} from "react";
import {CustomCard} from "../../../../UI/CustomCard";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import Functions from "../../../../../utils/Functions";
import {Tag} from "../../../../UI/Tag";

export const OfferDiffusionStep = ({offerData, onChange, onSave, canModify}) => {
    const {t} = useTranslation();

    const editMode = offerData.steps.includes(Functions.OFFER_STEP.DIFFUSION);
    const [diffusionEditMode, setDiffusionEditMode] = useState(canModify && !editMode);

    const handleSave = async () => {
        const result = onSave && await onSave();
        if (!result?.error) {
            setDiffusionEditMode(false);
        }
    };

    return (
        <>
            <CustomCard
                className="box-item justify-content-center"
                editMode={diffusionEditMode}
                setEditMode={setDiffusionEditMode}
                canModify={canModify && editMode}
                onSave={handleSave}
            >
                <Typography variant="label">{t("Job advertisement")}</Typography>
                <Grid container spacing={{xs: 2, md: 3}}>
                    <Grid item xs={12} md={4}>
                        <Box className="card standardform-card">
                            <Stack gap={0.5}>
                                <FieldHolder
                                    fielddesc={t("Smarteem")}
                                    type="switch"
                                    name="shared"
                                    id="shared"
                                    value={Boolean(offerData?.shared)}
                                    onChange={() => {}}
                                    canModify={true}
                                    disabled={true}
                                />
                                <Typography>
                                    {t("Share your offers on Smarteem")} <Tag text="To come"/>
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </CustomCard>

            {!editMode && (
                <Stack alignItems="center" className="w-100">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleSave}
                    >
                        {t("Publish")}
                    </Button>
                </Stack>
            )}
        </>
    )
};
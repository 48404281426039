import {forwardRef} from "react";
import {useSelector} from "react-redux";
import {createRequest} from "../../../services/enterpriseCollaborationRequestService";
import {HelperCard} from "../../UI/HelperCard";
import {SlEnvolopeLetter} from "react-icons/sl";

const ValidationStep = forwardRef(({data, setLoading, onSubmit}, ref) => {
    const token = useSelector((state) => state.auth.token);

    const handleSubmit = () => {
        (async () => {
            setLoading(true);
            const response = await createRequest(data, token);
            setLoading(false);

            onSubmit && onSubmit(response);
        })()
    };
    ref.current = handleSubmit;

    return (
        <HelperCard
            icon={<SlEnvolopeLetter/>}
            headerText={"You have completed all the fields required to submit your Smarteem profile details"}
            descriptionText={
                "Your contact will receive an e-mail from Smarteem including your message. This will contain a secure link giving access to your profile, information and administrative documents, valid for 30 days.\n" +
                "\n" +
                "In addition, your contact will be able to manage an assignment with you directly via Smarteem. By clicking on ‘Validate’, you agree to share your information with the people who have access to this link."
            }
        />
    )
})

export default ValidationStep
import React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {useTranslation} from "react-i18next";
import './optiontoggle.css';

const OptionToggle = (
    {
        selectedOption,
        onChange,
        options = [
            {value: false, label: "Optional"},
            {value: true, label: "Mandatory"}
        ]
    }
) => {
    const {t} = useTranslation();

    return (
        <ToggleButtonGroup
            className="option-toggle"
            color="primary"
            value={selectedOption}
            exclusive
            onChange={(event, value) => onChange(value)}
            aria-label={t("Select option")}
            orientation="horizontal"
            size="small"
        >
            {options.map((option) => (
                <ToggleButton key={option.value} value={option.value} disableRipple>
                    {t(option.label)}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default OptionToggle;

import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, IconButton, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {fetchCandidates, saveApplication, saveOffer, savePipeline} from "../../../services/recruitment";
import Functions from "../../../utils/Functions";
import {MdAdd, MdCheck, MdClose, MdMoreHoriz} from "react-icons/md";
import {FieldHolder} from "../../../components/Forms/FieldHolder";
import _ from "lodash";
import S3Image from "../../../components/UI/S3Image";
import Utils from "../../../utils/Utils";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";
import {useNavigate} from "react-router-dom";

export const JobApplicationDetailKanban = ({offer, onListChange}) => {
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const {setFreelanceData, setFreelanceEditPermissions} = useFreelanceProfileStore();

    const [offerData, setOfferData] = useState([]);
    const [candidates, setCandidates] = useState({});
    const pendingSave = useRef(false);

    const pipelines = offerData?.RecruitmentPipelines || [];
    const hasDefault = pipelines.some(pipeline => pipeline.default === true);
    if (!hasDefault) {
        pipelines.push({
            title: '',
            isReadOnly: false,
            displayOrder: pipelines.length + 1,
            default: true
        });
    }
    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    useEffect(() => {
        if (isEnterpriseSession) {
            setOfferData(offer);

            if (offer?.id) {
                loadCandidates(offer.id);
            }
        }
    }, [isEnterpriseSession, offer]);

    useEffect(() => {
        if (pendingSave.current) {
            handleOfferSave();
            pendingSave.current = false;
        }
    }, [offerData?.RecruitmentPipelines]);

    const loadCandidates = useCallback((offerId, query = {}) => {
        try {
            fetchCandidates(offerId, query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    const groupedCandidates = _.groupBy(fetchedData.data, 'RecruitmentPipeline.id');
                    setCandidates(groupedCandidates);
                    onListChange && onListChange(fetchedData.count);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, [token]);

    const handleDragEnd = (result) => {
        const {source, destination} = result;

        if (!destination) return;

        if (
            source.droppableId === "board" &&
            (destination.index === 0 || destination.index === (pipelines.length - 1))
        ) return;

        if (result.type === "BOARD" && source.index !== 0) {
            const newPipelines = Array.from(pipelines);
            const [movedPipeline] = newPipelines.splice(source.index, 1);
            newPipelines.splice(destination.index, 0, movedPipeline);

            const updatedPipelines = newPipelines.map((item, index) => ({...item, displayOrder: index + 1}));
            setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
            pendingSave.current = true;
            return;
        }

        const sourcePipelineId = source.droppableId.replace('pipeline-', '');
        const destPipelineId = destination.droppableId.replace('pipeline-', '');

        if (sourcePipelineId === destPipelineId && source.index === destination.index) return;

        const updatedCandidates = {...candidates};
        const [movedCandidate] = updatedCandidates[sourcePipelineId].splice(source.index, 1);
        (updatedCandidates[destPipelineId] ||= []).splice(destination.index, 0, movedCandidate);

        setCandidates(updatedCandidates);

        handleApplicationSave({
            id: movedCandidate?.id,
            RecruitmentPipelineId: destPipelineId,
            RecruitmentPipelines: {
                [sourcePipelineId]: updatedCandidates[sourcePipelineId].map(({id}) => id),
                [destPipelineId]: updatedCandidates[destPipelineId].map(({id}) => id)
            }
        });
    };

    const handleCellClick = ({id}) => {
        setFreelanceData({});
        setFreelanceEditPermissions([]);
        navigate(`/recruitment/candidates/detail/${id}`);
    };

    const handlePipelineEdit = async (data) => {
        if (data.default) {
            const result = await savePipeline({...data, RecruitmentOfferId: offerData?.id}, token);
            if (!result?.error) {
                const index = pipelines.findIndex((pipeline) => pipeline.default);
                if (index > -1) {
                    const updatedPipelines = [...pipelines];
                    updatedPipelines[index] = result;
                    setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
                }
            }
        } else {
            const index = pipelines.findIndex((pipeline) => pipeline.id === data.id);
            if (index > -1) {
                const updatedPipelines = [...pipelines];
                updatedPipelines[index].title = data?.title;
                setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
                pendingSave.current = true;
            }
        }
    };

    const handlePipelineDelete = (id) => {
        const index = pipelines.findIndex((pipeline) => pipeline.id === id);
        if (index > -1) {
            const updatedPipelines = pipelines.filter((pipeline) => pipeline.id !== id);
            setOfferData((prevOfferData) => ({...prevOfferData, RecruitmentPipelines: updatedPipelines}));
            pendingSave.current = true;

            const defaultPipeline = pipelines.find((pipeline) => pipeline.title === 'New');
            if (defaultPipeline) {
                const updatedCandidates = {...candidates};
                const movedPipelineData = updatedCandidates[id];

                updatedCandidates[defaultPipeline.id] = [
                    ...(updatedCandidates[defaultPipeline.id] || []),
                    ...movedPipelineData
                ];

                delete updatedCandidates[id];

                setCandidates(updatedCandidates);
            }
        }
    };

    const handleOfferSave = () => {
        const data = {...offerData};

        delete data.RecruitmentOfferSkills;
        delete data.RecruitmentCriteria;
        delete data.RecruitmentQuestions;
        data.RecruitmentPipelines = data.RecruitmentPipelines.filter((pipeline) => !pipeline.default);

        saveOffer(data, token).then();
    };

    const handleApplicationSave = (data) => {
        saveApplication(data, token).then();
    };

    return (
        <Box id="jobApplicationDetailKanban" sx={{width: "100%", height: "100%"}}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="board" direction="horizontal" type="BOARD">
                    {(provided) => (
                        <Stack
                            direction="row"
                            className="w-100 h-100"
                            gap={2.5}
                            sx={{overflowX: 'auto', pb: 1}}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {pipelines.map((pipeline, pipelineIndex) => (
                                <Draggable
                                    key={pipeline.id}
                                    draggableId={`pipeline-${pipeline.id}`}
                                    index={pipelineIndex}
                                    isDragDisabled={Boolean(pipeline.isReadOnly || pipeline.default)}
                                >
                                    {(provided) => (
                                        <Stack
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="h-100"
                                            gap={1}
                                            sx={{minWidth: '270px'}}
                                        >
                                            <JobApplicationPipelineRow
                                                provided={provided}
                                                pipeline={pipeline}
                                                onEdit={handlePipelineEdit}
                                                onDelete={handlePipelineDelete}
                                            />
                                            {!pipeline.default && (
                                                <Droppable droppableId={`pipeline-${pipeline.id}`} type="CANDIDATE">
                                                    {(provided, snapshot) => (
                                                        <Box
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className="kanban-candidate-container"
                                                        >
                                                            {candidates[pipeline.id]?.map((candidate, candidateIndex) => (
                                                                <Draggable
                                                                    key={candidate.id}
                                                                    draggableId={`candidate-${candidate.id}`}
                                                                    index={candidateIndex}
                                                                >
                                                                    {(provided) => (
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            onDoubleClick={() => handleCellClick(candidate)}
                                                                        >
                                                                            <JobApplicationCandidateRow
                                                                                candidate={candidate}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {snapshot.isDraggingOver && (
                                                                <Box
                                                                    className="kanban-candidate-item"
                                                                    sx={{
                                                                        height: '100px',
                                                                        border: 'none',
                                                                        boxShadow: 'none',
                                                                        opacity: 0.5,
                                                                        transition: 'background-color 0.2s ease',
                                                                    }}
                                                                />
                                                            )}
                                                        </Box>
                                                    )}
                                                </Droppable>
                                            )}
                                        </Stack>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Stack>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
};

const JobApplicationPipelineRow = ({provided, pipeline, onEdit, onDelete}) => {
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [pipelineData, setPipelineData] = useState(pipeline);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const formRef = useRef(null);

    const isDefault = Boolean(pipelineData.default);

    useEffect(() => {
        if (editMode && formRef.current) {
            const titleField = formRef.current.elements.title;
            if (titleField) {
                titleField.focus();
                titleField.select();
            }
        }
    }, [editMode]);

    useEffect(() => {
        setPipelineData(pipeline);
    }, [pipeline]);

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = () => {
        if (pipelineData.isReadOnly) return;
        setEditMode(true);
    };

    const handleBlur = (e) => {
        const {name, value} = e.target;
        setPipelineData((prevData) => ({...prevData, [name]: value}));
        if (!pipelineData.default || !value) setEditMode(false);
        if (!pipelineData.default) {
            onEdit && onEdit({...pipelineData, [name]: value});
        }
    };

    const handleSave = () => {
        setEditMode(false);
        onEdit && onEdit(pipelineData);
    };

    const handleDelete = () => {
        handleMenuClose();
        if (pipelineData?.id) {
            onDelete && onDelete(pipelineData.id);
        }
    };

    return (
        <form ref={formRef} noValidate>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                className="kanban-pipeline-item"
            >
                <Box className="w-100">
                    {editMode
                        ? (
                            <FieldHolder
                                type="text"
                                name="title"
                                id={`pipelineTitle${pipelineData.id || ""}`}
                                defaultValue={!isDefault ? t(pipelineData.title) : ""}
                                onBlur={handleBlur}
                                canModify={true}
                            />
                        )
                        : (
                            <Box {...provided.dragHandleProps}>
                                <Typography
                                    onClick={isDefault ? handleItemClick : undefined}
                                    onDoubleClick={!isDefault ? handleItemClick : undefined}
                                    sx={{px: '14px', py: '5px'}}
                                >
                                    {isDefault
                                        ? (
                                            <>
                                                <MdAdd size={18}/> {t("Add a column")}
                                            </>
                                        )
                                        : t(pipelineData.title)

                                    }
                                </Typography>
                            </Box>
                        )
                    }
                </Box>
                {!pipeline.isReadOnly && !isDefault && (
                    <Box>
                        <IconButton
                            aria-controls="item-actions-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                            size="small"
                        >
                            <MdMoreHoriz size={20}/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleDelete}>
                                <Typography color="secondary">{t("Delete")}</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                )}
                {editMode && pipeline.default && (
                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <IconButton
                            title={t("Save")}
                            onClick={handleSave}
                            color="secondary"
                            size="small"
                            sx={{border: '1px solid var(--secondary-main-color)', p: '2px'}}
                        >
                            <MdCheck size={20}/>
                        </IconButton>
                        <IconButton
                            title={t("Cancel")}
                            onClick={() => setEditMode(false)}
                            color="light"
                            size="small"
                            sx={{border: '1px solid var(--light-main-color)', p: '2px'}}
                        >
                            <MdClose size={20}/>
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </form>
    );
};

const JobApplicationCandidateRow = memo(({candidate}) => {
    const freelanceProfile = candidate?.FreelanceProfile;
    const user = freelanceProfile?.User;

    return (
        <Box className="kanban-candidate-item">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                gap={1}
            >
                <S3Image value={user?.picture} displaySkeleton={true}/>
                <Box sx={{textWrap: "wrap", pl: 0.5}}>
                    <Typography sx={{fontWeight: "bold"}}>{user?.fullName}</Typography>
                    <Typography>{freelanceProfile?.jobTitle}</Typography>
                </Box>
            </Stack>
            <Stack>
                <Typography color="info.main">{candidate.interactionCount} interactions</Typography>
                <Typography color="info.main">{candidate.noteCount} notes</Typography>
            </Stack>
            <Box sx={{position: "absolute", top: '3px', right: '4px'}}>
                <Typography color="info.main" sx={{fontSize: '12px'}}>
                    {Utils.timeAgo(candidate.createdAt)}
                </Typography>
            </Box>
        </Box>
    );
}, (prevProps, nextProps) => {
    return prevProps.id === nextProps.id;
});
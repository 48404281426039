import React, {forwardRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Box, Typography} from "@mui/material";
import './editor.css';

const Editor = forwardRef(({value, onChange, onBlur, ...props}, ref) => {
    const modules = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
            ['link'],
            ['clean']
        ],
    };

    const handleChange = (content) => {
        onChange && onChange({
            target: {
                name: props.name,
                value: trimContent(content)
            }
        })
    };

    const handleBlur = (previousRange, source, editor) => {
        const content = editor.getHTML();
        onBlur && onBlur({
            target: {
                name: props.name,
                value: trimContent(content)
            }
        });
    };

    const trimContent = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        if (textContent.trim().length === 0) {
            return '';
        }
        return html;
    };

    return (
        <Box sx={{position: "relative", top: props.label ? "-10px" : undefined}}>
            {props.label && (
                <Typography
                    sx={{
                        marginLeft: "13px",
                        fontSize: "11px",
                        color: "rgba(0, 0, 0, 0.6)"
                    }}
                >
                    {props.label}
                    {props.required && (
                        <span className="mandatory-text"> *</span>
                    )}
                </Typography>
            )}
            <input
                name={props.name}
                value={value}
                readOnly
                style={{position: 'absolute', width: 0, height: 0, opacity: 0, pointerEvents: 'none'}}
            />
            <ReactQuill
                ref={ref}
                className={`${props.error ? "error" : ""}`}
                modules={modules}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                {...props}
            />
        </Box>
    );
});

export default Editor;
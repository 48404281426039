import React, {useEffect, useState, useRef, forwardRef} from "react";
import {fetchFileInfo} from "../../services/fileService";
import {useSelector} from "react-redux";

import {FaCircleNotch} from "react-icons/fa";

import {MessageComponent} from "../UI/MessageComponent";
import "./fileinput.css";
import "./input.css";
import filesFreelanceProfile from "../../services/filesFreelanceProfile";
import missionRxjs from "../../services/missionRxjs";
import {t} from "i18next";
import Functions from "../../utils/Functions";
import {IconButton, Stack, Typography} from "@mui/material";
import {
    MdClose,
    MdCloudDone,
    MdCloudUpload,
    MdUploadFile,
} from "react-icons/md";
import useFileOpener from "../../hooks/customHooks";
import Utils from "../../utils/Utils";

export const FileInput = forwardRef(
    (
        {
            sibling = null,
            file,
            value,
            formData,
            setFormData,
            objectAttachedType,
            canModify,
            setFileInfo = null,
            onFileChange,
            ...props
        },
        ref
    ) => {
        const token = useSelector((state) => state.auth.token);
        const [fileName, setFileName] = useState(null);
        const [triggerApi, setTriggerApi] = useState(true);
        const [selectedFiles, setSelectedFiles] = React.useState([]);
        const [isBtnDelVisible, setIsBtnDelVisible] = useState(false);
        const [formatError, setFormatError] = useState(false);
        const {loading, message, openFileInNewTab} = useFileOpener();

        useEffect(() => {
            const getFileInfo = async () => {
                try {
                    if (value) {
                        const fetchedFileInfo = await fetchFileInfo(value, token);
                        if (fetchedFileInfo) {
                            setFileName(fetchedFileInfo.name);
                            setIsBtnDelVisible(true);
                        }
                    }
                    setTriggerApi(false); // Réinitialiser le déclencheur après l'appel API
                } catch (error) {
                    console.error("error: ", error.message);
                }
            };

            if (triggerApi && typeof value !== "boolean" && value) {
                getFileInfo();
            }
        }, [triggerApi, value, token]);

        //##Gestion des fichier
        useEffect(() => {
            const selectedFiles = filesFreelanceProfile.getFilesObservable();
            const subscriptionFiles = selectedFiles.subscribe((files) => {
                setSelectedFiles(files);
                if (!value && files.length) {
                    const selectedFile = files.find(
                        (file) => file.name === props.name
                    );
                    if (selectedFile) {
                        setFileName(selectedFile.theFile?.name);
                        setIsBtnDelVisible(true);
                    }
                }
            });

            return () => {
                subscriptionFiles.unsubscribe();
            };
        }, [value, props.name]);

        // Fonction pour ouvrir le fichier sauvegardé sous S3
        const handleFileOpen = async (value) => {
            await openFileInNewTab(value, Functions.TEXT_DOCUMENT, token);
        };

        const handleFileChange = async (e) => {
            //##Control if file or text
            let data = e.target.files[0];
            if (!data) {
                return false;
            }

            let fileData = {
                theFile: data,
                name: e.target.name,
                type: data.type,
                objectAttachedType: objectAttachedType,
            };
            setFileName(data.name);

            setIsBtnDelVisible(true);

            if (sibling === "factureFile") {
                if (data.type !== "application/pdf") {
                    setFormatError(true);
                } else {
                    missionRxjs.setFactureFile(fileData);
                }
            }

            if (sibling === "missionQuote") {
                if (data.type !== "application/pdf") {
                    setFormatError(true);
                } else {
                    missionRxjs.setQuoteFile(fileData);
                }
            }

            if (sibling === "missionContrat") {
                if (data.type !== "application/pdf") {
                    setFormatError(true);
                } else {
                    missionRxjs.setContratFile(fileData);
                }
            }

            if (setFileInfo) {
                setFileInfo(fileData);
            } else {
                let files = selectedFiles;
                let selectedItemIndex = files.findIndex(
                    (item) => item.name === e.target.name
                );

                if (selectedItemIndex !== -1) {
                    // La clé a été trouvée, mettons à jour sa valeur
                    files[selectedItemIndex].theFile = data;
                } else {
                    files = [...files, fileData];
                }

                filesFreelanceProfile.setFiles(files);
            }

            // Mettre à jour temporairement la valeur de l'objet pour savoir que le champ est renseigné
            setFormData && setFormData(Utils.updateNestedField(formData, props.name, true));
            onFileChange && onFileChange(props.name, fileData);
        };

        const fileInputRef = useRef(null);
        const handleDeleteFileClick = () => {
            setFormData && setFormData(Utils.updateNestedField(formData, props.name, null));
            onFileChange && onFileChange(props.name, null);

            if (props.name && !setFileInfo) {
                let files = selectedFiles;
                let selectedItemIndex = files.findIndex(
                    (item) => item.name === props.name
                );
                if (selectedItemIndex !== -1) {
                    // La clé a été trouvée, mettons à jour sa valeur
                    files.splice(selectedItemIndex, 1);
                }
                filesFreelanceProfile.setFiles(files);
            }

            //Delete file if setFileInfo is defined
            setFileInfo && setFileInfo(null);

            fileInputRef.current.value = null;
            setIsBtnDelVisible(false);
            setFileName(null);
        };

        let label;
        if (canModify) {
            label = (
                <>
                    <span>{t("Click to download a file")}</span>
                </>
            );
        } else if (!canModify) {
            label = (
                <>
                    <span>{t("No file available")}</span>
                </>
            );
        }

        return (
            <div
                className={`${props.className} d-flex align-items-center`}
                style={{width: "100%"}}
            >
                <div style={{width: "100%", textAlign: "left"}}>
                    {props.label && (
                        <Typography variant="label">
                            <span>{props.label}</span>
                            {props.required && (
                                <span className="mandatory-text"> *</span>
                            )}
                        </Typography>
                    )}
                    <div className={`file-upload-container ${props.error ? "error" : ""}`}>
                        <div className={`file-upload input file-inputstandard ${props.className}`}>
                            <input
                                name={props.name}
                                type="file"
                                id={props.id}
                                className="file-input"
                                accept=".pdf, .doc, .docx, .jpeg, .png"
                                max="20971520"
                                onChange={handleFileChange}
                                disabled={!canModify}
                                ref={fileInputRef}
                            />
                            <Typography
                                variant="label"
                                sx={{color: "#7b7f8b", fontSize: "14px"}}
                            >
                                {fileName ? (
                                    <MdCloudDone
                                        size={20}
                                        style={{marginRight: "5px"}}
                                    />
                                ) : (
                                    <MdCloudUpload
                                        size={20}
                                        style={{marginRight: "5px"}}
                                    />
                                )}
                                {label}
                            </Typography>
                            {formatError && (
                                <div style={{textAlign: "start", color: "red"}}>
                                    {`${t("Format allowed")}, PDF`}
                                </div>
                            )}
                        </div>
                        <div>
                            {fileName && (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={0.5}
                                    className="uploaded-file"
                                    sx={{width: "100%", marginBottom: "15px"}}
                                >
                                    <Typography color="primary">
                                        <MdUploadFile
                                            className="Mui-icon"
                                            size={20}
                                            style={{cursor: "pointer"}}
                                            onClick={() =>
                                                handleFileOpen(value)
                                            }
                                        />
                                    </Typography>
                                    <Typography variant="label">
                                        {fileName}
                                    </Typography>

                                    {isBtnDelVisible && (
                                        <div
                                            style={{
                                                marginLeft: "8px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <IconButton
                                                ref={ref}
                                                color="secondary"
                                                onClick={() => handleDeleteFileClick()}
                                                size="small"
                                            >
                                                <MdClose size={20}/>
                                            </IconButton>
                                        </div>
                                    )}
                                    {loading ? (
                                        <FaCircleNotch
                                            size={20}
                                            className="spinner"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Stack>
                            )}
                        </div>
                    </div>
                </div>

                {message?.type && (
                    <MessageComponent
                        type={message.type}
                        message={message.message}
                        width="100%"
                    />
                )}
            </div>
        );
    }
);

import React, {useEffect, useState} from 'react';
import {Button, Typography, Stack} from '@mui/material';
import './timerangepicker.css';
import {useTranslation} from "react-i18next";
import Utils from "../../utils/Utils";

const TimeRangePicker = (
    {
        selectedDate,
        selectedTimes,
        minTime,
        maxTime,
        timeStep,
        onSelect,
        editMode
    }
) => {
    const {t} = useTranslation();
    const [selectedRanges, setSelectedRanges] = useState([]);

    useEffect(() => {
        setSelectedRanges(selectedTimes);
    }, [selectedTimes]);

    const handleTimeClick = (startTime, endTime) => {
        if (!editMode) {
            return;
        }
        const isSelected = selectedRanges.some((range) => range.startTime === startTime && range.endTime === endTime);

        setSelectedRanges((prevSelectedRanges) => {
            const newSelectedRanges = isSelected
                ? prevSelectedRanges.filter((range) => range.startTime !== startTime || range.endTime !== endTime)
                : [...prevSelectedRanges, {startTime, endTime}];

            onSelect && onSelect(newSelectedRanges);
            return newSelectedRanges
        });
    };

    const isTimeInRange = (time) => {
        const currentTime = new Date(time).getHours() * 60 + new Date(time).getMinutes();
        const minTimeValue = parseInt(minTime.split(':')[0]) * 60 + parseInt(minTime.split(':')[1]);
        const maxTimeValue = parseInt(maxTime.split(':')[0]) * 60 + parseInt(maxTime.split(':')[1]);

        return currentTime >= minTimeValue && currentTime <= maxTimeValue;
    };

    return (
        <Stack direction="column" alignItems="start">
            <div>
                <Typography sx={{color: 'var(--primary-main-color)'}}>{t("Slots")}</Typography>
                {
                    selectedDate &&
                    <Typography>{Utils.formatDate(selectedDate, 'DD MMMM')}</Typography>
                }
            </div>
            <Stack
                direction="column"
                sx={{
                    maxHeight: 240,
                    overflow: "hidden scroll",
                    width: "100%",
                    marginTop: "5px",
                    paddingRight: "10px"
                }}
            >
                {[...Array(24 * 60 / timeStep)].map((_, index) => {
                    let startTime = new Date(2000, 1, 1, 0, 0).setMinutes(index * timeStep);
                    let endTime = new Date(2000, 1, 1, 0, 0).setMinutes((index + 1) * timeStep);

                    if (isTimeInRange(startTime) && isTimeInRange(endTime)) {
                        const startTimeFormatted = Utils.formatTime(startTime, true);
                        const endTimeFormatted = Utils.formatTime(endTime, true);

                        const isSelected = selectedRanges.some((range) =>
                            range.startTime === startTimeFormatted && range.endTime === endTimeFormatted
                        );

                        return (
                            <Button
                                key={index}
                                className="MuiTimeRange-item"
                                variant="contained"
                                color={isSelected ? "success" : "light"}
                                onClick={() => handleTimeClick(startTimeFormatted, endTimeFormatted)}
                                sx={{margin: '3px'}}
                                size="medium"
                                fullWidth
                                disabled={!editMode}
                            >
                                {`${Utils.formatTime(startTime)} - ${Utils.formatTime(endTime)}`}
                            </Button>
                        );
                    }
                    return null;
                })}
            </Stack>
        </Stack>
    );
};

export default TimeRangePicker;

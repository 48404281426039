import React from 'react';
import {Outlet} from 'react-router-dom';
import {Menu} from "../components/UI/Menu";
import {Box} from "@mui/material";

function MainLayout() {
    return (
        <Box className="layout">
            <Menu/>
            <Box className="container-content">
                <Outlet/>
            </Box>
        </Box>
    );
}

export default MainLayout;

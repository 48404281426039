import React, {useEffect, useRef, useState} from "react";
import "./chart.css";
import Chart from "react-apexcharts";

export const StackedBarChart = (
    {
        horizontal = false,
        currency = null,
        series,
        categories,
        chartHeight,
        customOptions = {}
    }
) => {
    const chartRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const barHeight = 60;
    if (typeof chartHeight === "undefined") {
        chartHeight = categories.length * barHeight + 36;
    }

    let maxValue = 0;

    if (series.length) {
        // Calculer les sommes des valeurs pour chaque index
        const summedData = series[0].data.map((_, i) =>
            series.reduce((sum, s) => sum + s.data[i], 0)
        );

        // Calculer la valeur maximale basée sur les sommes des valeurs
        maxValue = Math.max(...summedData) * 1.25;
    }

    const calculateOffsets = (width) => {
        const baseOffsetX = -1020; // Offset de base pour X
        const maxScreenWidth = 1920; // Largeur d'écran maximale pour le calcul
        const maxBars = 9; // Nombre maximal de barres visibles
        const maxOffsetX = baseOffsetX / maxBars; // Offset maximal pour chaque barre
        const columnWidthReduction = 0.3;
        const offsetMultiplier = (width / maxScreenWidth) * (1 - columnWidthReduction); // Multiplicateur en fonction de la largeur de l'écran

        let offsetX = maxOffsetX * offsetMultiplier; // Offset X calculé en fonction de la largeur de l'écran

        // Ajustement basé sur des largeurs d'écran spécifiques
        const adjustmentMap = [
            {maxWidth: 384, adjustment: -6},
            {maxWidth: 425, adjustment: -5},
            {maxWidth: 480, adjustment: -10},
            {maxWidth: 640, adjustment: -16},
            {maxWidth: 768, adjustment: -20},
            {maxWidth: 960, adjustment: -25},
            {maxWidth: 1024, adjustment: -15},
            {maxWidth: 1097, adjustment: -16},
            {maxWidth: 1440, adjustment: 5},
            {maxWidth: 1536, adjustment: 3},
            {maxWidth: 1745, adjustment: 2},
            {maxWidth: 1920, adjustment: 0},
            {maxWidth: 2400, adjustment: -3},
            {maxWidth: 2560, adjustment: -4.5},
            {maxWidth: 2880, adjustment: -6},
            {maxWidth: 3840, adjustment: -11},
            {maxWidth: 5760, adjustment: -22},
            {maxWidth: 7680, adjustment: -31},
        ];

        for (const {maxWidth, adjustment} of adjustmentMap) {
            if (width <= maxWidth) {
                offsetX += adjustment;
                break;
            }
        }

        return {
            offsetX: offsetX,
            offsetY: -2
        };
    };

    const offsets = horizontal ? {offsetX: 5, offsetY: 0} : calculateOffsets(windowWidth);

    const data = {
        series: series,
        options: {
            chart: {
                type: 'bar',
                height: chartHeight,
                stacked: true,
                toolbar: {show: false},
                zoom: {enabled: false},
                fontFamily: 'Poppins',
            },
            grid: {show: false},
            legend: {
                show: false,
                showForSingleSeries: false,
                height: 30,
                offsetY: 10,
                onItemHover: {
                    highlightDataSeries: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: horizontal,
                    columnWidth: '30%',
                    // barHeight: barHeight + "%",
                    distributed: Boolean(customOptions.colors),
                    dataLabels: {
                        total: {
                            enabled: customOptions.showTotal ?? true,
                            show: customOptions.showTotal ?? true,
                            offsetX: offsets.offsetX,
                            offsetY: offsets.offsetY,
                            formatter: function (val) {
                                return val.toLocaleString() + (currency ? ` ${currency}` : '');
                            },
                            style: {
                                color: 'var(--primary-main-color)',
                                fontSize: '12px',
                                fontWeight: 800,
                            }
                        }
                    }
                }
            },
            xaxis: {
                type: 'category',
                categories: categories,
                min: 0,
                max: maxValue,
                labels: {
                    show: !horizontal,
                    // rotate: 0,
                    style: {
                        colors: '#919191',
                    }
                },
                axisBorder: {
                    show: !horizontal,
                },
                axisTicks: {
                    show: !horizontal
                }
            },
            yaxis: {
                show: horizontal,
                labels: {
                    minWidth: horizontal ? 210 : 0,
                    maxWidth: horizontal ? 210 : 160,
                    style: {
                        colors: 'var(--primary-main-color)',
                        fontSize: '12px',
                        vAlign: 'middle',
                    }
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val.toLocaleString() + (currency ? ` ${currency}` : '');
                    },
                    title: {
                        formatter: function (seriesName) {
                            return seriesName + ' :';
                        }
                    }
                },
            },
            fill: {opacity: 1},
            dataLabels: {
                enabled: false
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        yaxis: {
                            labels: {
                                minWidth: horizontal ? 120 : 0,
                                maxWidth: horizontal ? 120 : 160,
                            }
                        }
                    }
                }
            ],
            colors: customOptions.colors ?? []
        }
    };

    return (
        <Chart
            type="bar"
            ref={chartRef}
            options={data.options}
            series={data.series}
            height={chartHeight}
        />
    );
};
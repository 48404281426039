import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import {AppLayout} from "../../../layouts/AppLayout";

import "../../page.css";
import {Table, TableActionMenu} from "../../../components/Tables/Table";
import Utils from "../../../utils/Utils";
import Functions from "../../../utils/Functions";
import {Alert, Box, Button, Grid, Stack, Typography} from "@mui/material";
import Popup from "../../../components/Popup/Popup";
import {AffiliationForm} from "./AffiliationForm";
import {deleteAffiliation, fetchFreelanceAffiliations} from "../../../services/collaborationService";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const AffiliationBoard = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const freelanceId = connectedUser?.freelanceId;

    const tableRef = useRef();
    const defaultValue = {
        email: null,
        companyName: "",
        firstName: "",
        lastName: "",
        language: "",
        comment: "",
    };
    const [isEditingMode, setEditingMode] = useState(false);
    const [editingData, setEditingData] = useState(defaultValue);
    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);
    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const isFreelanceSession = connectedUser.sessionType === Functions.SESSION_TYPE_FREELANCE;

    const columns = [
        {
            field: 'companyName',
            type: 'string',
            headerName: t("Company"),
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.companyName;
            }
        },
        {
            field: 'fullName',
            type: 'string',
            headerName: t("Contact"),
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row.fullName;
            }
        },
        {
            field: 'email',
            headerName: t("Email"),
            type: 'string',
            minWidth: 250,
            flex: 2.5,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const email = row.email;
                return (
                    <div className="MuiDataGrid-cellContent" title={email} role="presentation">
                        {email}
                    </div>
                );
            }
        },
        {
            field: 'createdAt',
            type: 'date',
            headerName: t("Date added"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.createdAt && new Date(row.createdAt),
            renderCell: ({row}) => {
                return Utils.formatDate(row.createdAt);
            }
        },
        {
            field: 'action',
            headerName: '',
            minWidth: 80,
            flex: 0.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <TableActionMenu
                        row={row}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                );
            },
        }
    ];

    const loadAffiliations = useCallback((query = {}) => {
        try {
            if (isFreelanceSession) {
                fetchFreelanceAffiliations(freelanceId, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setData(fetchedData.data);
                    }
                    setLoading(false);
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [token, isFreelanceSession, freelanceId]);

    useEffect(() => {
        loadAffiliations(filterConfigs);
    }, [filterConfigs, loadAffiliations]);

    const handleEdit = (id) => {
        const index = data.findIndex((affiliation) => affiliation.id === id);
        if (index > -1) {
            setEditingData(data[index] ?? defaultValue);
            setEditingMode(true);
        }
    };

    const handleDelete = async (id) => {
        await deleteAffiliation(id, token);
        loadAffiliations(filterConfigs);
    };

    const handleCreate = () => {
        setEditingData(defaultValue);
        setEditingMode(true);
    };

    const handleDialogClose = () => {
        setEditingMode(false);
    };

    const handleSaveFinish = (success) => {
        if (success) {
            loadAffiliations(filterConfigs);
            handleDialogClose();
        }
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <AppLayout>
            <Box sx={{width: {xs: '100%', md: 'calc(57% + 302px)'}}}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box className="box-item standardform-card card-padding-wide" sx={{alignItems: "center"}}>
                            <Stack
                                direction="column"
                                spacing={3}
                                sx={{width: {xs: "100%", md: "68%"}}}
                            >
                                <Typography variant="h1" color="primary">
                                    {t("Join the Smarteem affiliation programme and boost your income!")}
                                </Typography>
                                <Typography className="text-center">
                                    {t("Recommend Smarteem to your customers and business partners and receive 50% of their subscriptions for one year.")}
                                </Typography>
                                <Stack direction="column" spacing={2}>
                                    <Typography variant="h2" color="info.main">{t("How does it work?")}</Typography>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h3">
                                            1 - {t("Recommend Smarteem to your corporate customers and partners")}
                                        </Typography>
                                        <Typography className="text-center">
                                            <Trans
                                                t={t}
                                                i18nKey={
                                                    "Share your experience of Smarteem and highlight its innovative " +
                                                    "features using our <0>{{link}}</0>."
                                                }
                                                values={{link: t("Interactive demonstration").toString().toLowerCase()}}
                                                components={[<a
                                                    className=""
                                                    href="https://app.arcade.software/share/7hVHQthmRO6XMkIQLX3c"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                ></a>]}
                                            />
                                        </Typography>
                                    </Stack>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h3">
                                            2 - {t("Put us in touch with interested companies")}
                                        </Typography>
                                        <Typography className="text-center">
                                            {t("Send us the details of interested companies, and we'll arrange a meeting to present Smarteem to them.")}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h3">
                                            3 - {t("Receive your reward")}
                                        </Typography>
                                        <Typography className="text-center">
                                            {t(
                                                "As soon as a company you have recommended becomes a Smarteem customer, " +
                                                "you will receive 50% of their subscription for one year. " +
                                                "That's an average of 100 euros per month for each recommended company!"
                                            )}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={2}>
                            <Stack alignItems="end">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCreate}
                                >{t("Add a company")}</Button>

                                <Popup
                                    title={t("Add a company")}
                                    open={isEditingMode}
                                    onDialogClose={handleDialogClose}
                                >
                                    <AffiliationForm
                                        affiliation={editingData}
                                        onSaveFinish={handleSaveFinish}
                                    />
                                </Popup>
                            </Stack>
                            <Box className="box-item standardform-card card-padding-wide">
                                <Typography variant="label">{t("Interested companies")}</Typography>
                                <div className="table-layout">
                                    <Stack direction="column" className="w-100">
                                        <Table
                                            fullHeight={true}
                                            ref={tableRef}
                                            columns={columns}
                                            data={data}
                                            rowCount={rowCount}
                                            pageSize={pageSize}
                                            filterConfigs={filterConfigs}
                                            onChange={handleDataGridChange}
                                            hideFilter={true}
                                        />
                                        {!loading && !Boolean(data.length) &&
                                            <Alert
                                                icon={false}
                                                severity="info"
                                                sx={{position: 'relative', marginTop: "-10px", width: "100%"}}
                                            >{t("No companies added")}</Alert>
                                        }
                                    </Stack>
                                </div>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    );
};


import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/referrals/";

export const fetchFreelanceReferrals = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching referral :", error);
        throw error;
    }
};

export const createReferral = async (referralData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', referralData, token);
    } catch (error) {
        console.error("Error creating referral :", error);
        throw error;
    }
};

export const updateReferral = async (referralId, referralData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${referralId}`, 'PUT', referralData, token);
    } catch (error) {
        console.error("Error updating referral :", error);
        throw error;
    }
};

export const deleteReferral = async (referralId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${referralId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting referral :", error);
        throw error;
    }
};

export const sendReferenceAccessRequest = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}reference-access-request`, 'POST', data, token);
    } catch (error) {
        console.error("Error sending reference access request :", error);
        throw error;
    }
};

export const sendReferenceAccessFeedback = async (data) => {
    try {
        return requestApi(`${BASE_URL}reference-access-feedback`, 'POST', data);
    } catch (error) {
        console.error("Error sending reference access feedback :", error);
        throw error;
    }
};
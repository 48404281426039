import React from "react";
import {Box} from "@mui/material";
import {CandidateTable} from "../Candidate/CandidateTable";

export const JobApplicationDetailList = ({offer, onListChange}) => {
    return (
        <Box id="jobApplicationDetailList" className="w-100 h-100">
            <CandidateTable offerId={offer?.id} onListChange={onListChange}/>
        </Box>
    );
};
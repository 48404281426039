import React, {useRef, useState} from "react";
import {FieldHolder} from "../../../FieldHolder";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import Utils from "../../../../../utils/Utils";
import {createReferral, updateReferral} from "../../../../../services/referralService";

const ReferralForm = ({token, referral, onSaveFinish}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(referral);
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);

    const requiredFields = Utils.getRequiredFields('referral');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let response;
            if (data.id) {
                response = await updateReferral(data.id, data, token);
            } else {
                response = await createReferral(data, token);
            }
            onSaveFinish && onSaveFinish(!response?.error);

            setLoading(false);
        })()
    }

    return <form
        ref={formRef}
        onSubmit={handleSubmit}
        noValidate
    >
        <Box sx={{width: {md: "40vw"}, display: "flex", flexDirection: "column", gap: "15px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("First name")}
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={data.firstName}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("Last name")}
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={data.lastName}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.lastName}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("Email")}
                        type="text"
                        name="email"
                        id="email"
                        value={data.email}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.email}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FieldHolder
                        fielddesc={t("Phone number")}
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={data.phoneNumber}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.phoneNumber}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("LinkedIn profile")}
                        type="url"
                        name="linkedinProfile"
                        id="linkedinProfile"
                        placeholder={t("www.linkedin.com/user")}
                        value={data.linkedinProfile}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.linkedinProfile}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Company")}
                        type="text"
                        name="companyName"
                        id="companyName"
                        value={data.companyName}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.companyName}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Context / Assignment")}
                        type="multiline"
                        name="context"
                        id="context"
                        value={data.context}
                        onChange={handleChange}
                        canModify={true}
                        mandatoryFlag={true}
                        error={formErrors.context}
                    />
                </Grid>
                <Grid item xs={12} sx={{mt: 2}}>
                    <FieldHolder
                        fielddesc={t("I authorise Smarteem to contact this person for reference purposes")}
                        type="checkbox"
                        name="contactPermission"
                        id="contactPermission"
                        value={data.contactPermission}
                        onChange={handleChange}
                        canModify={true}
                        error={formErrors.contactPermission}
                    />
                </Grid>
            </Grid>
            <div style={{textAlign: "center"}}>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                >{t("Save")}</Button>
            </div>
        </Box>
    </form>
}

export default ReferralForm
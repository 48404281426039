import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {FieldHolder} from "../../../../FieldHolder";

export const FreelanceInfo2Card = ({formData}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode, setEditMode] = useState(false);
    const [identityDocumentFile, setIdentityDocumentFile] = useState(null);
    const [visaFile, setVisaFile] = useState(null);
    const [certificateFile, setCertificateFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const files = [];
    const requiredFields = ['identity_document'];

    useEffect(() => {
        if (editMode) {
            setFormErrors({});
            setIdentityDocumentFile(null);
            setVisaFile(null);
            setCertificateFile(null);
        }
    }, [editMode]);

    const handleSave = async () => {
        files.push(identityDocumentFile);
        files.push(visaFile);
        files.push(certificateFile);

        const result = await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback: () => setEditMode(false),
            requiredFields,
            freelanceProfileFiles: files
        });
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            setFreelanceData(result.data);
        }
    };

    return <>
        <CustomCard
            cardName="FreelanceInfo2Card"
            editMode={editMode}
            setEditMode={setEditMode}
            onSave={handleSave}
        >
            <FieldHolder
                fielddesc={t("Identity document")}
                type="file"
                name="identity_document"
                id="identity_document"
                placeholder={t("Identity document")}
                value={freelanceProfileData.identity_document}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setIdentityDocumentFile}
                canModify={editMode}
                mandatoryFlag={true}
                error={formErrors.identity_document}
            />
            <FieldHolder
                fielddesc={t("Visa")}
                type="file"
                name="visa"
                id="visa"
                value={freelanceProfileData.visa}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setVisaFile}
                canModify={editMode}
            />
            <FieldHolder
                fielddesc={t("Work authorization")}
                type="file"
                name="work_autorization_certificate"
                id="work_autorization_certificate"
                value={freelanceProfileData.work_autorization_certificate}
                formData={freelanceProfileData}
                setFormData={setFreelanceData}
                setFileInfo={setCertificateFile}
                canModify={editMode}
            />
        </CustomCard>
    </>
};
import React from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/fr"
import {Checkbox, FormControl, FormControlLabel, Switch, TextField} from "@mui/material";
import moment from "moment";

const Input = (
    {
        type,
        value,
        className = "",
        onChange,
        displayPlaceholder,
        min,
        max,
        ...props
    }
) => {
    const checkType = (type) => {
        let res;

        switch (type) {
            case "date":
                res = <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <DatePicker
                            label={props.label}
                            value={value ? dayjs(value) : null}
                            onChange={
                                (newValue) => onChange(
                                    {
                                        target: {
                                            name: props.name,
                                            value: newValue
                                                ? moment(newValue.toDate()).format("YYYY-MM-DD") : null
                                        }
                                    }
                                )
                            }
                            slotProps={{
                                textField: {
                                    clearable: true,
                                    error: props?.error,
                                    required: props.required
                                },
                            }}
                            {...props}
                        />
                    </LocalizationProvider>
                </FormControl>
                break;
            case "checkbox":
            case "switch":
                const isCheckBox = type === 'checkbox';
                const controlType = isCheckBox ? Checkbox : Switch;
                res = <FormControl fullWidth>
                    <FormControlLabel
                        value={Boolean(value)}
                        control={React.createElement(controlType, {
                            disableRipple: true,
                            checked: Boolean(value),
                            onChange: (e) => onChange({
                                target: {
                                    name: e.target.name,
                                    value: e.target.checked ? 1 : 0
                                }
                            }),
                            sx: {'& .MuiSvgIcon-root': {fontSize: 30}},
                            ...props
                        })}
                        label={props.label}
                        labelPlacement="end"
                        sx={{'& .MuiFormControlLabel-label': {fontWeight: !isCheckBox ? 'bold' : undefined}}}
                    />
                </FormControl>
                break;
            default:
                let inputProps;
                if (type === 'number') {
                    inputProps = {
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    };
                    if (typeof min !== "undefined") {
                        inputProps.min = min;
                    }
                    if (typeof max !== "undefined") {
                        inputProps.max = max;
                    }
                }

                res = <TextField
                    fullWidth
                    type={type}
                    value={props.onBlur ? undefined : (value ?? "")}
                    onChange={onChange}
                    inputProps={inputProps}
                    helperText=""
                    autoComplete={type === 'number' ? "off" : "true"}
                    {...props}
                />
                break;
        }
        return res;
    };

    return <>{checkType(type)}</>;
};

const Textarea = ({value, onChange, ...props}) => {
    return <TextField
        fullWidth
        multiline
        rows={props.rows || 4}
        value={props.onBlur ? undefined : (value ?? "")}
        onChange={onChange}
        {...props}
    />
};

export {
    Input, Textarea
};

import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";

import {saveToken} from "../../../utils/actions/authActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

import {MessageComponent} from "../../UI/MessageComponent";
import {FieldHolder} from "../FieldHolder";

import "./signupform.css";

import {loginUser} from "../../../services/userService";
import {Box, Button, Stack} from "@mui/material";
import Functions from "../../../utils/Functions";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";

export const LoginForm = ({popup = false, toggleLoginVisibility, toggleResetPasswordVisibility, redirect}) => {
    const {t} = useTranslation();

    const {
        setFreelanceData,
        setFreelanceEditPermissions
    } = useFreelanceProfileStore();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({email: "", password: ""});
    const [message, setMessage] = useState({});

    useEffect(() => {
        setFreelanceData({});
        setFreelanceEditPermissions([]);
    }, []);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleLogin = (e) => {
        e.preventDefault();

        (async () => {
            setLoading(true);
            try {
                const responseData = await loginUser(formData);
                setLoading(false);
                if (responseData.success) {
                    dispatch(
                        saveToken({
                            token: responseData.token,
                            userId: responseData.userId,
                            userFirstName: responseData.userFirstName,
                            userLastName: responseData.userLastName,
                            userEmail: responseData.userEmail,
                            freelanceId: responseData.freelanceId,
                            enterpriseId: responseData.enterpriseId,
                            companyId: responseData.companyId,
                            companyLogo: responseData.companyLogo,
                            companyName: responseData.companyName,
                            companyRole: responseData.companyRole,
                            userType: responseData.userType,
                            displayConfig: responseData.displayConfig ? JSON.parse(responseData.displayConfig) : {},
                            fullAccess: responseData.fullAccess
                        })
                    );

                    if (redirect) {
                        navigate(redirect);
                    } else {
                        if (!responseData.onboardingComplete) {
                            navigate("/onboarding");
                        } else {
                            // Après une authentification réussie, redirigez l'utilisateur vers la dernière page visitée.
                            const lastVisitedPage = location.state?.lastVisitedPage;
                            if (lastVisitedPage && lastVisitedPage !== "/") {
                                navigate(lastVisitedPage);
                            } else {
                                // Si lastVisitedPage n'est pas défini, redirigez l'utilisateur vers la page d'accueil par défaut.
                                if (responseData.userType === Functions.SESSION_TYPE_ENTERPRISE) {
                                    navigate("/talents/talentboard");
                                } else {
                                    navigate("/profile");
                                }
                            }
                        }
                    }
                } else {
                    setMessage({
                        type: "error",
                        message: t(responseData.error),
                    });
                }
            } catch (error) {
                console.error(error);
            }
        })();
    };

    return (
        <Box className={!popup ? "centerformcard" : "w-100"}>
            <form className="inputform standardform" onSubmit={handleLogin}>
                <FieldHolder
                    fielddesc={t("Email")}
                    type="text"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    mandatoryFlag={true}
                />
                <FieldHolder
                    fielddesc={t("Password")}
                    type="password"
                    name="password"
                    id="password"
                    placeholder={t("************")}
                    value={formData.password}
                    onChange={handleChange}
                    mandatoryFlag={true}
                />

                {popup
                    ? (
                        <Stack alignItems="center" className="w-100" sx={{mt: 3}}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={loading}
                                disableRipple
                            >
                                {t("Connect")}
                            </Button>
                        </Stack>
                    )
                    : (
                        <>
                            <p className="indexlink" onClick={toggleResetPasswordVisibility}>
                                {t("First connection / forgot password?")}
                            </p>
                            <div className="buttonbox">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    disabled={loading}
                                    fullWidth
                                    disableRipple
                                >
                                    {t("Connect")}
                                </Button>
                            </div>
                        </>
                    )
                }

                <MessageComponent type={message.type} message={message.message} width="100%"/>
                <div className="field">
                    <p className="indexlink" onClick={toggleLoginVisibility}>
                        {t("Create a new account")}
                    </p>
                </div>
            </form>
        </Box>
    );
};

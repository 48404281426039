import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/recruitment/";

export const fetchOffer = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}offers/${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching offer:", error);
        throw error;
    }
};

export const fetchOffers = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}offers?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching offers :", error);
        throw error;
    }
};

export const saveOffer = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}offers/save`, 'POST', data, token);
    } catch (error) {
        console.error("Error saving offer :", error);
        throw error;
    }
};

export const savePipeline = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}pipelines/save`, 'POST', data, token);
    } catch (error) {
        console.error("Error saving pipeline :", error);
        throw error;
    }
};

export const fetchCandidates = async (offerId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}candidates/${offerId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching candidates :", error);
        throw error;
    }
};

export const fetchFreelanceApplications = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}applications/freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching freelance applications :", error);
        throw error;
    }
};

export const fetchApplication = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}applications/${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching application :", error);
        throw error;
    }
};

export const saveApplication = async (data, token) => {
    try {
        return requestApi(`${BASE_URL}application/save`, 'POST', data, token);
    } catch (error) {
        console.error("Error saving application :", error);
        throw error;
    }
};
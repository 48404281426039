import {useTranslation} from "react-i18next";
import {Stack, Typography} from "@mui/material";
import {MdCircle} from "react-icons/md";
import Utils from "../../../utils/Utils";
import React, {memo} from "react";
import "./freelanceavailabilitydisplay.css";
import _ from "lodash";

export const FreelanceAvailabilityDisplay = memo(({availabilityData}) => {
    const {t} = useTranslation();

    const getAvailabilityDisplay = () => {
        const availabilities = availabilityData?.FreelanceProfileAvailabilities ?? [];
        const notAvailabilities = availabilityData?.FreelanceProfileNotAvailabilities ?? [];

        const availability = availabilities.length ? availabilities[0] : notAvailabilities[0];

        return availability ? availabilityStatus(availability) : t('Not specified');
    };

    const availabilityStatus = ({availabilityType, date}) => {
        const colorStatuses = {
            'Available': 'available',
            'Partially available': 'partially-available',
            'Not available': 'not-available',
        };
        const colorStatus = colorStatuses[availabilityType] || 'default';

        return <Stack direction="column" alignItems="center" className="freelance-availability-display" sx={{width: "100%", fontSize: "14px"}}>
            {availabilityType &&
                <Typography variant="iconText">
                    <MdCircle className={`availability-status-${colorStatus}`} size={14} style={{marginRight: "3px"}}/>
                    {t(availabilityType)}
                </Typography>
            }
            {availabilityType !== 'Not available' &&
                <Stack direction="row" className="freelance-availability-date" spacing={0.5}>
                    <div>{t("From")} {Utils.formatDate(date)}</div>
                </Stack>
            }
        </Stack>
    };

    return (
        <>{getAvailabilityDisplay()}</>
    );
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps.availabilityData, nextProps.availabilityData);
});
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Functions from "../../../../utils/Functions";
import ResponsiveStepper from "../../../UI/ResponsiveStepper";
import {Box, Stack} from "@mui/material";
import {OfferDescriptionStep} from "./Steps/OfferDescriptionStep";
import Utils from "../../../../utils/Utils";
import {saveOffer} from "../../../../services/recruitment";
import {useSelector} from "react-redux";
import {OfferInformationStep} from "./Steps/OfferInformationStep";
import {OfferProcessStep} from "./Steps/OfferProcessStep";
import {OfferDiffusionStep} from "./Steps/OfferDiffusionStep";

export const OfferForm = ({offer, ...props}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [activeStep, setActiveStep] = useState(Functions.OFFER_STEP.DESCRIPTION);
    const [offerData, setOfferData] = useState(offer);

    const steps = [
        {key: Functions.OFFER_STEP.DESCRIPTION, name: 'Offer description'},
        {key: Functions.OFFER_STEP.INFORMATION, name: 'Information requested'},
        {key: Functions.OFFER_STEP.PROCESS, name: 'Recruitment process'},
        {key: Functions.OFFER_STEP.DIFFUSION, name: 'Job Posting and Distribution'},
    ];

    useEffect(() => {
        setOfferData(offer);
    }, [offer]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setOfferData((prevFormData) => Utils.updateNestedField(prevFormData, name, value));
    };

    const handleSubmit = async (nextStep = "") => {
        const {valid, errors} = Utils.validateRequiredFields(offerData, ['title', 'description']);
        if (!valid) {
            return {error: true, data: errors};
        }

        try {
            const data = {...offerData};

            data.RecruitmentQuestions = data?.RecruitmentQuestions.filter((question) => question.title);
            data.RecruitmentPipelines = data?.RecruitmentPipelines.filter((pipeline) => pipeline.title);

            if (
                activeStep === Functions.OFFER_STEP.DIFFUSION &&
                !data.steps.includes(Functions.OFFER_STEP.DIFFUSION)
            ) {
                data.status = "Published";
            }

            data.steps = Utils.updateStatusSection(data.steps, activeStep);

            const result = await saveOffer(data, token);
            if (!result?.error) {
                props.setTriggerApi(true);
                props.setOfferId(result.id);
                if (nextStep) {
                    setActiveStep(nextStep);
                }
                return {success: true, data: data};
            } else {
                return {success: false, message: t("Error saving data")};
            }
        } catch (error) {
            return {success: false, message: error.toString()};
        }
    };

    return (
        <div className="form-layout" id="offerForm">
            <Stack className="w-100" direction="column" spacing={5}>
                <Box className="w-100">
                    <ResponsiveStepper
                        steps={steps}
                        nonLinear={Boolean(offerData?.id)}
                        completedSteps={offerData?.steps || []}
                        stepKey={activeStep}
                        onStepClick={(index) => (
                            setActiveStep(steps[index]?.key)
                        )}
                    />
                </Box>
                <Box className="standardform">
                    {activeStep === Functions.OFFER_STEP.DESCRIPTION && (
                        <OfferDescriptionStep
                            offerData={offerData}
                            setOfferData={setOfferData}
                            titleRef={props.titleRef}
                            onChange={handleChange}
                            onSave={handleSubmit}
                            canModify={true}
                        />
                    )}
                    {activeStep === Functions.OFFER_STEP.INFORMATION && (
                        <OfferInformationStep
                            offerData={offerData}
                            setOfferData={setOfferData}
                            onSave={handleSubmit}
                            canModify={true}
                        />
                    )}
                    {activeStep === Functions.OFFER_STEP.PROCESS && (
                        <OfferProcessStep
                            offerData={offerData}
                            setOfferData={setOfferData}
                            onSave={handleSubmit}
                            canModify={true}
                        />
                    )}
                    {activeStep === Functions.OFFER_STEP.DIFFUSION && (
                        <OfferDiffusionStep
                            offerData={offerData}
                            setOfferData={setOfferData}
                            onChange={handleChange}
                            onSave={handleSubmit}
                            canModify={true}
                        />
                    )}
                </Box>
            </Stack>
        </div>
    );
};
import React, {memo, useEffect, useRef, useState} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {endOfMonth, format, isAfter, isBefore, isSameDay, isWithinInterval, startOfMonth} from 'date-fns';
import './availabilitycalendar.css';
import {
    fetchFreelanceAvailabilities,
    deleteAvailability,
    saveAvailability
} from "../../../../../services/freelanceAvailabilityService";
import {useSelector} from "react-redux";
import {MdCircle, MdClose} from "react-icons/md";
import Popup from "../../../../Popup/Popup";
import {Box, Button, Grid, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from "@mui/material";
import parameters from "../../../../../utils/parameters.json";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import TimeRangePicker from "../../../../Inputs/TimeRangePicker";
import Utils from "../../../../../utils/Utils";

function getAvailabilityClass(status) {
    const colors = {
        'Selecting': 'selecting',
        'Available': 'available',
        'Partially available': 'partially-available',
        'Not available': 'not-available',
    };
    return colors[status] || '';
}


const AvailabilityCalendar = memo(function AvailabilityCalendar2(
    {
        freelanceProfile,
        setFreelanceProfile,
        editMode
    }
) {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [showAvailabilityDialog, setShowAvailabilityDialog] = useState(false);
    const [availabilityType, setAvailabilityType] = useState('');
    const [selectedDates, setSelectedDates] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const defaultRange = {
        startDate: null,
        endDate: null,
        FreelanceProfileTimeSlots: []
    };
    const [currentRange, setCurrentRange] = useState(defaultRange);
    const [hoveredDate, setHoveredDate] = useState(null);

    const calendarRef = useRef(null);
    const fetchFreelanceAvailabilitiesRef = useRef();

    let availabilityTypes = parameters['Availability'];

    useEffect(() => {
        const labelButton = document.querySelector('.react-calendar__navigation__label');

        const handleClick = (e) => {
            e.stopPropagation();
            e.preventDefault();
        };

        if (labelButton) {
            labelButton.addEventListener('click', handleClick);
        }

        return () => {
            if (labelButton) {
                labelButton.removeEventListener('click', handleClick);
            }
        };
    }, []);

    useEffect(() => {
        const start = startOfMonth(currentDate);
        const end = endOfMonth(currentDate);

        const filter = {
            date: {
                cond: 'and',
                operator: 'between',
                value: [format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')]
            }
        };

        fetchFreelanceAvailabilitiesRef.current({filter: JSON.stringify(filter)});
    }, [currentDate]);

    fetchFreelanceAvailabilitiesRef.current = (query = {}) => {
        try {
            if (freelanceProfile?.id) {
                fetchFreelanceAvailabilities(freelanceProfile.id, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        const availabilitiesData = fetchedData.data.map((availability) => ({
                            ...availability,
                            startDate: availability.date,
                            endDate: availability.date
                        }));
                        setSelectedDates(availabilitiesData);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDateChange = (date) => {
        date = format(date, 'yyyy-MM-dd');

        if (!selecting) {
            setCurrentRange({...currentRange, startDate: date, endDate: null});
            setSelecting(true);
            setHoveredDate(null);
        } else {
            const startDate = isBefore(date, currentRange.startDate) ? date : currentRange.startDate;
            const endDate = isAfter(date, currentRange.startDate) ? date : currentRange.startDate;
            const newRange = {startDate, endDate};

            setCurrentRange({...currentRange, startDate, endDate});

            // Vérifier les chevauchements avec les plages de dates existantes
            let conflictFound = false;
            for (let range of selectedDates) {
                if (
                    isWithinInterval(range.startDate, {start: newRange.startDate, end: newRange.endDate}) ||
                    isWithinInterval(range.endDate, {start: newRange.startDate, end: newRange.endDate}) ||
                    isWithinInterval(newRange.startDate, {start: range.startDate, end: range.endDate}) ||
                    isWithinInterval(newRange.endDate, {start: range.startDate, end: range.endDate})
                ) {
                    conflictFound = true;
                    break;
                }
            }

            if (conflictFound) {
                availabilityTypes.Erase = {
                    "nativetext": "Erase"
                };
            } else {
                delete availabilityTypes.Erase;
            }

            setShowAvailabilityDialog(true);
        }
    };

    const handleMouseOver = (date) => {
        if (selecting && currentRange.startDate) {
            setHoveredDate(format(date, 'yyyy-MM-dd'));
        }
    };

    const tileClassName = ({date, view}) => {
        if (view === 'month') {
            date = format(date, 'yyyy-MM-dd');
            if (selecting && currentRange.startDate && (hoveredDate || currentRange.endDate)) {
                const _endDate = currentRange.endDate || hoveredDate;
                const startDate = isBefore(_endDate, currentRange.startDate) ? _endDate : currentRange.startDate;
                const endDate = isAfter(_endDate, currentRange.startDate) ? _endDate : currentRange.startDate;
                if (
                    (isAfter(date, startDate) && isBefore(date, endDate)) ||
                    isSameDay(date, startDate) || isSameDay(date, endDate)
                ) {
                    return 'highlight-selecting';
                }
            }

            const selectedDate = getSelectedDate(date);
            if (selectedDate) {
                return `highlight-${getAvailabilityClass(selectedDate.availabilityType)}`;
            }
        }
        return 'date-tile';
    };

    const tileContent = ({date, view}) => {
        if (view === 'month') {
            let tileTooltip = null;
            const formattedDate = format(date, 'yyyy-MM-dd');
            const selectedDate = getSelectedDate(formattedDate);
            if (selectedDate) {
                const _availabilityType = selectedDate.availabilityType;
                if (!isErase(_availabilityType)) {
                    const _availabilityTypeLabel = t(availabilityTypes[_availabilityType]?.nativetext);
                    if (isPartiallyAvailable(_availabilityType)) {
                        tileTooltip = <Stack direction="column" spacing={1}>
                            {_availabilityTypeLabel}
                            <Stack direction="column">
                                {selectedDate.FreelanceProfileTimeSlots
                                    .sort((a, b) => {
                                        const startTimeA = new Date(`${formattedDate} ${a.startTime}`).getTime();
                                        const startTimeB = new Date(`${formattedDate} ${b.startTime}`).getTime();
                                        return startTimeA - startTimeB;
                                    })
                                    .map((time, index) => {
                                    const startTime = Utils.formatTime(`${formattedDate} ${time.startTime}`);
                                    const endTime = Utils.formatTime(`${formattedDate} ${time.endTime}`);
                                    return (
                                        <Typography key={index} variant="textPrimary">
                                            {`${startTime} - ${endTime}`}
                                        </Typography>
                                    );
                                })}
                            </Stack>
                        </Stack>
                    } else {
                        tileTooltip = _availabilityTypeLabel;
                    }
                }
            }
            return (
                <Tooltip
                    classes={{popper: "MuiTooltip-light"}}
                    title={tileTooltip}
                    placement="right"
                    disableInteractive
                >
                    <div className="tile-content" onMouseOver={() => handleMouseOver(date)}>
                        {date.getDate()}
                    </div>
                </Tooltip>
            );
        }

        return date.getDate();
    };

    const handleActiveStartDateChange = ({activeStartDate}) => {
        setCurrentDate(activeStartDate);
    };

    const handleAvailabilityChange = (event, value) => {
        if (value !== null && value !== undefined) {
            setAvailabilityType(value);
            if (!isPartiallyAvailable(value)) {
                setCurrentRange({...currentRange, FreelanceProfileTimeSlots: []});
            }
        }
    };

    const handleTimeSelect = (times) => {
        setCurrentRange({
            ...currentRange,
            FreelanceProfileTimeSlots: times
        });
    };

    const handleAvailabilitySave = async () => {
        const data = {
            ...currentRange,
            availabilityType: availabilityType,
            FreelanceProfileId: freelanceProfile?.id
        };
        setSelectedDates((prev) => [...prev, data]);

        let result;
        if (isErase(availabilityType)) {
            result = await deleteAvailability(data, token);
        } else {
            result = await saveAvailability(data, token);
        }

        if (!result.error) {
            updateFreelanceAvailability(result.updatedUser);
            handleAvailabilityDialogClose();
        }
    };

    const updateFreelanceAvailability = (updatedUser) => {
        const now = Utils.formatDateTz(new Date());
        setFreelanceProfile({
            ...freelanceProfile,
            availabilityUpdatedAt: now,
            AvailabilityUpdatedUser: {
                fullName: updatedUser?.fullName
            }
        });
    };

    const getSelectedDate = (date) => {
        const reverseSelectedDates = _.reverse([...selectedDates]);
        return reverseSelectedDates.find((d) => {
            const {startDate, endDate} = d;
            return date >= startDate && date <= endDate;
        });
    }

    const isPartiallyAvailable = (type) => {
        return type === 'Partially available';
    };

    const isErase = (type) => {
        return type === 'Erase';
    };

    const handleAvailabilityDialogClose = () => {
        setAvailabilityType('');
        setShowAvailabilityDialog(false);
        setCurrentRange(defaultRange);
        setHoveredDate(null);
        setSelecting(false);
    };

    return (
        <div>
            <div ref={calendarRef} className="calendar-container fade-in">
                <Calendar
                    locale="fr"
                    onClickDay={handleDateChange}
                    tileClassName={tileClassName}
                    tileContent={tileContent}
                    tileDisabled={({date, view}) => !editMode}
                    onActiveStartDateChange={handleActiveStartDateChange}
                />
            </div>

            <Popup
                open={showAvailabilityDialog}
                onDialogClose={handleAvailabilityDialogClose}
                hideCloseButton={true}
            >
                <Box>
                    <Grid container alignItems="start" justifyContent="center" spacing={2}>
                        <Grid item xs="auto">
                            <Stack direction="column" spacing={0.5}>
                                <Typography variant="textPrimary">{t("Select my availability")}</Typography>
                                <ToggleButtonGroup
                                    orientation="vertical"
                                    value={availabilityType}
                                    exclusive
                                    onChange={handleAvailabilityChange}
                                    aria-label={t("Select my availability")}
                                >
                                    {
                                        Object.keys(availabilityTypes).map((key) => {
                                            const availability = parameters['Availability'][key].nativetext;
                                            const icon = isErase(key)
                                                ? <MdClose size={20} style={{marginRight: "8px"}}/>
                                                : <MdCircle size={20} className={`status-${getAvailabilityClass(key)}`}
                                                            style={{marginRight: "8px"}}/>;
                                            return <ToggleButton
                                                key={key}
                                                value={key}
                                                sx={{justifyContent: "start"}}
                                            >
                                                {icon}
                                                <Typography
                                                    title={t(availability)}
                                                    sx={{fontSize: 14, cursor: "pointer", textTransform: "initial"}}
                                                    noWrap
                                                >{t(availability)}</Typography>
                                            </ToggleButton>
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Stack>
                        </Grid>
                        {isPartiallyAvailable(availabilityType) && (
                            <Grid item xs="auto">
                                <TimeRangePicker
                                    selectedTimes={currentRange.FreelanceProfileTimeSlots}
                                    timeStep={30}
                                    minTime="07:00"
                                    maxTime="21:00"
                                    onSelect={handleTimeSelect}
                                    editMode={editMode}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <div style={{textAlign: "center", marginTop: 15}}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={!availabilityType}
                            onClick={handleAvailabilitySave}
                            size="small"
                        >{t("Validate")}</Button>
                    </div>
                </Box>
            </Popup>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.selectedDates === nextProps.selectedDates &&
        prevProps.editMode === nextProps.editMode;
});


export default AvailabilityCalendar;

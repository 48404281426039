import React, {useEffect, useState} from 'react';
import {Box, Button, Chip, Grid, Stack, Typography} from "@mui/material";
import './publicoffer.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {fetchOffer} from "../../../services/recruitment";
import {FaBriefcase, FaBuilding, FaFileSignature, FaLocationDot, FaUser} from "react-icons/fa6";
import {GoMoveToEnd, GoMoveToStart} from "react-icons/go";
import Utils from "../../../utils/Utils";
import {Display} from "../../../components/Inputs/Display";
import Popup from "../../../components/Popup/Popup";
import {SignupForm} from "../../../components/Forms/Login_Signup/SignupForm";
import {LoginForm} from "../../../components/Forms/Login_Signup/LoginForm";
import Functions from "../../../utils/Functions";

export const PublicOffer = () => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const {id} = useParams();
    const navigate = useNavigate();

    const [showApplyDialog, setShowApplyDialog] = useState(false);
    const [loginVisibility, setLoginVisibility] = useState(false);
    const [offer, setOffer] = useState({});

    const offerPublished = offer?.status === Functions.STATUS_OFFER.Published;
    const redirectUrl = `/offer/onboarding/${id}`;

    useEffect(() => {
        fetchOffer(id, token).then((fetchedOffer) => {
            if (!fetchedOffer?.error) {
                if (fetchedOffer.RecruitmentOfferSkills) {
                    fetchedOffer.RecruitmentOfferSkills = fetchedOffer.RecruitmentOfferSkills.map(os => os.Skill);
                }
                setOffer(fetchedOffer);
            }
        });
    }, []);

    const getOfferDetailItem = (label, icon, iconLabel) => {
        return (
            <Grid container alignItems="center" spacing={1.5}>
                <Grid item>
                    <Chip icon={icon} label={t(iconLabel)} variant="outlined" color="info"/>
                </Grid>
                <Grid item xs={6} md={9}>
                    <Typography variant="label" color="primary">{label}</Typography>
                </Grid>
            </Grid>
        );
    };

    const toggleLoginVisibility = () => setLoginVisibility(prev => !prev);

    const handleApplyClick = () => {
        if (connectedUser.sessionType === "Freelance" && connectedUser.isAuthenticated) {
            navigate(redirectUrl);
        } else {
            setShowApplyDialog(true);
        }
    };

    return (
        <>
            <Grid container className="public-layout">
                <Grid item xs={12} lg={8}>
                    <Box className="public-layout-header"></Box>
                    <Box className="public-layout-container offer-detail h-100" sx={{py: "8vh"}}>
                        {Object.keys(offer).length > 0 && (
                            <>
                                <Box className="card standardform-card" sx={{gap: '30px '}}>
                                    <h1 className="pagetitle">{offer && offer.title ? offer.title : ""}</h1>
                                    <Stack direction="column" className="w-100" gap={1.5}>
                                        {getOfferDetailItem(offer?.Company?.name, <FaBuilding size={15}/>, "Company")}
                                        {offer?.location && getOfferDetailItem(
                                            offer.location,
                                            <FaLocationDot size={15}/>,
                                            "Location"
                                        )}
                                        {offer?.contractType && getOfferDetailItem(
                                            t(Utils.getParameterValue(offer.contractType, "OfferContractType")),
                                            <FaFileSignature size={15}/>,
                                            "Contract"
                                        )}
                                        {offer?.startDate && getOfferDetailItem(
                                            Utils.formatDate(offer.startDate),
                                            <GoMoveToStart size={15}/>,
                                            "Start"
                                        )}
                                        {offer?.endDate && getOfferDetailItem(
                                            Utils.formatDate(offer.endDate),
                                            <GoMoveToEnd size={15}/>,
                                            "End"
                                        )}
                                    </Stack>
                                    {offerPublished && (
                                        <Button variant="contained" color="secondary" onClick={handleApplyClick}>
                                            {t("Apply")}
                                        </Button>
                                    )}
                                </Box>

                                <Box className="card standardform-card" sx={{gap: '30px '}}>
                                    <Chip icon={<FaBriefcase size={15}/>} label={t("Offer description")}
                                          variant="outlined"
                                          color="info"/>
                                    <Display type="wysiwyg" value={offer?.description}/>
                                </Box>

                                {(offer?.requiredProfile || offer?.RecruitmentOfferSkills.length > 0) && (
                                    <Box className="card standardform-card" sx={{gap: '30px '}}>
                                        <Chip icon={<FaUser size={15}/>} label={t("Profile required")}
                                              variant="outlined"
                                              color="info"/>
                                        {offer?.requiredProfile && (
                                            <Display type="wysiwyg" value={offer.requiredProfile}/>
                                        )}
                                        {offer?.RecruitmentOfferSkills.length > 0 && (
                                            <Display
                                                type="dropdown"
                                                label={t("Skills required")}
                                                value={offer.RecruitmentOfferSkills}
                                                ismulti={true}
                                            />
                                        )}
                                    </Box>
                                )}

                                {offerPublished && (
                                    <Button variant="contained" color="secondary" onClick={handleApplyClick}>
                                        {t("Apply")}
                                    </Button>
                                )}
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>

            <Popup
                open={showApplyDialog}
                onDialogClose={() => setShowApplyDialog(false)}
            >
                <Box sx={{width: {md: "28vw"}}}>
                    <Stack direction="column" alignItems="center" gap={5} sx={{p: 8}}>
                        <Typography variant="h2" color="primary">
                            {t("Welcome!")}
                        </Typography>
                        <Box className="w-100">
                            {loginVisibility
                                ? (
                                    <LoginForm
                                        popup={true}
                                        toggleLoginVisibility={toggleLoginVisibility}
                                        redirect={redirectUrl}
                                    />
                                )
                                : (
                                    <SignupForm
                                        popup={true}
                                        toggleLoginVisibility={toggleLoginVisibility}
                                        redirect={redirectUrl}
                                    />
                                )
                            }
                        </Box>
                    </Stack>
                </Box>
            </Popup>
        </>
    );
};
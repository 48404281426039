import React, {useEffect, useState} from "react";
import {AppLayout} from "../../layouts/AppLayout";
import {Box, Button, IconButton, Menu, MenuItem, Stack} from "@mui/material";
import {duplicateMission, fetchMission, updateMissionStatus} from "../../services/missionService";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Functions from "../../utils/Functions";
import {MissionForm} from "../../components/Forms/Mission/MissionForm";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";

import "../page.css";
import "./missionsdetail.css";
import {fetchFileInfo} from "../../services/fileService";
import missionRxjs from "../../services/missionRxjs";
import {useToast} from "../../context/ToastProvider";
import TagService from "../../services/tagService";
import {MdSettings} from "react-icons/md";

export const MissionsDetail = ({proposal = false}) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const navigate = useNavigate();
    const {id} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab') || Functions.SECTION_DETAIL_MISSION;

    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);

    const [showConfirmMissionStatus, setShowConfirmMissionStatus] = useState(null);
    const [confirmMissionTitle, setConfirmMissionTitle] = useState("");
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [showArchiveButton, setShowArchiveButton] = useState(false);

    const [missionSection, setMissionSection] = useState(activeTab);
    const [missionId, setMissionId] = useState(id);
    const [mission, setMission] = useState({});
    const [triggerApi, setTriggerApi] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const closedMissionStatuses = [
        Functions.STATUS_MISSION.Archived,
        Functions.STATUS_MISSION.Deleted
    ];

    useEffect(() => {
        setMissionId(id);
    }, [id]);

    useEffect(() => {
        (async () => {
            if (typeof missionId !== "undefined" && triggerApi) {
                let fetchedMission = await fetchMission(missionId, token);
                if (fetchedMission.MissionTags) {
                    fetchedMission.MissionTags = fetchedMission.MissionTags.map(mt => mt.Tag);
                    fetchedMission.MissionTags = TagService.groupTagsByType(fetchedMission.MissionTags);
                }

                setMission(fetchedMission);
                missionRxjs.setMission(fetchedMission);

                if (fetchedMission?.brief) {
                    const fetchedFileInfo = await fetchFileInfo(fetchedMission?.brief, token);
                    missionRxjs.setFileNameFile(fetchedFileInfo.name);
                }

                setTriggerApi(false);

                if (!closedMissionStatuses.includes(fetchedMission.status)) {
                    if (fetchedMission.staffedFreelanceId) {
                        setShowDeleteButton(false);
                        setShowArchiveButton(true);
                    } else {
                        setShowArchiveButton(false);
                        setShowDeleteButton(true);
                    }
                }
            }
        })();
    }, [missionId, triggerApi, token]);

    const handleShowConfirmMissionStatus = (status) => {
        const confirmMissionTitles = {
            [Functions.STATUS_MISSION.Archived]: "Are you sure you want to archive the assignment?",
            [Functions.STATUS_MISSION.Closed]: "Are you sure you want to mark this assignment as completed?",
            [Functions.STATUS_MISSION.Deleted]: "Are you sure you want to delete the assignment?"
        };
        handleMenuClose();
        setShowConfirmMissionStatus(status);
        setConfirmMissionTitle(confirmMissionTitles[status] ?? "");
    };

    const handleShowConfirmCreateMission = () => {
        setShowConfirmMissionStatus("Create");
        setConfirmMissionTitle("Are you sure you want to create an assignment?");
    };

    const handleShowConfirmDuplicateMission = () => {
        setShowConfirmMissionStatus("Duplicate");
        setConfirmMissionTitle("Are you sure you want to duplicate the assignment?");
    };

    const handlePopupClose = () => setShowConfirmMissionStatus(null);

    const handleActionClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleActionSubmit = async () => {
        if (["Create", "Duplicate"].includes(showConfirmMissionStatus)) {
            const result = await duplicateMission(missionId, token);
            if (!result.error) {
                handlePopupClose();

                setTriggerApi(true);
                navigate(`/missions/missionsdetail/${result.id}`);
                if (showConfirmMissionStatus === "Duplicate") {
                    showToast(t("You have duplicated the assignment"));
                }
            }
        } else {
            await handleUpdateMissionStatus();
        }
    };

    const handleUpdateMissionStatus = async () => {
        const updatedMessages = {
            [Functions.STATUS_MISSION.Archived]: "You have successfully archived the assignment",
            [Functions.STATUS_MISSION.Closed]: "You have successfully completed the assignment",
            [Functions.STATUS_MISSION.Deleted]: "You have successfully removed the assignment"
        };

        const data = {status: showConfirmMissionStatus};
        if (showConfirmMissionStatus === Functions.STATUS_MISSION.Closed) {
            data.estimated_end_date = new Date();
        }

        const updatedMission = await updateMissionStatus(missionId, data, token);
        if (!updatedMission?.error) {
            handlePopupClose();

            setTriggerApi(true);
            showToast(t(updatedMessages[showConfirmMissionStatus]));
        }
    };

    const getButtonActions = () => {
        return <>
            {sessionType !== Functions.SESSION_TYPE_FREELANCE && missionId && (
                <Stack direction="row" alignItems="center" spacing={2}>
                    {proposal
                        ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={handleShowConfirmCreateMission}
                            >
                                {t("Create an assignment")}
                            </Button>
                        )
                        : (
                            <>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="large"
                                    onClick={handleShowConfirmDuplicateMission}
                                >
                                    {t("Duplicate the assignment")}
                                </Button>
                                {!closedMissionStatuses.includes(mission.status) && (
                                    <>
                                        <IconButton
                                            color="primary"
                                            aria-controls="item-actions-menu"
                                            aria-haspopup="true"
                                            onClick={handleActionClick}
                                            size="large"
                                        >
                                            <MdSettings size={28}/>
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={menuOpen}
                                            onClose={handleMenuClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            sx={{marginTop: 0.5}}
                                        >
                                            {mission.deadline === Functions.TEXT_CDI && mission.status !== Functions.STATUS_MISSION.Closed && (
                                                <MenuItem
                                                    onClick={() => handleShowConfirmMissionStatus(Functions.STATUS_MISSION.Closed)}
                                                    sx={{fontSize: '16px'}}
                                                >{t("Mark the assignment as completed")}</MenuItem>
                                            )}
                                            {showArchiveButton && (
                                                <MenuItem
                                                    onClick={() => handleShowConfirmMissionStatus(Functions.STATUS_MISSION.Archived)}
                                                    sx={{fontSize: '16px'}}
                                                >{t("Archive the assignment")}</MenuItem>
                                            )}
                                            {showDeleteButton && (
                                                <MenuItem
                                                    onClick={() => handleShowConfirmMissionStatus(Functions.STATUS_MISSION.Deleted)}
                                                    sx={{fontSize: '16px'}}
                                                >{t("Delete the assignment")}</MenuItem>
                                            )}
                                        </Menu>
                                    </>
                                )}
                            </>
                        )
                    }
                </Stack>
            )}
        </>
    };

    return (
        <AppLayout>
            <Box className="pageheader" sx={{width: proposal ? "70%" : "100%"}}>
                <Stack
                    alignItems="start"
                    justifyContent="space-between"
                    sx={{
                        flexDirection: {xs: "column", md: "row"},
                        width: {xs: "100%", md: "50%"}
                    }}
                >
                    <p className="pagetitle">
                        {mission && mission.name ? mission.name : t("New assignment")}
                    </p>
                    <div className="pageaction">
                        <Box sx={{display: {md: 'none', xs: 'block'}}}>
                            {getButtonActions()}
                        </Box>
                    </div>
                </Stack>
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    {getButtonActions()}
                </Box>
            </Box>
            <MissionForm
                mission={mission}
                missionSection={missionSection}
                setMissionSection={setMissionSection}
                setTriggerApi={setTriggerApi}
                missionId={missionId}
                setMissionId={setMissionId}
                closedMission={closedMissionStatuses.includes(mission?.status)}
                proposal={proposal}
            />

            <ConfirmPopup
                open={Boolean(showConfirmMissionStatus)}
                title={t(confirmMissionTitle)}
                onConfirm={handleActionSubmit}
                onDialogClose={handlePopupClose}
            />
        </AppLayout>
    );
};
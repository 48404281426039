import {AppLayout} from "../../layouts/AppLayout";
import {ContractListSection} from "../../components/Forms/Mission/Sections/ContractSection/ContractListSection";
import {useSelector} from "react-redux";

export const ContractsBoard = () => {
    const sessionType = useSelector((state) => state.auth.sessionType);
    return (
        <AppLayout className="content-full-height">
            <ContractListSection sessionType={sessionType} isSection={false}/>
        </AppLayout>
    );
};

export default ContractsBoard;
import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { fetchFreelanceMissions } from "../../../../services/missionService";
import { UserCard } from "../../../UI/UserCard";
import Utils from "../../../../utils/Utils";
import {Table} from "../../../Tables/Table";
import {MessageComponent} from "../../../UI/MessageComponent";
import {Box, Chip} from "@mui/material";
import Functions from "../../../../utils/Functions";
import PermissionService from "../../../../services/permissionService";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const MissionsSection = ({formData, ...props}) => {
    const { t } = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const connectedUser = useSelector((state) => state.auth);

    const [, setError] = React.useState("");
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const params = useParams();
    const id = formData?.id ?? params.id; // FreelanceProfileId
    const fetchMissionsRef = useRef();
    const [message, setMessage] = React.useState({});
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });

    let columns;
    if (typeof props.columns !== "undefined") {
        columns = props.columns;
    } else {
        columns = [
            {
                field: 'Mission.name',
                headerName: t("Name"),
                type: 'string',
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => row.name
            },
            {
                field: 'OperationalManager.User.fullName',
                type: 'string',
                headerName: t("Assignment Manager"),
                minWidth: 200,
                flex: 1,
                headerAlign: 'center',
                align: 'left',
                renderCell: ({row}) => {
                    const user = row.OperationalManager?.User;
                    return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
                }
            },
            {
                field: 'start_date',
                type: 'date',
                headerName: t("Starting date"),
                minWidth: 150,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                valueGetter: (params, row) => row.start_date && new Date(row.start_date),
                renderCell: ({row}) => {
                    return Utils.formatDate(row.start_date);
                }
            },
            {
                field: 'status',
                type: 'singleSelect',
                headerName: t("Status"),
                minWidth: 70,
                flex: 0.7,
                headerAlign: 'center',
                align: 'center',
                valueOptions: [
                    Functions.STATUS_MISSION.Open,
                    Functions.STATUS_MISSION["In progress"],
                    Functions.STATUS_MISSION.Closed
                ].map((status) => ({
                    value: status,
                    label: t(status)
                })),
                renderCell: ({row}) => {
                    return t(row.status);
                }
            },
            {
                field: 'averageNps',
                type: 'number',
                noSearchFilter: true,
                headerName: t("Recommendation level"),
                minWidth: 100,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({row}) => {
                    const nps = row?.averageNps;
                    return nps !== null && typeof nps !== "undefined"
                        ? <Chip
                            label={+parseFloat(nps).toFixed(1)}
                            className={Utils.getRecommendationStatus(parseFloat(nps))}
                        />
                        : "-";
                }
            }
        ];
    }

    useEffect(() => {
        fetchMissionsRef.current(filterConfigs);
    }, [filterConfigs]);

    fetchMissionsRef.current = async (query = {}) => {
        try {
            const fetchedData = await fetchFreelanceMissions(id, query, token);
            if (!fetchedData.error) {
                setRowCount(fetchedData.count);
                setData(fetchedData.data);
            } else {
                setMessage({type: 'error', message: fetchedData?.error});
            }
        } catch (error) {
            setError(error);
        }
    }

    const handleCellClick = (row) => {
        if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
            if (!PermissionService.hasPermission(connectedUser, "missionDetail")) {
                return;
            }
        }

        navigate(`/missions/missionsdetail/${row.id}`);
    };

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    return (
        <div className="card transparent card-padding-wide">
            <Box className="table-layout" sx={{pt: '6px'}}>
                <Table
                    columns={columns}
                    data={data}
                    rowCount={rowCount}
                    pageSize={pageSize}
                    filterConfigs={filterConfigs}
                    onChange={handleDataGridChange}
                    onCellClick={handleCellClick}
                    fullHeight={true}
                    hideFilter={props.hideTableFilter || false}
                />
            </Box>
            <MessageComponent type={message.type} message={message.message} width="100%"/>
        </div>
    );
};

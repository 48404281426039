import React from 'react';
import {Alert, Stack, Typography} from "@mui/material";
import {Display} from "../../../Inputs/Display";
import {useTranslation} from "react-i18next";

export const OfferAnswerSection = ({applicationData}) => {
    const {t} = useTranslation();

    const questions = applicationData?.RecruitmentOffer?.RecruitmentQuestions || [];
    const answers = (applicationData?.JobApplicationAnswers || []).reduce((acc, a) => ({
        ...acc,
        [a.RecruitmentQuestionId]: a.answer
    }), {});

    return (
        <div className='card standardform-card card-padding-wide'>
            <Stack className="w-100" gap={3}>
                {questions.length === 0 && (
                    <Alert icon={false} severity="info">{t("No question")}</Alert>
                )}
                {questions.map((question) => {
                    return (
                        <Stack gap={1}>
                            <Typography variant="label" color="primary">
                                {question.title}
                                {question.isRequired && (<span className="mandatory-text"> *</span>)}
                            </Typography>
                            <Display
                                type="wysiwyg"
                                value={answers[question.id] || "-"}
                            />
                        </Stack>
                    );
                })}
            </Stack>
        </div>
    );
};
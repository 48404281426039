import React, {memo, useState} from "react";
import {FaCheck, FaLink} from "react-icons/fa6";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

const CopyLinkButton = memo((
    {
        link,
        label = "Copy link",
        variant="outlined",
        color= "secondary",
        size = "medium",
    }
) => {
    const {t} = useTranslation();
    const [copyLinkIcon, setCopyLinkIcon] = useState(<FaLink/>);

    const handleCopyLinkClick = (e) => {
        e.stopPropagation();

        navigator.clipboard.writeText(link);
        setCopyLinkIcon(<FaCheck/>);
        setTimeout(() => {
            setCopyLinkIcon(<FaLink/>);
        }, 1000);
    };

    return (
        <Button
            className="copy-link-button"
            startIcon={copyLinkIcon}
            variant={variant}
            color={color}
            size={size}
            onClick={handleCopyLinkClick}
        >{t(label)}</Button>
    );
}, (prevProps, nextProps) => {
    return prevProps.link === nextProps.link;
});

export default CopyLinkButton;
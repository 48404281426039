import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;

export const requestApi = (url, method = 'GET', data = {}, token = "", abortSignal) => {
    return new Promise((resolve, reject) => {
        try {
            let options = {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    authorization: token
                },
                signal: abortSignal
            };

            if (Object.keys(data).length) {
                options.body = JSON.stringify(data);
            }

            fetch(url, options)
                .then(response =>
                    response.json().then(responseData => ({ response, responseData }))
                )
                .then(({ response, responseData }) => {
                    if (method !== 'GET') {
                        return resolve(responseData);
                    }

                    if (responseData?.encryptedData) {
                        responseData = JSON.parse(
                            CryptoJS.AES.decrypt(responseData.encryptedData, secretKey)
                                .toString(CryptoJS.enc.Utf8)
                        );
                    }

                    if (!response.headers.has('content-range')) {
                        return resolve(responseData);
                    }

                    const contentRange = response.headers.get('content-range');
                    const contentRangeData = contentRange.split("/");

                    return resolve({
                        count: parseInt(contentRangeData[1]),
                        data: responseData
                    });
                })
                .catch(error => {
                    if (error.name !== 'AbortError') {
                        return reject(error);
                    }
                    resolve({ error: true });
                });
        } catch (error) {
            reject(error);
        }
    });
};
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, Box, Button, Grid, IconButton, Stack, Typography} from "@mui/material";
import Functions from "../../../../../utils/Functions";
import OptionToggle from "../../../../UI/OptionToggle";
import {MdCheck, MdDelete} from "react-icons/md";
import {FieldHolder} from "../../../FieldHolder";
import Utils from "../../../../../utils/Utils";
import {CustomCard} from "../../../../UI/CustomCard";

export const OfferInformationStep = ({offerData, setOfferData, onSave, canModify}) => {
    const {t} = useTranslation();

    const editMode = offerData.steps.includes(Functions.OFFER_STEP.INFORMATION);
    const [infoEditMode, setInfoEditMode] = useState(canModify && !editMode);
    const [questionEditMode, setQuestionEditMode] = useState(canModify && !editMode);
    const [loading, setLoading] = useState(true);
    const [questions, setQuestions] = useState([]);

    const criteria = offerData?.RecruitmentCriteria || [];
    const requiredCriteria = criteria.filter(c => c.isRequired);
    const optionalCriteria = criteria.filter(c => !c.isRequired);

    useEffect(() => {
        if (offerData?.RecruitmentCriteria !== null && !offerData?.RecruitmentCriteria.length) {
            const criteria = [
                {
                    name: 'Profile',
                    infoType: Functions.FREELANCE_PROFILE_SECTION.PROFILE,
                    isRequired: true,
                    isReadOnly: true,
                    displayOrder: 1
                },
                {
                    name: 'Informations and documents',
                    infoType: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
                    isRequired: true,
                    isReadOnly: true,
                    displayOrder: 4
                },
                {
                    name: 'Availabilities',
                    infoType: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY,
                    isRequired: false,
                    isReadOnly: false,
                    displayOrder: 2
                },
                {
                    name: 'External referrals',
                    infoType: Functions.FREELANCE_PROFILE_SECTION.REFERRAL,
                    isRequired: false,
                    isReadOnly: false,
                    displayOrder: 3
                }
            ];
            setOfferData({...offerData, RecruitmentCriteria: criteria});
        }
        if (offerData?.RecruitmentQuestions !== null) {
            const updatedQuestions = offerData?.RecruitmentQuestions || [];

            if (questionEditMode) {
                const hasDefault = updatedQuestions.some(question => question.default === true);
                if (!hasDefault) {
                    updatedQuestions.push({
                        title: '',
                        isRequired: false,
                        displayOrder: questions.length + 1,
                        default: true,
                        editMode: true
                    });
                }
            }

            setQuestions([...updatedQuestions]);
            setLoading(false);
        }
    }, [offerData, questionEditMode]);

    const handleCriteriaChange = (infoType, value) => {
        if (value != null) {
            const updatedCriteria = criteria.map(c =>
                c.infoType === infoType ? {...c, isRequired: value} : c
            );
            setOfferData({...offerData, RecruitmentCriteria: updatedCriteria});
        }
    };

    const handleQuestionDelete = (question) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(question.index, 1);
        setOfferData({...offerData, RecruitmentQuestions: updatedQuestions});
    };

    const handleQuestionSave = (question) => {
        const updatedQuestions = [...questions];
        updatedQuestions[question.index] = {
            ...question,
            default: false,
            editMode: false,
        };
        setOfferData({...offerData, RecruitmentQuestions: updatedQuestions});
    };

    const handleSave = async (callback) => {
        const result = onSave && await onSave();
        if (!result?.error) {
            callback && callback();
        }
    };

    return (
        <>
            <CustomCard
                className="justify-content-center"
                editMode={infoEditMode}
                setEditMode={setInfoEditMode}
                canModify={canModify && editMode}
                onSave={() => handleSave(() => setInfoEditMode(false))}
            >
                {requiredCriteria.length > 0 && (
                    <CriteriaSection
                        title={t("Mandatory candidate information")}
                        criteria={requiredCriteria}
                        editMode={infoEditMode}
                        onCriteriaChange={handleCriteriaChange}
                    />
                )}
                {optionalCriteria.length > 0 && (
                    <CriteriaSection
                        title={t("Optional candidate information")}
                        criteria={optionalCriteria}
                        editMode={infoEditMode}
                        onCriteriaChange={handleCriteriaChange}
                    />
                )}
            </CustomCard>

            <CustomCard
                className="justify-content-center"
                editMode={questionEditMode}
                setEditMode={setQuestionEditMode}
                canModify={canModify && editMode}
                onSave={() => handleSave(() => setQuestionEditMode(false))}
            >
                <Typography variant="label">{t("Questions")}</Typography>

                <Stack direction="column" className="w-100" gap={2}>
                    {!questionEditMode && !loading && questions.length === 0 && (
                        <Alert icon={false} severity="info">{t("No question")}</Alert>
                    )}
                    {questions.map((question, index) => {
                        question.index = index;
                        if (questionEditMode || !question.default) {
                            return (
                                <RecruitmentQuestionRow
                                    key={index}
                                    question={question}
                                    editMode={questionEditMode}
                                    onSave={handleQuestionSave}
                                    onDelete={handleQuestionDelete}
                                />
                            );
                        }
                        return null;
                    })}
                </Stack>
            </CustomCard>

            {!editMode && (
                <Stack alignItems="center" className="w-100">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => handleSave(() => {
                            setInfoEditMode(false);
                            setQuestionEditMode(false);
                        })}
                    >
                        {t("Save")}
                    </Button>
                </Stack>
            )}
        </>
    );
};

const CriteriaSection = ({title, criteria, editMode, onCriteriaChange}) => {
    const {t} = useTranslation();

    return (
        <Stack direction="column" className="w-100" gap={1}>
            <Typography variant="label">{title}</Typography>
            <Grid container spacing={2}>
                {criteria.map((info) => (
                    <Grid item xs={4} key={info.infoType}>
                        <Box className="recruitment-criteria-item">
                            <Typography sx={{fontWeight: "bold"}}>{t(info.name)}</Typography>
                            <Stack className="h-100" alignItems="center" justifyContent="space-between" gap={2}>
                                <Box className="recruitment-criteria-image">
                                    <img
                                        src={`/images/${info.infoType.toLowerCase()}.png`}
                                        alt={t(info.name)}
                                    />
                                </Box>
                                {editMode && !info.isReadOnly && (
                                    <OptionToggle
                                        selectedOption={info.isRequired}
                                        onChange={(value) => onCriteriaChange(info.infoType, value)}
                                    />
                                )}
                            </Stack>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

const RecruitmentQuestionRow = ({question, editMode, onSave, onDelete}) => {
    const {t} = useTranslation();

    const [questionData, setQuestionData] = useState(question);
    const [formErrors, setFormErrors] = useState({});

    const formRef = useRef(null);
    const hasError = Object.keys(formErrors).length > 0;

    useEffect(() => {
        setQuestionData(question);
        /*if (question?.default && question.editMode && formRef.current) {
            const titleField = formRef.current.elements.title;
            if (titleField) {
                formRef.current.elements.title.focus();
            }
        }*/
    }, [question]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setQuestionData((prevData) => ({...prevData, [name]: value}));
    };

    const handleOptionChange = (value) => {
        if (value != null) {
            setQuestionData((prevData) => ({...prevData, isRequired: value}));
            if (!questionData?.editMode) {
                onSave && onSave({...questionData, isRequired: value});
            }
        }
    };

    const handleSave = (e) => {
        e.preventDefault();

        let {valid, errors} = Utils.validateRequiredFields(questionData, ['title']);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (valid && onSave) {
            onSave(questionData);
        }
    };

    return (
        <form ref={formRef}
              noValidate
              className={`recruitment-question-item ${hasError ? "error" : ""}`}
        >
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                    {questionData?.editMode
                        ? (
                            <FieldHolder
                                type="multiline"
                                name="title"
                                id={`questionTitle${questionData?.index}`}
                                rows={1}
                                placeholder={t("Posez votre question")}
                                defaultValue={questionData?.title}
                                onBlur={handleChange}
                                canModify={true}
                                mandatoryFlag={true}
                                error={formErrors.title}
                            />
                        )
                        : (
                            <Typography sx={{px: '14px', py: '5px'}}>
                                {questionData?.title}
                                {!editMode && questionData?.isRequired && (<span className="mandatory-text"> *</span>)}
                            </Typography>
                        )
                    }
                </Grid>
                {editMode && (
                    <>
                        <Grid item xs="auto">
                            <OptionToggle
                                selectedOption={questionData?.isRequired}
                                onChange={handleOptionChange}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            {questionData?.editMode
                                ? (
                                    <IconButton
                                        title={t("Save")}
                                        onClick={handleSave}
                                        color="secondary"
                                        size="small"
                                        sx={{border: '1px solid var(--secondary-main-color)', p: '2px'}}
                                    >
                                        <MdCheck size={20}/>
                                    </IconButton>
                                )
                                : (
                                    <IconButton
                                        title={t("Delete")}
                                        onClick={() => onDelete && onDelete(questionData)}
                                        color="secondary"
                                        size="small"
                                    >
                                        <MdDelete size={20}/>
                                    </IconButton>
                                )
                            }
                        </Grid>
                    </>
                )}
            </Grid>
        </form>
    );
};
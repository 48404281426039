import React, {useRef, useState} from "react";
import {Button, Grid, Stack} from "@mui/material";
import ResponsiveStepper from "../../../UI/ResponsiveStepper";
import Functions from "../../../../utils/Functions";
import {ProposalAssignmentForm} from "./ProposalAssignmentForm";
import {SendProposalAssignmentForm} from "../../Mission/Sections/MissionDetailSection/SendProposalAssignmentForm";
import {saveShortlistedFreelance} from "../../../../services/missionShortlistedFreelance";
import {useSelector} from "react-redux";
import {CustomCard} from "../../../UI/CustomCard";
import {useTranslation} from "react-i18next";

export const ProposalAssignment = ({assignment, selectedFreelances, onSaveFinish}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);

    const [editMode, setEditMode] = useState(false);
    const [missionId, setMissionId] = useState(null);
    const [activeStep, setActiveStep] = useState(Functions.PROPOSAL_ASSIGNMENT_STEP.DETAIL);
    const [assignmentData, setAssignmentData] = useState(assignment);

    const proposalAssignmentForm = useRef(null);

    const steps = [
        {key: Functions.PROPOSAL_ASSIGNMENT_STEP.DETAIL, name: 'Assignment details'},
        {key: Functions.PROPOSAL_ASSIGNMENT_STEP.FREELANCER, name: 'Proposal to freelancers'},
    ];

    const handleAssignmentSaveFinish = async (responseData) => {
        if (responseData.success) {
            const {data} = responseData;
            const response = await saveShortlistedFreelance({
                MissionId: data.id,
                FreelanceProfileIds: selectedFreelances
            }, token);
            if (!response?.error) {
                setMissionId(data.id);
                setActiveStep(Functions.PROPOSAL_ASSIGNMENT_STEP.FREELANCER);
            }
        }
    };

    const handleProposalSendFinish = (success) => {
        if (success) {
            onSaveFinish && onSaveFinish();
        }
    };

    const handleSave = async () => {
        proposalAssignmentForm?.current();
    };

    const handleProposeAssignmentClick = () => {
        handleAssignmentSaveFinish({
            success: true,
            data: {id: assignmentData?.id}
        }).then();
    };

    return (
        <Grid container direction="column" columns={12} spacing={2}>
            <Grid item xs={12}>
                <ResponsiveStepper
                    steps={steps}
                    stepKey={activeStep}
                    onStepClick={(index) => setActiveStep(steps[index]?.key)}
                />
            </Grid>
            <Grid item xs={12}>
                {activeStep === Functions.PROPOSAL_ASSIGNMENT_STEP.DETAIL && (
                    <>
                        {assignmentData.id
                            ? (
                                <Stack direction="column" alignItems="center" gap={2}>
                                    <CustomCard
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        canModify={true}
                                        onSave={handleSave}
                                    >
                                        <ProposalAssignmentForm
                                            ref={proposalAssignmentForm}
                                            data={assignmentData}
                                            setData={setAssignmentData}
                                            onSaveFinish={() => setEditMode(false)}
                                            canModify={editMode}
                                        />
                                    </CustomCard>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleProposeAssignmentClick}
                                    >{t("Propose the assignment")}</Button>
                                </Stack>
                            )
                            : (
                                <ProposalAssignmentForm
                                    data={assignmentData}
                                    setData={setAssignmentData}
                                    onSaveFinish={handleAssignmentSaveFinish}
                                    canModify={true}
                                />
                            )
                        }
                    </>
                )}
                {activeStep === Functions.PROPOSAL_ASSIGNMENT_STEP.FREELANCER && missionId && (
                    <SendProposalAssignmentForm
                        missionId={missionId}
                        selectedFreelances={selectedFreelances}
                        onBack={() => setActiveStep(Functions.PROPOSAL_ASSIGNMENT_STEP.DETAIL)}
                        onSaveFinish={handleProposalSendFinish}
                    />
                )}
            </Grid>
        </Grid>
    );
}
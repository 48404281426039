import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {FaRegAddressCard} from "react-icons/fa";

import filesFreelanceProfile from "../../../services/filesFreelanceProfile";

import {ProfileSection} from "./Sections/ProfileSection/ProfileSection";
import {InformationAndDocumentSection} from "./Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import {MissionsSection} from "./Sections/MissionsSection";

import "../form.css";
import "./freelanceprofileform.css";
import {HelperCard} from "../../UI/HelperCard";
import Functions from "../../../utils/Functions";
import {AvailabilitySection} from "./Sections/AvailabilitySection";
import {ReferralSection} from "./Sections/ReferralSection";
import SectionMenu from "../../UI/SectionMenu";
import {CompanyFreelanceNote} from "./CompanyFreelance/CompanyFreelanceNote";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";
import {useTranslation} from "react-i18next";
import {fetchCompany} from "../../../services/companyService";
import {OtherAssignmentSection} from "./Sections/OtherAssignmentSection/OtherAssignmentSection";
import {OfferAnswerSection} from "./Sections/OfferAnswerSection";

export const FreelanceProfileForm = (
    {
        activeSection,
        setActiveSection,
        editPermissions
    }
) => {
    const objectAttachedType = "freelanceProfile";
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const {freelanceApplication, freelanceData, setFreelanceData} = useFreelanceProfileStore();

    const sessionType = useSelector((state) => state.auth.sessionType);
    const companyId = useSelector((state) => state.auth.companyId);

    const [company, setCompany] = useState({});

    const otherAssignmentSectionRef = useRef(null);
    const formRef = useRef(null);

    const canModify = editPermissions.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL);

    useEffect(() => {
        filesFreelanceProfile.setFiles([]);
        filesFreelanceProfile.setFilesDeleted([]);
    }, []);

    useEffect(() => {
        (async () => {
            if (companyId) {
                const fetchedCompany = await fetchCompany(companyId, token);
                setCompany(fetchedCompany);
            }
        })();
    }, [token]);

    const handleSectionClick = (section) => {
        setActiveSection(section);
        if (section === Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT) {
            otherAssignmentSectionRef.current && otherAssignmentSectionRef.current();
        }
    };

    let sectionMenuItems = [];
    let helperContent;

    if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
        sectionMenuItems = [
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
        ];

        if (freelanceApplication?.id) {
            sectionMenuItems.splice(3, 0,
                {
                    key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
                    name: 'Informations and documents'
                },
                {
                    key: Functions.FREELANCE_PROFILE_SECTION.OFFER_ANSWER,
                    name: 'Answers to questions'
                }
            );
        } else if (editPermissions.length) {
            sectionMenuItems.splice(2, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT,
                name: t("Assignments") + (parseInt(freelanceData?.missionCount) ? ` (${freelanceData?.missionCount})` : ""),
                tooltip: t("Assignments for {{company}}", {company: company?.name})
            });
            sectionMenuItems.splice(3, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT,
                name: t("Assignments outside Smarteem")
            });
            sectionMenuItems.splice(5, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
                name: 'Informations and documents'
            });
        }
    } else {
        sectionMenuItems = [
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
            {key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT, name: 'Informations and documents'},
        ];

        if (activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE) {
            helperContent = (
                <>
                    <HelperCard
                        icon={<FaRegAddressCard/>}
                        headerText={"Why complete your profile?"}
                        descriptionText={
                            "Your Smarteem profile can be shared with multiple companies. Once completed, you can transmit all your personal information, skills, and documents to a new company with a single click."
                        }
                    />
                </>
            );
        }
    }

    const standardFormClass = (
        activeSection === Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT ||
        activeSection === Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT
    ) ? "standardform-w70" : "standardform-w55";

    return (
        <div className="form-layout">
            <div className="workflowmenu">
                <SectionMenu
                    color="info"
                    sectionMenuItems={sectionMenuItems}
                    activeSection={activeSection}
                    onSectionMenuClick={handleSectionClick}
                />
            </div>
            <form
                ref={formRef}
                className={`standardform ${standardFormClass}`}
                id="freelanceProfileForm"
                noValidate
            >
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <ProfileSection
                        objectAttachedType={objectAttachedType}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                    <AvailabilitySection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                    <ReferralSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        canModify={editPermissions?.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL)}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT && (
                    <MissionsSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT && (
                    <OtherAssignmentSection
                        ref={otherAssignmentSectionRef}
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={true}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                    <InformationAndDocumentSection
                        objectAttachedType={objectAttachedType}
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.OFFER_ANSWER && (
                    <OfferAnswerSection
                        applicationData={freelanceApplication}
                    />
                )}
            </form>

            {
                sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
                activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE &&
                (Boolean(editPermissions.length) || freelanceApplication?.id) &&
                (
                    <CompanyFreelanceNote
                        activeSection={Functions.FREELANCE_PROFILE_SECTION.PROFILE}
                        freelanceProfile={freelanceData}
                        editPermissions={editPermissions}
                        freelanceApplication={freelanceApplication}
                    />
                )
            }
            {helperContent && (<div className="helpercard-container">{helperContent}</div>)}
        </div>
    );
};

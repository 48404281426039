import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/quotes/";

export const fetchQuote = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching quote:", error);
        throw error;
    }
};

export const createQuote = async (quoteData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', quoteData, token);
    } catch (error) {
        throw error;
    }
};

export const getQuotesByFreelance = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}get-by-freelance?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const getQuotesByCompany = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}get-by-company?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};


export const getQuotesByMission = async (missionId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}mission/${missionId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        throw error;
    }
};

export const updateQuote = async (quoteId, quoteData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${quoteId}`, 'PUT', quoteData, token);
    } catch (error) {
        console.error("Error updating quote:", error);
        throw error;
    }
};

export const modifyManyQuoteStatus = async (datas, token) => {
    try {
        const response = await fetch(`${BASE_URL}modify-many-quote-status`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: JSON.stringify(datas),
        });
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const sendNotification = async (datas, token) => {
    try {
        return requestApi(`${BASE_URL}send-notification`, 'POST', datas, token);
    } catch (error) {
        throw error;
    }
};
import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {LuShieldCheck} from "react-icons/lu";
import {TbWorldSearch} from "react-icons/tb";

import Utils from "../../../utils/Utils";

import {FieldHolder, MandatoryText} from "../FieldHolder";

import {fetchUser, updateUser} from "../../../services/userService";
import {createFile} from "../../../services/fileService";

import "../form.css";

import {MessageComponent} from "../../UI/MessageComponent";
import {HelperCard} from "../../UI/HelperCard";
import Functions from "../../../utils/Functions";
import {Button, Grid, Stack} from "@mui/material";

export const UserForm = forwardRef(({setLoading, onSubmit, ...props}, ref) => {
    const token = useSelector((state) => state.auth.token);
    const {t} = useTranslation();
    const [formErrors, setFormErrors] = useState({});
    const [message, setMessage] = useState({});
    const [formData, setFormData] = useState({});
    const [userPicture, setUserPicture] = useState(null);
    const formRef = useRef(null);

    const requiredFields = [
        'picture', 'firstName', 'lastName', 'email', 'dateOfBirth', 'dialCode', 'phoneNumber',
        'countryOfResidence', 'user_language'
    ];

    useEffect(() => {
        try {
            fetchUser(token).then((fetchedUser) => {
                if (!fetchedUser?.error) {
                    setFormData(fetchedUser);
                }
            });
        } catch (error) {
            setMessage({type: "error", message: error.message});
        }
    }, [token]);

    useEffect(() => {
        if (Object.keys(formErrors).length > 0) {
            const {errors} = Utils.validateRequiredFields(formData, requiredFields);
            setFormErrors(errors);
        }
    }, [formData]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleFileChange = (name, value) => {
        setUserPicture(value || null);
        setFormData({...formData, [name]: value || null});
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(formData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return
        }

        try {
            setMessage({type: "loading", message: "Saving data"});

            const userDataUpdate = {...formData};

            if (userPicture) {
                const fileData = {
                    typeFile: Functions.TEXT_PROFILE_PICTURE,
                    picture: userPicture.theFile,
                };
                userDataUpdate.picture = await createFile(fileData, "", token);
            }

            setLoading && setLoading(true);
            const user = await updateUser({userData: userDataUpdate, token: token});
            setLoading && setLoading(false);

            if (!user.error) {
                setMessage({type: "success", message: "User updated"});

                onSubmit && onSubmit({success: true});
            } else {
                setMessage({type: "error", message: "Error saving data"});
            }
        } catch (error) {
            setMessage({type: "error", message: "Error saving data"});
        }
    };

    if (ref) {
        ref.current = handleSubmit;
    }

    const standardFormClass = props.className || "standardform-w55";

    return (
        <>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                method="post"
                className={`standardform ${standardFormClass}`}
                noValidate
            >
                <div className="card standardform-card card-padding-wide">
                    <Grid container sx={{justifyContent: {xs: "center", md: "start"}}}>
                        <Grid item>
                            <FieldHolder
                                fielddesc={t("Profile picture")}
                                type="image"
                                name="picture"
                                id="picture"
                                placeholder={t("Picture")}
                                value={formData.picture}
                                onFileChange={handleFileChange}
                                error={formErrors.picture}
                            />
                        </Grid>
                    </Grid>

                    <FieldHolder
                        fielddesc={t("First name")}
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder={t("Jean")}
                        value={formData.firstName}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.firstName}
                    />
                    <FieldHolder
                        fielddesc={t("Last name")}
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder={t("Dupont")}
                        value={formData.lastName}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.lastName}
                    />
                    <FieldHolder
                        fielddesc={t("Email")}
                        type="text"
                        name="email"
                        id="email"
                        placeholder={t("User email")}
                        value={formData.email}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.email}
                    />
                    <FieldHolder
                        fielddesc={t("Birth date")}
                        type="date"
                        name="dateOfBirth"
                        id="dateOfBirth"
                        placeholder={t("01/01/1970")}
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.dateOfBirth}
                    />
                    <FieldHolder
                        fielddesc={t("Dial code")}
                        type="text"
                        name="dialCode"
                        id="dialCode"
                        placeholder={t("+49")}
                        value={formData.dialCode}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.dialCode}
                    />
                    <FieldHolder
                        fielddesc={t("Phone number")}
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder={t("06 00 00 00 00")}
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.phoneNumber}
                    />
                    <FieldHolder
                        fielddesc={t("Country of residence")}
                        type="dropdown"
                        set="country"
                        name="countryOfResidence"
                        id="countryOfResidence"
                        placeholder={t("United states of america")}
                        value={formData.countryOfResidence}
                        formData={formData}
                        setFormData={setFormData}
                        mandatoryFlag={true}
                        error={formErrors.countryOfResidence}
                    />
                    <FieldHolder
                        fielddesc={t("Language")}
                        type="dropdown"
                        set="Language"
                        name="user_language"
                        id="user_language"
                        placeholder={t("")}
                        value={formData.user_language}
                        formData={formData}
                        setFormData={setFormData}
                        mandatoryFlag={true}
                        error={formErrors.user_language}
                    />

                    <Stack alignItems="center" className="w-100">
                        <MandatoryText/>
                    </Stack>
                </div>
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />
                {!ref &&
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                    >
                        {t("Save")}
                    </Button>
                }
            </form>
            <div className="helpercard-container">
                <HelperCard
                    icon={<TbWorldSearch/>}
                    headerText={"Why provide your country of residence?"}
                    descriptionText={
                        "The country of residence determines the applicable regulations."
                    }
                />
                <HelperCard
                    icon={<LuShieldCheck/>}
                    headerText={"How are your personal information secured?"}
                    descriptionText={
                        "Smarteem follows the best GDPR practices. Only those who need to contact you can access your information."
                    }
                />
            </div>
        </>
    );
});

import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import Utils from "../../../utils/Utils";

import {FieldHolder, MandatoryText} from '../FieldHolder';

import {fetchCompany, updateCompany} from "../../../services/companyService";

import '../form.css';

import {MessageComponent} from '../../UI/MessageComponent';
import {Button, Grid, Stack, Typography} from "@mui/material";
import {MdAddCircleOutline} from "react-icons/md";
import Functions from "../../../utils/Functions";
import {createFile} from "../../../services/fileService";
import {saveToken} from "../../../utils/actions/authActions";

export const CompanyForm = forwardRef(({setLoading, onSubmit, ...props}, ref) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const companyId = connectedUser.companyId;
    const {t} = useTranslation();
    const [formErrors, setFormErrors] = useState({});
    const [message, setMessage] = useState({});
    const [formData, setFormData] = useState({});
    const [companyLogo, setCompanyLogo] = useState(null);
    const formRef = useRef(null);

    const imageTextUpload = (
        <Stack
            direction="column"
            alignItems="center"
            gap={1}
            p={0.5}
        >
            <Typography sx={{
                textAlign: "center",
                cursor: "pointer"
            }}>{t("Click to upload a logo")}</Typography>
            <MdAddCircleOutline size={20}/>
        </Stack>
    );

    const requiredFields = ['name'];

    useEffect(() => {
        try {
            fetchCompany(companyId, token).then((fetchedCompany) => {
                if (!fetchedCompany?.error) {
                    setFormData(fetchedCompany);
                }
            });
        } catch (error) {
            setMessage({type: "error", message: error.message});
        }
    }, [token]);

    useEffect(() => {
        if (Object.keys(formErrors).length > 0) {
            const {errors} = Utils.validateRequiredFields(formData, requiredFields);
            setFormErrors(errors);
        }
    }, [formData]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleFileChange = (name, value) => {
        setCompanyLogo(value || null);
        setFormData({...formData, [name]: value || null});
    };

    const handleSubmit = async (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(formData, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return
        }

        try {
            setMessage({type: "loading", message: "Saving data"});
            setLoading && setLoading(true);

            if (companyLogo) {
                const fileData = {
                    typeFile: Functions.TEXT_PROFILE_PICTURE,
                    picture: companyLogo.theFile,
                };
                formData.logo = await createFile(fileData, "", token);
            }

            const company = updateCompany({
                companyId: formData.id,
                companyData: formData,
                token: token
            });

            setLoading && setLoading(false);

            if (!company?.error) {
                dispatch(
                    saveToken({
                        ...connectedUser,
                        companyLogo: formData.logo,
                        userType: connectedUser.sessionType
                    })
                );
                await new Promise((resolve) => setTimeout(resolve, 400));
                setMessage({type: "success", message: "Company updated"});
                onSubmit && onSubmit({success: true});
            } else {
                setMessage({type: "error", message: "Error saving data"});
            }
        } catch (error) {
            setMessage({type: "error", message: "Error saving data"});
        }
    };

    if (ref) {
        ref.current = handleSubmit;
    }

    const standardFormClass = props.className || "standardform-w55";

    return (
        <>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                method="post"
                className={`standardform ${standardFormClass}`}
                noValidate
            >
                <div className='card standardform-card card-padding-wide'>
                    <Grid container sx={{justifyContent: {xs: "center", md: "start"}}}>
                        <Grid item>
                            <FieldHolder
                                type="image"
                                name="logo"
                                id="logo"
                                value={formData?.logo}
                                onFileChange={handleFileChange}
                                resizeImage={false}
                                textUpload={imageTextUpload}
                            />
                        </Grid>
                    </Grid>

                    <FieldHolder
                        fielddesc={t('Name')}
                        type="text"
                        name="name"
                        id="name"
                        placeholder={t('Company')}
                        value={formData.name}
                        onChange={handleChange}
                        mandatoryFlag={true}
                        error={formErrors.name}
                    />
                    <FieldHolder
                        fielddesc={t('Headquarter country')}
                        type="dropdown"
                        set="country"
                        name="headquartersCountry"
                        id="headquartersCountry"
                        placeholder={t('United States of America')}
                        value={formData.headquartersCountry}
                        onChange={handleChange}
                        formData={formData}
                        setFormData={setFormData}
                        error={formErrors.headquartersCountry}
                    />
                    <FieldHolder
                        fielddesc={t('Address of the headquarters')}
                        type="text"
                        name="legalAddress"
                        id="legalAddress"
                        placeholder={t('1, 5th Ave, New York, NY 10003')}
                        value={formData.legalAddress}
                        onChange={handleChange}
                        error={formErrors.legalAddress}
                    />
                    <FieldHolder
                        fielddesc={t('Siret')}
                        type="text"
                        name="siret"
                        id="siret"
                        placeholder={t('XXXXXXXXX')}
                        value={formData.siret}
                        onChange={handleChange}
                        error={formErrors.siret}
                    />
                    <FieldHolder
                        fielddesc={t("VAT Number")}
                        type="text"
                        name="vatNumber"
                        id="vatNumber"
                        placeholder={t("VAT Number")}
                        value={formData.vatNumber}
                        onChange={handleChange}
                        error={formErrors.vatNumber}
                    />
                    <FieldHolder
                        fielddesc={t('Organization size')}
                        type="dropdown"
                        set="Size"
                        name="size"
                        id="size"
                        value={formData.size}
                        formData={formData}
                        setFormData={setFormData}
                        error={formErrors.size}
                    />

                    <Stack alignItems="center" className="w-100">
                        <MandatoryText/>
                    </Stack>
                </div>
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="90%"
                />

                {!ref &&
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                    >
                        {t("Save")}
                    </Button>
                }
            </form>
            {/* <div className="helpercard-container">
          <HelperCard 
            icon = {<TbWorldSearch />}
            headerText = {"Pourquoi renseigner vos informations entreprise ?"}
            descriptionText = {"Renseigner vos informations entreprise permet à Smarteem de vérifier l'identité"}
            />
          <HelperCard 
            icon = {<LuShieldCheck />}
            headerText = {"Comment sont sécurisées vos informations personnelles ?"}
            descriptionText = {"Smarteem applique les meilleures pratiques RGPD. Seules les personnes ayant besoin de vous contacter peuvent accéder à vos informations."}
            />
        </div> */}
        </>
    );
});